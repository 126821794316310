import { AxiosResponse } from 'axios';
import {
  createContext,
  useContext,
  useState,
  useCallback,
  SetStateAction,
  Dispatch,
} from 'react';
import api from '../services/api';
import useAxios from '../utils/useAxios';

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

interface Error {
  status: number;
  message: string;
}

interface User {
  id: string;
  email: string;
  manager: string;
}

interface AuthState {
  user: User;
  token: string;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextProps {
  user: User;
  token: string;
  error: Error | null;
  signIn(credentials: SignInCredentials): Promise<Error | null>
  setError: Dispatch<SetStateAction<Error | null>>;
  updateUser(user: User): void;
  signOut(): void;
}

export const AuthProvider: React.FC = ({ children }) => {
  const [axiosPost] = useAxios('post');
  const [error, setError] = useState<Error | null>(null);

  const [data, setData] = useState<AuthState>(() => {
    const user = localStorage.getItem('@NovoCred:user');
    const token = localStorage.getItem('@NovoCred:token');

    if (user && token) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { user: JSON.parse(user), token };
    }

    return {} as AuthState;
  });

  const signIn = useCallback(async ({ email, password }): Promise<Error | null> => {
    let res = null;
    await axiosPost({
      url: '/login',
      body: { email, password },
      success: (response: AxiosResponse) => {
        const { user, token } = response.data.data;

        localStorage.setItem('@NovoCred:user', JSON.stringify(user));
        localStorage.setItem('@NovoCred:token', token);

        api.defaults.headers.authorization = `Bearer ${token}`;

        setError(null);
        setData({ user, token });
        res = null;
      },
      error: (err: { response: { data: { message: string }; status: number; }; }) => {
        const { message } = err.response.data;
        const { status } = err.response;

        setError({ status, message });
        res = { status, message };
      },
    });
    return res;
  }, [axiosPost]);

  const updateUser = useCallback(
    (userData: User) => {
      localStorage.setItem('@NovoCred:user', JSON.stringify(userData));

      setData({
        token: data.token,
        user: userData,
      });
    },
    [data.token],
  );

  const signOut = useCallback(() => {
    localStorage.removeItem('@NovoCred:user');
    localStorage.removeItem('@NovoCred:token');

    delete api.defaults.headers.authorization;

    setData({} as AuthState);
  }, []);

  return (

    <AuthContext.Provider value={{
      user: data.user,
      signIn,
      signOut,
      updateUser,
      token: data.token,
      error,
      setError,
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
