import React from 'react';
import { AxiosError } from 'axios';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactRouteProps,
  Redirect,
} from 'react-router-dom';
import { useAuth } from '../hooks/Auth';
import api from '../services/api';

interface RouteProps extends ReactRouteProps {
  isPrivate: boolean;
  component: React.ComponentType;
}
const PrivateRoute: React.FC<RouteProps> = ({
  isPrivate,
  component: Component,
  ...rest
}: RouteProps) => {
  const token = localStorage.getItem('@NovoCred:token');
  const { signOut, setError } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        api.post('/login/auth').catch((error: AxiosError) => {
          signOut();
          setError(error.response?.data.message);
        });

        return isPrivate === !!token ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default PrivateRoute;
