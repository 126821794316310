import styled from 'styled-components';
import theme from '../../assets/styles/theme';

export const CardsTitle = styled.h2`
  font: ${theme.fonts.primary.h2};
  color: #4B4B4B;
`;

export const GoalInfo = styled.div`
  width: 30%;
  height: 125px;
  background-color: #ECE2FF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 20px;
  border-radius: 20px;
`;

export const GoalText = styled.h4`
  font: ${theme.fonts.primary.h3};
  color: ${theme.colors.mainBlack};
  max-width: 90%;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const GoalQuantity = styled.p`
  font: ${theme.fonts.primary.body};
  color: ${theme.colors.mainBlack};
  padding-right: 10px;
`;

export const PrizeCardsContainer = styled.div`
  display: flex;
  margin-left: -30px;
  width: 100%;
`;

export const PrizeCard = styled.div`
  width: 30%;
  height: 125px;
  display: flex;
  border: 1px solid #E1E1E1;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #0000001A;
  background-color: ${theme.colors.bodyWhite};
  margin-right: 10px;
`;

export const PrizeDescription = styled.p`
  font: ${theme.fonts.secondary.small};
  color: ${theme.colors.mainBlack};
`;

export const PrizePercentage = styled.h2`
  font: ${theme.fonts.secondary.h2};
  color: ${theme.colors.mainBlack};
  font-weight: bold;
`;
