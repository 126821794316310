/* eslint-disable react/require-default-props */
import React, { useState, useEffect, useRef } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { useReactToPrint } from 'react-to-print';
import useAxios from '../../utils/useAxios';
import ModalUi from '../Modals/ModalUi';
import theme from '../../assets/styles/theme';
import {
  Title,
  Goals,
  Prizes,
  ModalCard,
  ModalCardCategory,
  ModalCardPrize,
  Sales,
  ModalContent,
} from './style';
import {
  Button,
  ProgressCircle,
  TableComponent,
  ReportTemplate,
} from '../index';
import {
  Document,
  Download,
} from '../../assets/icons';

interface Participant {
  id?: string;
  modal: boolean;
  onExited?: () => void;
  code: number;
  goalPerfomance?:number;
  prizes?: Prize2[];
}

interface Prize2 {
  name:string;
  price: number;
  prizeModality: string;
}

type Sale = {
  id?: string;
  name: string;
  cpf: string;
  productCode: string;
  companyCnpj: string;
  productAmount: number;
  receiptNumber: number;
  sellerName?: string;
};

type Goal = {
  productName: string,
  productDescription: string,
  unityQuantity: number,
  unityPrice: number,
  productCode: number [],
  eachSalePrize: number | null,
  pointValue: number,
  prizes: Prize[];
};

type Prize = {
  name: string,
  price: number,
  photo: string,
  goal: string,
  percentageNeeded: number,
  prizeModality: string,
};

type Participants = {
  id: string;
  name: string;
  sold: number;
  prizes?: Prize[];
  goals: participantGoal[];
  goalsPerfomance: GoalsPerfomance[];
};

type GoalsPerfomance = {
  name: string,
  percentageSold: number;
};

type participantGoal = {
  name: string;
  presentValue: number;
  fullValue: number;
};

type ParticipantSalesData = {
  sales: Sale[],
  totalSales: number;
};

const salesHistoryColumns = [
  {
    disablePadding: false,
    id: 'name',
    label: 'Nome da venda registrada',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'value',
    label: 'Valor(R$)',
    numeric: true,
  },
];

const ParticipantsModal = ({
  id, modal, onExited, code, goalPerfomance, prizes,
}: Participant) => {
  const [axiosGet] = useAxios('get');
  const [selectedParticipant, setSelectedParticipant] = useState<Participants>();
  const [participantSalesData, setParticipantSalesData] = useState<ParticipantSalesData>();
  const componentRefParticipant = useRef(null);

  console.log(prizes);

  const fetchParticipantSales = async (query = 'order=default&rows=10&page=0') => {
    await axiosGet({
      url: `/sale/participant/${id}/campaign/${code}?${query || ''}`,
      success: (res: AxiosResponse) => {
        console.log(res.data.data);
        setParticipantSalesData(res.data.data);
      },
    });
  };

  useEffect(() => {
    fetchParticipantSales();
  }, [id]);

  return (
    <>
      <ModalUi open={modal} height="650px" width="1000px" onExited={onExited}>
        <ModalContent>
          <Goals>
            <Title>Desempenho das metas</Title>
            <div style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            >
              <ProgressCircle
                type="small"
                radius={30}
                height="160px"
                width="160px"
                presentValue={goalPerfomance}
                fullValue={100}
                prize="Vendas"
              />
            </div>
          </Goals>
          <Prizes>
            <Title>Saldo de prêmios adquiridos</Title>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              marginTop: 10,
            }}
            >
              {prizes?.map((prize: Prize2) => {
                if (prize.name && prize?.name.includes('PocketCard')) {
                  return (
                    <ModalCard>
                      <ModalCardCategory>{prize?.name}</ModalCardCategory>
                      <ModalCardPrize>
                        R$
                        {' '}
                        {prize?.price?.toLocaleString('pt-BR')}
                      </ModalCardPrize>
                    </ModalCard>
                  );
                }
                if (prize?.prizeModality === 'by each sale') {
                  return (
                    <ModalCard>
                      <ModalCardCategory>Total ganho pela venda de unidades</ModalCardCategory>
                      <ModalCardPrize>
                        R$
                        {' '}
                        {prize.price?.toLocaleString('pt-BR')}
                      </ModalCardPrize>
                    </ModalCard>
                  );
                }

                return (
                  <ModalCard>
                    <ModalCardCategory>Prêmio físico</ModalCardCategory>
                    <ModalCardPrize>{prize.name}</ModalCardPrize>
                  </ModalCard>
                );
              })}

              {/* {selectedParticipant?.prizes.length === 0 && (
              <ModalCard style={{ textAlign: 'center' }}>
                <ModalCardCategory>Este participante ainda não obteve prêmios</ModalCardCategory>
              </ModalCard>
            )} */}

            </div>
          </Prizes>
          <Sales>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 15,
            }}
            >
              <Title>Histórico de vendas</Title>
            </div>
            <TableComponent
              title="Histórico de vendas"
              saleNameData={participantSalesData?.sales}
              total={participantSalesData?.totalSales}
              columns={salesHistoryColumns}
            /* filterTable={async (query: string) => {
              console.log(`toaqui${query}`);
              /* const id = selectedParticipant?.id || ''; */
            /* await fetchParticipantSales(id, `&${query}&type=sales`); */
            />
          </Sales>
          {/* <div style={{ zIndex: -10, display: 'none' }}>
          {campaignData?.campaign.code && selectedParticipant?.id && (
            <ReportTemplate
              campaignCode={campaignData?.campaign.code}
              reportType="specific"
              componentRef={componentRefParticipant}
              participantId={selectedParticipant.id}
            />
          )}
        </div> */}
        </ModalContent>
      </ModalUi>
    </>
  );
};

export default ParticipantsModal;
