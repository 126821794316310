/* eslint-disable react/require-default-props */
import * as React from 'react';
import {
  StyledModal,
  Backdrop,
  ModalBox,
  ModalTitle,
  ModalText1,
  TopContent,
  ModalButtons,
  ModalText2,
  CloseIcon,
  ModalContent,
} from './style';

import { Button } from '../..';
import theme from '../../../assets/styles/theme';

type WithChildren<T = unknown> = T & {
  children?: React.ReactNode,
};

export interface ModalProps {
  children?: WithChildren;
  content?: React.ElementType;
  width?: string;
  height?: string;
  bgColor?: string;
  title?: string;
  description?: string;
  gapButtons?: string;
  text?: string;
  marginDescription?: string;
  marginText?: string;
  textPosition?: string;
  open: boolean;
  onExited?: () => void;
  cancelButton?: boolean;
  padding?: string;
}

const ModalUi: React.ElementType = ({
  width,
  height,
  children,
  content,
  bgColor,
  title,
  description,
  gapButtons,
  text,
  marginDescription,
  marginText,
  textPosition,
  open,
  onExited,
  cancelButton,
  padding,
}: ModalProps) => (

  <StyledModal
    open={open}
    BackdropComponent={Backdrop}
    padding={padding}
  >
    <ModalBox
      width={width}
      height={height}
      bgColor={bgColor}
      padding={padding}
    >
      <TopContent textPosition={textPosition}>
        <ModalTitle>
          {' '}
          {title}
          {' '}
        </ModalTitle>

        <CloseIcon onClick={onExited} />

      </TopContent>
      <ModalText1 marginDescription={marginDescription} textPosition={textPosition}>
        {' '}
        {description}
        {' '}
      </ModalText1>
      <ModalText2 marginText={marginText}>
        {text}
      </ModalText2>
      <ModalContent>
        {content}
      </ModalContent>
      <ModalButtons
        gapButtons={gapButtons}
      >
        {cancelButton && (
          <Button
            text="Cancelar"
            width={theme.buttons.width.small}
            height={theme.buttons.height.small}
            textColor="#191919"
            backgroundColor={theme.colors.bodyWhite}
            hoverBackground={theme.colors.bodyWhite}
            border="0"
            fontSize="13px"
            boxShadow="0"
            onClick={onExited}
          />
        )}
      </ModalButtons>
      {children}
    </ModalBox>
  </StyledModal>

);

export default ModalUi;
