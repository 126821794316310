import styled from 'styled-components';
import theme from '../../assets/styles/theme';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const CardTitle = styled.p`
  text-align: left;
  font: ${theme.fonts.primary.h3};
  letter-spacing: 0px;
  color: ${theme.colors.mainBlack};
  opacity: 1;
  text-align: center;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 7%;
`;

export const Subtitle = styled.p`
  text-align: left;
  font: ${theme.fonts.secondary.body};
  letter-spacing: 0px;
  color: ${theme.colors.mainGray};
  opacity: 1;
`;

export const Countdown = styled.p`
  text-align: left;
  font: 550 40px Roboto, sans-serif;
  letter-spacing: 0px;
  color: ${theme.colors.mainBlack};
  opacity: 1;
`;
