import styled from 'styled-components';
import theme from '../../assets/styles/theme';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const SettingsPage = styled.div`
  width: ${theme.pages.width};
  max-width: ${theme.pages.maxWidth};
  padding-bottom: 50px;
`;

export const SettingsSection = styled.div`
  width: 50%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
`;

export const SettingsTitle = styled.h2`
  display: flex;
  flex-direction: column;
  font: ${theme.fonts.primary.h2};
  color: ${theme.colors.mainBlack};
  font-weight: bold;
`;

export const SettingsInfo = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-gap: 35px 20px;
  padding: 30px 0 30px 0;
`;

export const FieldTitle = styled.p`
  font: ${theme.fonts.secondary.body};
  padding: 5px 0 5px 0;
  color: ${theme.colors.mainBlack};
  font-weight: bold;
`;

export const FieldInfo = styled.h3`
  font: ${theme.fonts.secondary.h3};
  padding: 5px 0 5px 0;
  color: #484848;
`;

export const CompleteField = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const CreatedIn = styled.p`
  font: ${theme.fonts.secondary.body};
  color: ${theme.colors.mainGray};
`;

export const Drawing = styled.img`
  width: 43%;
  max-width: 800px;
  position: absolute;
  right: 0;
  bottom: 0;
`;

export const ModalBody = styled.div`
  display: flex;
  font: ${theme.fonts.primary.h3};
  color: ${theme.colors.mainBlack};
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;
