/* eslint-disable indent */
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { useReactToPrint } from 'react-to-print';
import { AxiosResponse } from 'axios';
import useAxios from '../../utils/useAxios';

import theme from '../../assets/styles/theme';

import NameParticipants from '../Mocks/NameParticipants.json';

import {
  Header,
  Navbar,
  Button,
  CountdownTimer,
  ProgressCircle,
  SalesChart,
  OutlinedInput,
  TableComponent,
  ModalUi,
  Toast,
  ReportTemplate,
} from '../../components';

import {
  AddCircle,
  Document, Download,
} from '../../assets/icons';

import {
  AutoCompleteContainer,
  Container,
  Content,
  Section,
  SectionTitle,
  ModalContent,
  Title,
  Goals,
  Prizes,
  ModalCard,
  ModalCardCategory,
  ModalCardPrize,
  Sales,
  TopParticipant,
  ParticipantData,
  ParticipantName,
  ParticipantSold,
  SoldText,
  ParticipantRank,
  ParticipantImage,
  BlankParticipant,
  AutoCompleteItem,
  AutoCompleteItemButton,
  TopContent,
} from './style';

type CampaignData = {
  campaign: Campaign,
  goals: Goal[],
  participants: Participant[],
  totalParticipants: number,
  totalSales: number,
  expectedTotalMoney: number,
};

type Campaign = {
  id: string;
  code: number,
  title: string,
  description: string,
  expirationDate: string,
  initialDate: string,
  image: string,
  regulation: string,
  isActive: boolean,
  teamModality: boolean;
};

type Sale = {
  id: string,
  name: string,
};

type Goal = {
  productName: string,
  productDescription: string,
  unityQuantity: number,
  unityPrice: number,
  productCode: number[],
  eachSalePrize: number | null,
  pointValue: number,
  prizes: Prize[];
};

type Prize = {
  name: string,
  price: number,
  photo: string,
  goal: string,
  percentageNeeded: number,
  prizeModality: string,
};

type Participant = {
  id: string;
  name: string;
  sold: number;
  prizes: Prize[];
  goalsPerfomance: GoalsPerfomance[];
};

type GoalsPerfomance = {
  name: string,
  percentageSold: number;
};

type ParticipantSales = {
  participantId: string,
  participantName: string,
  participantSales: Sale[],
  totalSales: number;
};

type TopParticipant = {
  name: string,
  salesAmount: number,
  image: string,
};

type TeamData = {
  campaign: Campaign,
  teamName: string,
  teamSales: ParticipantSales[],
  topParticipants: TopParticipant[],
  totalSales: number,
};

type ParticipantSalesData = {
  sales: Sale[],
  totalSales: number;
};

interface IData {
  name: string;
  id?: string;
  code: string;
}

const teamParticipantColumns = [
  {
    disablePadding: false,
    id: 'name',
    label: 'Nome do(a) participante',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'sales',
    label: 'Vendas(R$)',
    numeric: true,
  },
  {
    disablePadding: false,
    id: 'performance',
    label: 'Desempenho',
    numeric: false,
  },
];

const salesHistoryColumns = [
  {
    disablePadding: false,
    id: 'name',
    label: 'Nome da venda registrada',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'value',
    label: 'Valor(R$)',
    numeric: true,
  },
];

const Team: React.FC = () => {
  const [participantPerformance, setParticipantPerformance] = useState<boolean>(false);
  const [campaignData, setCampaignData] = useState<CampaignData>();
  const [newParticipantName, setNewParticipantName] = useState([] as any);
  const [teamData, setTeamData] = useState<TeamData>();
  const [selectedParticipant, setSelectedParticipant] = useState<Participant>();
  const [participantSalesData, setParticipantSalesData] = useState<ParticipantSalesData>();
  const [percentages, setPercentages] = useState<number[]>(new Array(15).fill(0));
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [suggestionList, setSuggestionList] = useState<IData[]>([]);
  const [participantModal, setParticipantModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [axiosGet] = useAxios('get');
  const [axiosDelete] = useAxios('delete');
  const [axiosPost] = useAxios('post');
  const componentRefParticipant = useRef(null);
  const componentRefTeam = useRef(null);
  const [participantNameList, setParticipantNameList] = useState<typeof NameParticipants>([]);
  const [selectedParticipantID, setSelectedParticipantID] = useState<string>();
  const [loadingTeam, setLoadingTeam] = useState<boolean>(true);
  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(true);
  const [loadingPercentages, setLoadingPercentages] = useState<boolean>(true);

  const [showDeletionModal, setShowDeletionModal] = useState<boolean>(false);
  const [deletionObject, setDeletionObject] = useState<{
    type: string,
    rows: any[],
  }>({
    type: '',
    rows: [],
  });

  const { code: campaignCode, teamCode } = useParams<{ code: string, teamCode: string; }>();

  const ErrorToast = () => (
    <Toast
      type="error"
      message={errorMessage || ''}
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  const handlePrintParticipant = useReactToPrint({
    content: () => componentRefParticipant.current,
  });

  const handlePrintTeam = useReactToPrint({
    content: () => componentRefTeam.current,
  });

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const suggestions = participantNameList
      .sort()
      .filter((v: IData) => String(v.name).toLowerCase().startsWith(value.toLowerCase()));
    if (suggestions.length > 0) {
      setIsComponentVisible(true);
    }

    setSuggestionList(suggestions);
    setNewParticipantName({ suggestions, text: value });
  };

  const suggestionSelected = (value: IData) => {
    setIsComponentVisible(false);

    setNewParticipantName({
      text: value.name,
      suggestions: [],
    });
  };

  const handleCreatePatientTeamRelation = async (userID: string) => {
    await axiosPost({
      url: `/participantTeam/team/${teamCode}/participant/${userID}`,
      success: () => {
        setErrorMessage('');
        setErrorToast(false);
      },
      error: (error: any) => {
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  };

  const addParticipantModal = () => (
    <ModalUi
      open={participantModal}
      onExited={() => setParticipantModal(false)}
      title="Gerencie participantes"
      description="Pesquise pelo nome e adicione mais participantes a essa campanha."
      width="579px"
      height="340px"
      gapButtons="15px"
      cancelButton
      content={(
        <div style={{
          width: '100%',
          marginTop: 10,
          marginBottom: 40,
        }}
        >

          <OutlinedInput
            type="alpha"
            autoComplete="off"
            title="Nome do Participante"
            value={newParticipantName.text}
            setValue={setNewParticipantName}
            suggestionList
            onChange={(e: ChangeEvent<HTMLInputElement>) => onTextChanged(e)}
          />
          {newParticipantName.text?.length > 0
            && suggestionList.length > 0 && isComponentVisible && (
              <div style={{
                position: 'relative',
              }}
              >
                <AutoCompleteContainer>
                  {suggestionList?.map((item: IData) => (
                    <AutoCompleteItem key={item.id}>
                      <AutoCompleteItemButton
                        key={item.id}
                        onClick={() => {
                          suggestionSelected(item);
                          setSelectedParticipantID(item.id);
                        }}
                      >
                        {item.name}
                      </AutoCompleteItemButton>
                    </AutoCompleteItem>
                  ))}
                </AutoCompleteContainer>
              </div>
            )}
        </div>
      )}
    >
      <Button
        text="Inserir na campanha"
        backgroundColor={theme.colors.lighterViolet}
        width={theme.buttons.width.large}
        height={theme.buttons.height.large}
        textColor={theme.colors.mainWhite}
        fontSize={theme.fonts.primary.h2}
        hoverBackground={theme.colors.darkViolet}
        onClick={() => {
          if (selectedParticipantID) {
            handleCreatePatientTeamRelation(selectedParticipantID);
            setParticipantModal(false);
            setSelectedParticipantID(undefined);
            window.location.reload();
          } else {
            setErrorMessage('Este participante não está cadastrado na campanha');
            setErrorToast(true);
          }
        }}
      />
    </ModalUi>
  );

  const handleDeleteParticipants = async () => {
    const requests = deletionObject.rows.map((row: any) => {
      const { participantId } = row;
      return axiosDelete({
        url: `/participantTeam/team/${teamCode}/participant/${participantId}`,
        success: () => {
          setErrorMessage('');
          setErrorToast(false);
        },
        error: (error: any) => {
          const { data } = error.response;
          setErrorMessage(data.message);
          setErrorToast(true);
        },
      });
    });
    await Promise.all(requests);
  };

  const deleteSales = async () => {
    try {
      const requests = deletionObject.rows.map((row: any) => {
        const { id } = row;
        return axiosDelete({
          url: `/sale/${id}`,
          success: () => {
            setErrorMessage('');
            setErrorToast(false);
            window.location.reload();
          },
          error: (error: any) => {
            const { data } = error.response;
            setErrorMessage(data.message);
            setErrorToast(true);
          },
        });
      });
      await Promise.all(requests);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteModal = () => {
    let functionHandler: any;
    if (deletionObject?.type === 'participants') {
      functionHandler = handleDeleteParticipants;
    } else {
      functionHandler = deleteSales;
    }
    return (
      <ModalUi
        open
        onExited={() => setShowDeletionModal(false)}
        title="Confirmar exclusão"
        description={`Você está prestes a excluir ${deletionObject?.rows.reduce(
          (acc: string, row: any) => `${acc} ${row.participantName} da campanha, `,
          '',
        )} tem certeza que deseja prosseguir com a ação?`}
        width="579px"
        height="250px"
        gapButtons="15px"
        cancelButton
      >
        <>
          <Button
            text="Excluir"
            backgroundColor={theme.colors.errorRed}
            width={theme.buttons.width.large}
            height={theme.buttons.height.large}
            textColor={theme.colors.mainWhite}
            fontSize={theme.fonts.primary.h2}
            hoverBackground={theme.colors.darkCherry}
            onClick={async () => {
              functionHandler();
              setShowDeletionModal(false);
              setDeletionObject({ type: '', rows: [] });
            }}
          />
        </>
      </ModalUi>
    );
  };

  const participantPerformanceModal = () => (
    <ModalUi
      open={participantPerformance}
      onExited={() => {
        setParticipantPerformance(false);
      }}
      title={selectedParticipant?.name}
      width="60%"
      height="650px"
      padding="30px 30px 30px 50px"
      content={(
        <ModalContent>
          <Goals>
            <Title>Desempenho das metas</Title>
            <div style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            >
              {selectedParticipant?.goalsPerfomance.map((goal: GoalsPerfomance) => (
                <ProgressCircle
                  type="small"
                  radius={30}
                  height="160px"
                  width="160px"
                  presentValue={goal.percentageSold}
                  fullValue={100}
                  prize={`Vender ${goal.name}`}
                />
              ))}
            </div>
          </Goals>
          <Prizes>
            <Title>
              Saldo de prêmios adquiridos
            </Title>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              marginTop: 10,
            }}
            >
              {selectedParticipant?.prizes.map((prize: Prize) => {
                if (prize.name && prize?.name.includes('PocketCard')) {
                  return (
                    <ModalCard>
                      <ModalCardCategory>{prize?.name}</ModalCardCategory>
                      <ModalCardPrize>
                        R$
                        {' '}
                        {prize?.price?.toLocaleString('pt-BR')}
                      </ModalCardPrize>
                    </ModalCard>
                  );
                }
                if (prize?.prizeModality === 'by each sale') {
                  return (
                    <ModalCard>
                      <ModalCardCategory>Total ganho pela venda de unidades</ModalCardCategory>
                      <ModalCardPrize>
                        R$
                        {' '}
                        {prize.price?.toLocaleString('pt-BR')}
                      </ModalCardPrize>
                    </ModalCard>
                  );
                }

                return (
                  <ModalCard>
                    <ModalCardCategory>Prêmio físico</ModalCardCategory>
                    <ModalCardPrize>{prize.name}</ModalCardPrize>
                  </ModalCard>
                );
              })}

              {selectedParticipant?.prizes.length === 0 && (
                <ModalCard style={{ textAlign: 'center' }}>
                  <ModalCardCategory>Este participante ainda não obteve prêmios</ModalCardCategory>
                </ModalCard>
              )}

            </div>
          </Prizes>
          <Sales>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 15,
            }}
            >
              <Title>Histórico de vendas</Title>
              <Button
                iconLeft={<Document />}
                iconRight={<Download />}
                borderRadius={theme.buttons.borderRadius.medium}
                width="280px"
                height={theme.buttons.height.large}
                onClick={handlePrintParticipant}
                // Trigger
                // Notification
                subText="Formatos disponíveis: .pdf e .xml"
                text="Fazer download do histórico"
                textColor={theme.colors.mainBlack}
                alinText="left"
              />
            </div>
            <TableComponent
              title="Histórico de vendas"
              saleNameData={participantSalesData?.sales}
              deleteRows={(value: any) => {
                setShowDeletionModal(true);
                setDeletionObject({
                  rows: value,
                  type: 'sales',
                });
              }}
              columns={salesHistoryColumns}
              filterTable={async (query: string) => {
                const id = selectedParticipant?.id || '';
                // await fetchParticipantSales(id, `&${query}&type=sales`);
              }}
            />
          </Sales>
          <div style={{ zIndex: -10, display: 'none' }}>
            {campaignData?.campaign.code && selectedParticipant?.id && (
              <ReportTemplate
                campaignCode={campaignData?.campaign.code}
                reportType="specific"
                componentRef={componentRefParticipant}
                participantId={selectedParticipant.id}
              />
            )}
          </div>
        </ModalContent>
      )}

    />
  );

  const fetchCampaignData = useCallback(async (query = '&order=default&rows=10&page=0') => {
    await axiosGet({
      url: `/campaign/${campaignCode}?get=full${query || ''}`,
      success: (res: AxiosResponse) => {
        setCampaignData(res.data.data);
        setErrorMessage('');
        setErrorToast(false);
        setTimeout(() => setLoadingCampaign(false), 500);
      },
      error: (error: any) => {
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  }, [axiosGet, campaignCode]);

  const fetchPercentages = useCallback(async () => {
    await axiosGet({
      url: `/sale/chart/${campaignCode}?teamCode=${teamCode}`,
      success: (res: AxiosResponse) => {
        setPercentages(res.data.data.percentages);
        setErrorMessage('');
        setErrorToast(false);
        setTimeout(() => setLoadingPercentages(false), 500);
      },
      error: (error: any) => {
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  }, [axiosGet, campaignCode, teamCode]);

  const fetchTeamSales = useCallback(async () => {
    await axiosGet({
      url: `/team/campaign/${campaignCode}/sales/${teamCode}`,
      success: (res: AxiosResponse) => {
        setTeamData(res.data.data);
        setErrorMessage('');
        setErrorToast(false);
        setTimeout(() => setLoadingTeam(false), 500);
      },
      error: (error: any) => {
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  }, [axiosGet, campaignCode, teamCode]);

  const fetchParticipantSales = async (id: string, query = 'order=default&rows=10&page=0') => {
    await axiosGet({
      url: `/sale/participant/${id}/campaign/${campaignCode}?${query || ''}`,
      success: (res: AxiosResponse) => {
        setParticipantSalesData(res.data.data);
        setErrorMessage('');
        setErrorToast(false);
      },
      error: (error: any) => {
        setErrorMessage(error);
        setErrorToast(true);
      },
    });
  };

  const fetchParticipants = async () => {
    await axiosGet({
      url: '/participant',
      success: (res: AxiosResponse) => {
        setParticipantNameList(res.data.data.participants.map((participant: Participant) => ({
          id: participant.id,
          name: participant.name,
        })));
        setErrorMessage('');
        setErrorToast(false);
      },
      error: (error: any) => {
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  };

  useEffect(() => {
    (async () => {
      await fetchCampaignData();
      await fetchTeamSales();
      await fetchPercentages();
      await fetchParticipants();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {ErrorToast()}
      <Container>
        <Content>
          <Navbar />
          <Header
            title={teamData?.teamName}
            overTitleText="Visualizar desempenho"
            onClickReturn={() => {
              window.location.href = `/campanha/${campaignCode}`;
            }}
          >
            <div style={{ alignSelf: 'flex-start', marginTop: '20px' }}>
              <Button
                text="Baixar resumo do time"
                backgroundColor="#F2F2F2"
                hoverBackground="#F2F2F2"
                width="280px"
                height="66px"
                textColor="#1C1C1C"
                borderRadius={theme.buttons.borderRadius.medium}
                iconRight={<Download />}
                border="1px solid #ECECEC"
                subText="Arquivos disponíveis: .pdf e .xml"
                onClick={handlePrintTeam}
              />
            </div>
          </Header>

          <div style={{ zIndex: -10, display: 'none' }}>
            {campaignData?.campaign.code && teamCode && (
              <ReportTemplate
                campaignCode={campaignData?.campaign.code}
                reportType="individualTeam"
                componentRef={componentRefTeam}
                teamCode={teamCode}
              />
            )}
          </div>

          {participantPerformance && participantPerformanceModal()}

          {showDeletionModal && deleteModal()}

          {participantModal && addParticipantModal()}

          <Section>
            <SectionTitle>Vendas do time</SectionTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0' }}>
              {loadingTeam && loadingCampaign ? (<Skeleton variant="rectangular" width="60%" height={430} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: theme.card.borderRadius.large }} />) : (
                <SalesChart
                  width="60%"
                  height="430px"
                  sales={teamData?.totalSales}
                  percents={percentages}
                />
              )}
              <div>
                {loadingCampaign ? (
                  <>
                    <Skeleton variant="rectangular" width={270} height={138} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: theme.card.borderRadius.medium }} />
                    <Skeleton variant="rectangular" width={270} height={272} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: theme.card.borderRadius.medium, margin: '20px 20px 20px 0' }} />
                  </>
                ) : (
                  <>
                    {campaignData
                      && <CountdownTimer date={campaignData?.campaign.expirationDate} />}
                    <ProgressCircle
                      presentValue={teamData?.totalSales}
                      fullValue={campaignData?.expectedTotalMoney}
                    />
                  </>
                )}
              </div>
            </div>
          </Section>

          <Section>
            <SectionTitle>Top participantes do time</SectionTitle>

            <div style={{ display: 'flex' }}>
              {loadingTeam ? (<Skeleton variant="rectangular" width="100%" height={145} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: theme.card.borderRadius.medium }} />) : (
                <>
                  {teamData?.topParticipants.map((participant, index) => (
                    <TopParticipant>
                      {participant.image
                        ? <ParticipantImage src={participant.image} alt="Imagem participante" />
                        : <BlankParticipant />}

                      <ParticipantData>
                        <ParticipantRank>
                          #
                          {index + 1}
                        </ParticipantRank>
                        <ParticipantName>{participant.name}</ParticipantName>

                        <div style={{ display: 'flex', alignItems: 'baseline' }}>
                          <ParticipantSold>{participant.salesAmount}</ParticipantSold>
                          <SoldText>Vendas</SoldText>
                        </div>

                      </ParticipantData>
                    </TopParticipant>
                  ))}
                </>
              )}
            </div>
          </Section>
          <Section>
            <TopContent>
              <SectionTitle>Participantes do time</SectionTitle>
              <TopContent>
                <Button
                  text="Adicionar participante"
                  width={theme.buttons.width.large}
                  height={theme.buttons.height.medium}
                  backgroundColor="transparent"
                  border={`2px solid ${theme.colors.mainGray}`}
                  borderRadius={theme.buttons.borderRadius.medium}
                  textColor={theme.colors.mainGray}
                  iconLeft={<AddCircle />}
                  onClick={() => setParticipantModal(true)}
                />
              </TopContent>
            </TopContent>
            {loadingTeam && loadingCampaign ? (

              <Skeleton
                variant="rectangular"
                width="100%"
                height={145}
                animation="wave"
                sx={{ bgcolor: theme.colors.lighterGray }}
                style={{ borderRadius: theme.card.borderRadius.small }}
              />
            ) : (
              <>

                <TableComponent
                  title="Participantes do time"
                  columns={teamParticipantColumns}
                  teamParticipantData={teamData?.teamSales}
                  total={campaignData?.totalParticipants}
                  deleteRows={(value: any) => {
                    setShowDeletionModal(true);
                    setDeletionObject({
                      rows: value,
                      type: 'participants',
                    });
                  }}
                  onClickLink={async (row: ParticipantSales) => {
                    setParticipantPerformance(true);
                    const participantsArray = campaignData?.participants || [];

                    const fullParticipant: Participant = participantsArray?.filter(
                      (participant: Participant) => participant.id === row.participantId,
                    )[0];

                    setSelectedParticipant(fullParticipant);
                    await fetchParticipantSales(row.participantId);
                  }}
                  filterTable={(query: string) => {
                    fetchCampaignData(`&${query}&type=participants`);
                  }}
                />
              </>
            )}
          </Section>
        </Content>
      </Container>
    </>
  );
};

export default Team;
