/* eslint-disable react/require-default-props */
import React from 'react';
import {
  ChartBody,
  ChartContent,
  ChartDescription,
  ChartDescriptionText,
  ChartGraphic,
  ChartGraphicDate,
  ChartSales,
  ChartTitle,
  ChartDescriptionebol,
  ChartTitleContent,
  ChartSelect,
  ChartGraphicContent,
} from './style';

const monthsArray = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export interface ChartProps {
  sales: number;
  percents: number[],
  width?: string;
  height?: string;
}

const SalesChart: React.ElementType = ({
  sales,
  percents,
  width,
  height,
}: ChartProps) => {
  const initialMonth = new Date().getMonth();
  return (

    <ChartBody width={width} height={height}>
      <ChartTitle> Valor total obtido </ChartTitle>
      <ChartTitleContent>
        <ChartSales>
          {' '}
          R$
          {' '}
          {sales?.toLocaleString('pt-BR',
            { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          {' '}
        </ChartSales>
        <ChartSelect>
          {monthsArray[initialMonth]}
        </ChartSelect>
      </ChartTitleContent>
      <ChartContent>
        {percents.map((
          percent: number,
          index: number,
        ) => {
          const currentDay = new Date();
          currentDay.setDate(currentDay.getDate() - 14 + index);
          return (
            <ChartGraphicContent>
              <ChartGraphic
                vertical
                percent={percent}
                strokeColor="#7136E8"
                strokeWidth={17.5}
                trailWidth={17.5}
                trailColor="#E1E1E1"
                showInfo={false}
              />
              <ChartGraphicDate>
                {' '}
                {currentDay.getDate()}
                {' '}
              </ChartGraphicDate>
            </ChartGraphicContent>
          );
        })}
      </ChartContent>
      <ChartDescription>
        <ChartDescriptionText>
          <ChartDescriptionebol />
          Desempenho esperado
        </ChartDescriptionText>
        <ChartDescriptionText>
          <ChartDescriptionebol colorBol="#7136E8" />
          Desempenho alcançado
        </ChartDescriptionText>
      </ChartDescription>
    </ChartBody>

  );
};

export default SalesChart;
