/* eslint-disable react/require-default-props */
import * as React from 'react';
import { Button } from '../../index';
import theme from '../../../assets/styles/theme';
import { Warning } from '../../../assets/icons';
import {
  StyledModal,
  Backdrop,
  Body,
  TopBody,
  BottomBody,
  Text,
  SVGContainer,
} from './style';

export interface ModalProps {
  open: boolean;
  onClose?: () => void;
  confirmButton?: () => void;
}

const WarningUi: React.ElementType = ({
  open,
  onClose,
  confirmButton,
}: ModalProps) => (
  <StyledModal
    open={open}
    BackdropComponent={Backdrop}
  >
    <Body>
      <TopBody>
        <SVGContainer>
          <Warning />
        </SVGContainer>
        <Text> Você está prestes a ser redirecionado à www.novocred.com.br </Text>
      </TopBody>
      <BottomBody>
        <Button
          text="Cancelar"
          width={theme.buttons.width.small}
          height={theme.buttons.height.small}
          textColor="#191919"
          backgroundColor={theme.colors.bodyWhite}
          hoverBackground={theme.colors.bodyWhite}
          border="0"
          fontSize={theme.fonts.primary.h3}
          boxShadow="0"
          onClick={onClose}
        />
        <Button
          text="Prosseguir"
          backgroundColor={theme.colors.lighterViolet}
          width={theme.buttons.width.medium}
          height={theme.buttons.height.medium}
          textColor={theme.colors.mainWhite}
          fontSize={theme.fonts.primary.h2}
          hoverBackground={theme.colors.darkViolet}
          onClick={confirmButton}
        />
      </BottomBody>
    </Body>
  </StyledModal>
);
export default WarningUi;
