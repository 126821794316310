import React, { useState, useContext } from 'react';
import {
  GeneralCard, Button, ModalUi, OutlinedInput,
} from '../../../../components';
import theme from '../../../../assets/styles/theme';
import { ArrowRight } from '../../../../assets/icons';

import { campaignDataContext } from '../../../../hooks/campaignContext';

import {
  StepContainer,
  Title,
  CardsContainer,
  CardTitle,
  CardDescription,
  CardBody,
  PlusButton,
  TeamName,
  CloseButton,
  TeamNamesContainer,
  TitleAndButtonDiv,
} from './style';

type StepProps = {
  current: number,
  SetCurrent: (current: number) => void;
}

const Participants: React.ElementType = ({
  current,
  SetCurrent,
}: StepProps) => {
  const { campaign } = useContext(campaignDataContext);
  const [individual, setIndividual] = useState<boolean>(false);
  const [team, setTeam] = useState<boolean>(false);
  const [newTeam, setNewTeam] = useState<string>('');
  const [finishTeams, setFinishTeams] = useState<boolean>(false);

  return (
    <>
      <StepContainer>
        <TitleAndButtonDiv>
          <Title>Selecionar modalidade de participantes</Title>
          {team && (
            <div style={{ justifySelf: 'end' }}>
              <Button
                text="PROSSEGUIR"
                backgroundColor={theme.colors.bodyWhite}
                hoverBackground="#EFEFEF"
                hoverTextColor="#363636"
                border={`2px solid ${theme.colors.mainGray}`}
                borderRadius={theme.buttons.borderRadius.medium}
                width={theme.buttons.width.large}
                height={theme.buttons.height.medium}
                textColor={theme.colors.mainGray}
                iconRight={<ArrowRight />}
                onClick={() => {
                  if (campaign.teamNames.length > 0) {
                    setFinishTeams(true);
                  } else {
                    alert('Você precisa criar pelo menos um time para escolher essa modalidade');
                  }
                }}
              />
            </div>
          )}
        </TitleAndButtonDiv>

        <CardsContainer>
          {(!campaign.teamModality) && (
            <>
              <GeneralCard width="48%" height="250px">
                <CardBody>
                  <CardTitle>Times</CardTitle>
                  <CardDescription>
                    Adicione times e os nomeie para que o sistema gere códigos de
                    acesso para a sua campanha.
                  </CardDescription>
                  <Button
                    text="Selecionar modalidade"
                    backgroundColor={theme.colors.lightViolet}
                    hoverBackground={theme.colors.darkViolet}
                    borderRadius={theme.buttons.borderRadius.large}
                    width="95%"
                    height={theme.buttons.height.medium}
                    textColor={theme.colors.bodyWhite}
                    onClick={() => {
                      setTeam(true);
                      campaign.setTeamModality(true);
                    }}
                  />
                </CardBody>
              </GeneralCard>

              <GeneralCard width="48%" height="250px">
                <CardBody>
                  <CardTitle>Individuais</CardTitle>
                  <CardDescription>
                    Participantes individuais podem ingressar na sua campanha através
                    do código da mesma.
                  </CardDescription>
                  <Button
                    text="Selecionar modalidade"
                    backgroundColor={theme.colors.lightViolet}
                    hoverBackground={theme.colors.darkViolet}
                    borderRadius={theme.buttons.borderRadius.large}
                    width="95%"
                    height={theme.buttons.height.medium}
                    textColor={theme.colors.bodyWhite}
                    onClick={() => setIndividual(true)}
                  />
                </CardBody>
              </GeneralCard>
            </>
          )}

          {(campaign.teamModality) && (
            <>
              <GeneralCard width="90%" height="300px">
                <CardBody>
                  <CardTitle>Times</CardTitle>
                  <CardDescription>
                    Participantes individuais podem ingressar na sua campanha
                    através do código da mesma.
                  </CardDescription>
                  <div style={{ width: '80%', display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: '450px', margin: '0 15px' }}>
                      <OutlinedInput
                        title="Nome do time"
                        type="alphanumeric"
                        value={newTeam}
                        setValue={setNewTeam}
                      />
                    </div>
                    <PlusButton onClick={() => {
                      if (newTeam !== '') {
                        let name = newTeam.charAt(0).toUpperCase() + newTeam.slice(1);
                        if (name.split(' ')[0] !== 'Time') {
                          name = `Time ${name}`;
                        }
                        if (name !== 'Time' && !campaign.teamNames.includes(name)) {
                          campaign.setTeamNames([
                            ...campaign.teamNames,
                            name,
                          ]);
                          setNewTeam('');
                        }
                      }
                    }}
                    />
                  </div>

                  <TeamNamesContainer>
                    {campaign.teamNames.map((name) => (
                      <TeamName>
                        <div style={{ display: 'flex', justifyContent: 'center', marginRight: '5px' }}>
                          {name}
                        </div>
                        <CloseButton onClick={() => {
                          campaign.setTeamNames([
                            ...campaign.teamNames.filter((value) => name !== value),
                          ]);
                        }}
                        />
                      </TeamName>
                    ))}
                  </TeamNamesContainer>
                </CardBody>
              </GeneralCard>
            </>
          )}
        </CardsContainer>

        <ModalUi
          open={individual}
          onExited={() => setIndividual(false)}
          title="Modalidade individual"
          description="O código destinado à esta modalidade será gerado e você poderá visualiza-lo na
          última etapa de criação da campanha, assim como na página de visualização da campanha."
          width="579px"
          height="240px"
        >
          <Button
            text="Continuar"
            backgroundColor={theme.colors.lighterViolet}
            width={theme.buttons.width.large}
            height={theme.buttons.height.large}
            textColor={theme.colors.mainWhite}
            fontSize={theme.fonts.primary.h2}
            hoverBackground={theme.colors.darkViolet}
            onClick={() => {
              SetCurrent(current + 1);
              campaign.setTeamModality(false);
            }}
          />
        </ModalUi>

        <ModalUi
          open={finishTeams}
          onExited={() => setFinishTeams(false)}
          title="Modalidade times"
          description="O código destinado à esta modalidade será gerado e você poderá visualiza-lo
          na última etapa de criação da campanha, assim como na página de visualização da campanha."
          width="579px"
          height="240px"
        >
          <Button
            text="Continuar"
            backgroundColor={theme.colors.lighterViolet}
            width={theme.buttons.width.large}
            height={theme.buttons.height.large}
            textColor={theme.colors.mainWhite}
            fontSize={theme.fonts.primary.h2}
            hoverBackground={theme.colors.darkViolet}
            onClick={() => {
              SetCurrent(current + 1);
              campaign.setTeamModality(true);
            }}
          />
        </ModalUi>
      </StepContainer>
    </>

  );
};
export default Participants;
