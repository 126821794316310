import styled from 'styled-components';
import theme from '../../assets/styles/theme';

type CardProps = {
  backgroundColor?: string;
  width?: string;
  height?: string;
  padding?: string;
  clickableImage?: boolean;
}

export const CardMain = styled.div<CardProps>`
  width: ${(props: CardProps) => props.width || '270px'};
  border-radius: ${theme.card.borderRadius.large};
  box-shadow: 0px 3px 6px #00000029;
  background-color: ${(props: CardProps) => props.backgroundColor || theme.colors.bodyWhite};
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const CardImage = styled.div<CardProps>`
  width: 100%;
  height: 140px;
  border-radius: 33px 33px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: ${(props: CardProps) => props.clickableImage && 'pointer'};

  img {
    height: 100%;
    width: 100%;
    object-fit: cover 
  }
`;

export const CardBody = styled.div<CardProps>`
  height: ${(props: CardProps) => props.height || '210px'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding:${(props: CardProps) => props.padding || '15px 24px 25px 24px'};
`;

export const CardInformation = styled.div<CardProps>`
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;  
`;

export const CardTitle = styled.h1`
  font: ${theme.fonts.primary.h1};
  font-weight: 600;
  color: ${theme.colors.mainBlack};
  margin-bottom:9px ;
`;

export const CardDescription = styled.p`
  font: ${theme.fonts.secondary.small};
  color: ${theme.colors.mainBlack};
`;
