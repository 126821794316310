import React, {
  useRef, useState, useEffect,
} from 'react';
import crypto from 'crypto';
import { AxiosResponse } from 'axios';
import { useAuth } from '../../hooks/Auth';
import useAxios from '../../utils/useAxios';
import theme from '../../assets/styles/theme';

import {
  StandardInput,
  Button,
  WarningUi,
  ModalUi,
  OutlinedInput,
  Toast,
} from '../../components';

import {
  LoginPage,
  LoginContainerBorder,
  LoginContainer,
  LoginTitle,
  InputContainer,
  InputTitle,
  ForgotPasswordText,
  AuxiliarTextOne,
  AuxiliarTextTwo,
  GenericContainer,
  ModalContainer,
} from './style';

const Login: React.FC = () => {
  const { signIn, error, setError } = useAuth();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [recoveryMail, setRecoveryMail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [clickedInput, setClickedInput] = useState('');
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [axiosPost] = useAxios('post');

  const emailInputRef = useRef<HTMLDivElement | null>(null);
  const passwordInputRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (e: { target: any; }) => {
    const emailReference = emailInputRef.current;
    const passwordReference = passwordInputRef.current;
    if ((emailReference && !emailReference.contains(e.target))
      && (passwordReference && !passwordReference.contains(e.target))) {
      setClickedInput('');
    }
  };

  const isEmpty = (string: string) => string.length === 0;

  const submitLogin = async () => {
    if (!isEmpty(email) && !isEmpty(password)) {
      // const hashedPassword = crypto.createHash('sha256').update(password).digest('hex');

      await signIn({ email, password }).then((errorSignIn) => {
        if (errorSignIn) {
          setErrorToast(true);
          setErrorMessage(errorSignIn.message);
        } else {
          setErrorToast(false);
          setErrorMessage(null);
          window.location.href = '/minhas-campanhas';
        }
      });
    }
  };

  const sendPasswordEmail = async () => {
    if (!isEmpty(recoveryMail)) {
      setShowPasswordModal(false);
      await axiosPost({
        url: 'user/forgot-password',
        body: { email: recoveryMail },
        success: async (res: AxiosResponse) => {
          setSuccessToast(true);
          setSuccessMessage(res.data.message);
        },
        error: (err: any) => {
          setErrorToast(true);
          setErrorMessage(err.response.data.message);
        },
      });
    }
  };

  const ErrorToast = () => (
    <Toast
      type="error"
      message={errorMessage}
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  const SuccessToast = () => (
    <Toast
      type="success"
      message={successMessage}
      open={successToast}
      setOpen={setSuccessToast}
    />
  );

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !showPasswordModal) {
      submitLogin();
    } else if (e.key === 'Enter' && showPasswordModal) {
      sendPasswordEmail();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (error?.message) {
      setErrorMessage(error.message);
      setErrorToast(true);
    }
  }, []);

  return (
    <>
      {ErrorToast()}
      {SuccessToast()}
      <LoginPage onKeyUp={handleEnterKey}>
        <LoginContainerBorder>
          <LoginContainer>
            <LoginTitle>Login</LoginTitle>
            <InputContainer>
              <InputTitle style={{
                color: clickedInput === 'email'
                  ? theme.colors.lighterViolet
                  : theme.colors.mainGray,
              }}
              >
                E-mail
              </InputTitle>
              <GenericContainer ref={emailInputRef} onClick={() => setClickedInput('email')}>
                <StandardInput
                  placeholder="Insira seu e-mail aqui"
                  type="email"
                  required
                  value={email}
                  setValue={setEmail}
                />
              </GenericContainer>
            </InputContainer>
            <InputContainer>
              <InputTitle style={{
                color: clickedInput === 'password'
                  ? theme.colors.lighterViolet
                  : theme.colors.mainGray,
              }}
              >
                Senha
              </InputTitle>
              <GenericContainer ref={passwordInputRef} onClick={() => setClickedInput('password')}>
                <StandardInput
                  placeholder="Senha"
                  type="password"
                  required
                  value={password}
                  setValue={setPassword}
                />
              </GenericContainer>
              <ForgotPasswordText
                onClick={() => setShowPasswordModal(true)}
              >
                Esqueci minha senha
              </ForgotPasswordText>
            </InputContainer>
            <Button
              width="70%"
              height="45px"
              backgroundColor={theme.colors.lighterViolet}
              borderRadius="12px"
              boxShadow="0px 2px 6px #0000004D"
              text="Entrar"
              fontSize="18px"
              textColor={theme.colors.bodyWhite}
              hoverBackground={theme.colors.darkViolet}
              onClick={() => submitLogin()}
            />
          </LoginContainer>
        </LoginContainerBorder>
        <AuxiliarTextOne>Ainda não possui uma conta?</AuxiliarTextOne>
        <AuxiliarTextTwo onClick={() => setShowModal(true)}>Cadastre-se agora</AuxiliarTextTwo>
        <WarningUi
          open={showModal}
          onClose={() => setShowModal(false)}
          confirmButton={() => {
            window.open('http://www.novocred.com.br', '_blank');
            setShowModal(false);
          }}
        />
        <ModalUi
          open={showPasswordModal}
          onExited={() => setShowPasswordModal(false)}
          title="Redefinir senha"
          description={`O seu pedido de redefinição de senha será enviado para o email
            informado no cadastro: ${recoveryMail === '' ? 'seu_email' : recoveryMail}. 
            Digite o email no campo abaixo para receber o pedido`}
          width="579px"
          height="auto"
        >
          <ModalContainer>
            <OutlinedInput
              small
              allowError
              type="email"
              title="E-mail de recuperação"
              value={recoveryMail}
              setValue={setRecoveryMail}
            />
            <GenericContainer style={{ marginTop: '25px', alignSelf: 'flex-end' }}>
              <Button
                text="Cancelar"
                width={theme.buttons.width.small}
                height={theme.buttons.height.small}
                textColor="#191919"
                backgroundColor={theme.colors.bodyWhite}
                hoverBackground={theme.colors.bodyWhite}
                border="0"
                fontSize={theme.fonts.primary.h3}
                boxShadow="0"
                onClick={() => setShowPasswordModal(false)}
              />
              <Button
                text="Enviar"
                backgroundColor={theme.colors.lighterViolet}
                width={theme.buttons.width.small}
                height={theme.buttons.height.large}
                textColor={theme.colors.mainWhite}
                fontSize={theme.fonts.primary.h2}
                hoverBackground={theme.colors.darkViolet}
                onClick={sendPasswordEmail}
              />
            </GenericContainer>
          </ModalContainer>
        </ModalUi>
      </LoginPage>
    </>

  );
};

export default Login;
