import styled from 'styled-components';
import theme from '../../../../assets/styles/theme';
import {
  Create, Plus, Picture, Close,
} from '../../../../assets/icons';

type SectionTitleProp = {
  big: boolean;
}

type SectionColumnProp = {
  height?: string;
  margin?: string;
}

type ModalitySwitchProp = {
  value: string;
  expectedValue: string;
}

type UnderTextProp = {
  color?: string;
}

type PrizeModalityProp = {
  active: boolean;
}

type PrizeCardProp = {
  selected: number;
  index: number;
}

type GoalInfoPros = {
  bold: boolean
}

type PrizeBoxState = {
  prizeName: string | undefined;
}

type ImageProps = {
  imageUrl?: string,
}

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const CardTitle = styled.h3`
  font: ${theme.fonts.primary.h3};
  font-weight: bold;
  padding-bottom: 15px;
`;

export const ProductSection = styled.div`
  width: 98%;
  height: 50%;
  padding: 25px 45px;

  align-self: flex-start;
  display: flex;
  justify-content: space-between;
`;

export const PrizeSection = styled.div`
  width: 103%;
  height: 50%;
  padding: 25px 35px;

  border-top: 1px solid #E1E1E1;
`;

export const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  width: 42%; 
`;

export const ProductID = styled.div`
  display: flex;
  flex-direction: column;
  width: 42%;
  height: 100%;

  .MuiFormGroup-root {
      flex-direction: row;
      display: flex;
      flex-wrap: nowrap;
      width: 120%;
    }

  .MuiRadio-colorSecondary.Mui-checked {
    color: ${theme.colors.lighterViolet};
  }

  .MuiTypography-body1{
    font: ${theme.fonts.primary.body};
    color: ${theme.colors.mainBlack};
  }
`;

export const SectionTitle = styled.h3`
  font: ${(props: SectionTitleProp) => (props.big ? theme.fonts.primary.h3 : theme.fonts.primary.body)};
  color: ${theme.colors.mainBlack};
`;

export const SectionDescription = styled.p`
  font: ${theme.fonts.secondary.small};
  color: ${theme.colors.secondaryGray};
`;

export const SectionColumn = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: ${(props: SectionColumnProp) => props.height};
  margin: ${(props: SectionColumnProp) => props.margin};
`;

export const PrizeSectionDescription = styled.p`
  font: ${theme.fonts.secondary.body};
  color: ${theme.colors.secondaryGray};
`;

export const ModalitySwitch = styled.h2`
  font:  ${theme.fonts.primary.h2}; 
  font-weight: ${(props: ModalitySwitchProp) => (props.value === props.expectedValue) && 'bold'};

  color: ${(props: ModalitySwitchProp) => (props.value === props.expectedValue
    ? theme.colors.mainBlack : theme.colors.secondaryGray)};

  &:hover{
    cursor: pointer;
  }
`;

export const InputTitle = styled.p`
  font: ${theme.fonts.primary.body};
  color: ${theme.colors.mainBlack};
`;

export const PrizeSelection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 95%;
  max-width: 650px;
  margin: 40px 0 0 0;
`;

export const PrizeBox = styled.div`
  width: 32%;
  height: 170px;
  border: ${`1px solid ${theme.colors.secondaryGray}`};
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0 20px 0;
  border-radius: 20px;
  text-align: center;


  &:hover {
    background-color: ${(props: PrizeBoxState) => !props.prizeName && '#EBEBEB'};
    cursor: ${(props: PrizeBoxState) => !props.prizeName && 'pointer'};
  }
`;

export const EditContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 35%;
  cursor: pointer;
`;

export const CreateIcon = styled(Create)`
  color: #7E7E7E;
  width: 16px;
  height: 20px;
  stroke-width: 1.2px;
`;

export const PlusIcon = styled(Plus)`
  width: 100%;
  height: 100%;
  stroke-width: 0.4px;
`;

export const PrizeUnderText = styled.p`
  font: ${theme.fonts.primary.small};
  color: ${(props: UnderTextProp) => props.color || '#7E7E7E'};
`;

export const PrizeModalContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const PrizeModality = styled.h4`
  font: ${theme.fonts.primary.body};
  color: ${theme.colors.mainBlack};
  margin-right: 20px;
  cursor: pointer;

  font-weight: ${(props: PrizeModalityProp) => props.active && 'bold'};
  border-bottom: ${(props: PrizeModalityProp) => props.active && `1.5px solid ${theme.colors.mainBlack}`};

`;

export const ModalCard = styled.div`
  background: ${theme.colors.bodyWhite};
  box-shadow: 0px 3px 6px #0000001A;
  border: 1px solid #E1E1E1;
  border-radius: 20px;
  width: 100%;
  height: 100px;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 10px;
  cursor: pointer;

  border: ${(props: PrizeCardProp) => props.selected === props.index && `4px solid ${theme.colors.lighterViolet}`};
`;

export const PrizeName = styled.h3`
  font: ${theme.fonts.primary.body};
  color: ${theme.colors.mainBlack};
`;

export const UploadDiv = styled.div`
  width: 140px;
  height: 140px;
  background: ${(props: ImageProps) => (props.imageUrl ? `url(${props.imageUrl}) no-repeat center` : theme.colors.mainBlack)};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;

export const PictureIcon = styled(Picture)`
  color: ${theme.colors.bodyWhite};
  width: 50px;
  height: 50px;
  display: ${(props: ImageProps) => (props.imageUrl ? 'none' : 'inline')};
`;

export const UploadButtonDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 140px;
  padding-top: 10px;
  margin: 0 auto;
  cursor: pointer;
  font: ${theme.fonts.secondary.small};
  color: ${theme.colors.mainBlack};

  input {
    display: none;
  }
`;

export const ValueInput = styled.div`
  width: 80%;
`;

export const RegistredGoal = styled.div`
  width: 95%;
  max-width: 300px;
  min-height: 180px;
  background-color: #EFEFEF;
  border-radius: 20px;
  padding: 5px 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin: 0 0 20px 0;

  @media (max-width: 1050px){
    width: 70%;
  }
`;

export const RegistredGoalText = styled.p`
  font: ${theme.fonts.secondary.small};
  color: ${theme.colors.mainBlack};
`;

export const GoalInfo = styled.h3`
  font: ${theme.fonts.secondary.body};
  color: ${theme.colors.mainBlack};
  font-weight: ${(props: GoalInfoPros) => (props.bold && 'bold')};
  margin-right: 5px;
`;

export const FlexBox = styled.div`
  display: flex;
`;

export const IconContainer = styled.button`
  margin-left: 750px;
  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const ModalContainer = styled.div`
  width: 700px;
  height: 460px;
  background-color: white;
  border-radius: 20px;
  box-shadow:  0px 3px 6px #0000001A;
  padding: 20px;
  padding-top: 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 50px;
  height: 350px;
`;

export const SecondIconContainer = styled.button`
  border: none;
  background-color: transparent;
  margin-left: 620px;
  position: absolute;
  cursor: pointer;
`;

export const ContentContainer = styled.div`
  width: 280px;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
 `;

export const PlusButton = styled(Plus)`
  stroke-width: 0.3px;
  height: 100%;
  width: 56px;
  border: 1px solid ${theme.colors.mainGray};
  border-radius: 10px;
  cursor: pointer;
`;

export const TeamNamesContainer = styled.div`
  display: flex;
  width: 70%;
  justify-content: flex-start;
  overflow-y : auto;
  flex-wrap: wrap;
  height: 60px;
`;

export const TeamName = styled.div`
  display: flex;
  justify-content: space-between;
  height: 25px;
  background-color: ${theme.colors.mainBlack};
  color: ${theme.colors.bodyWhite};
  font: ${theme.fonts.primary.body};
  border-radius: 23px;
  align-items: center;
  padding: 0 10px;
  margin: 0 10px 5px 0;
`;

export const CloseButton = styled(Close)`
  width: 12px;
  stroke-width: 2px;
  cursor: pointer;
`;
