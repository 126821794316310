import styled from 'styled-components';
import theme from '../../assets/styles/theme';

type NavbarProps = {
  color?: string;
  backgroundColor?: string;
  transform?: boolean;
}

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: 40px;
  margin-top: 25px;
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Icon = styled.div<NavbarProps>`
  width: 55px;
  height: 55px;
  background-color: ${(props: NavbarProps) => props.backgroundColor || `${theme.colors.darkViolet}`};
  border-radius: 100%;
`;

export const Information = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TopInformation = styled.div`
  width: 100%;
  height: 25px;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const User = styled.p`
  font: ${theme.fonts.secondary.body};
  color: ${theme.colors.mainBlack};
  margin: 0px;
  font-weight: bold;
  margin-right: 20px;
`;

export const Arrow = styled.button<NavbarProps>`
  background-color: transparent;
  cursor: pointer;
  border: 0;
  transition: 0.3s ease-in-out;
  transform:${(props: NavbarProps) => (props.transform ? 'rotate(-180deg)' : 'rotate(-360deg)')};
  outline: none;
  margin: 3px 3px 0 0;

  svg {
    width: 19px;
    height: 19px;
  }
`;

export const Description = styled.p<NavbarProps>`
  font: ${theme.fonts.secondary.small};
  color: ${(props: NavbarProps) => props.color || '#707070'};
  margin: 0px;
`;

export const ModalCard = styled.div`
  width: 210px;
  height: 135px;
  background-color: ${theme.colors.bodyWhite};
  margin-top: 50px;
  border-radius: 7px;
  box-shadow:  0px 0px 12px #0000002E;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: absolute;
  z-index: 10;
  padding: 15px;
  
  a, a:hover {
    text-decoration: none; 
  }
`;

export const ModalItem = styled.button`
  font: ${theme.fonts.primary.body};
  color: ${theme.colors.mainBlack};
  background-color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border: 0px;
  padding-left: 10px;
  width: 100%;
  outline: none;

  p {
    width: 80%;
  }
`;
