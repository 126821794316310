/* eslint-disable react/require-default-props */
import { useState, useEffect } from 'react';

import {
  CardTitle, SubtitleContainer, Subtitle, Countdown, ContentContainer,
} from './style';

import GeneralCard from '../GeneralCard';

import { countDeadline } from '../../utils/dateFunctions';

export interface CountdownProps {
  date: string;
  width?: string;
  height?: string;
}

const CountdownTimer: React.ElementType = ({
  date,
  width,
  height,
}: CountdownProps) => {
  const [time, setTime] = useState<any>();

  const deadlineFunction = (deadline: string) => {
    const today = new Date();
    const campaignDeadline = new Date(deadline);
    if ((today.toISOString().slice(0, 4) === campaignDeadline.toISOString().slice(0, 4)
      && today.toISOString().slice(5, 7) === campaignDeadline.toISOString().slice(5, 7)
      && today.toISOString().slice(8, 10) === campaignDeadline.toISOString().slice(8, 10))
      || today < campaignDeadline) {
      const days = (countDeadline(deadline));
      const hours = (24 - today.getHours() - 1);
      const minutes = (60 - today.getMinutes());
      setTime(
        {
          days,
          hours,
          minutes,
        },
      );
      tick();
    } else {
      setTime(
        {
          days: 0,
          hours: 0,
          minutes: 0,
        },
      );
    }
  };

  const tick = () => {
    if (time) {
      if (time.hours === 0 && time.minutes === 0) {
        setTime({ days: time.days - 1, hours: 23, minutes: 59 });
      } else if (time.minutes === 0) {
        setTime({ days: time.days, hours: time.hours - 1, minutes: 59 });
      } else {
        setTime({ days: time.days, hours: time.hours, minutes: time.minutes - 1 });
      }
    }
  };

  useEffect(() => {
    setInterval(() => {
      deadlineFunction(date);
    }, 1000);
  }, []);

  return (
    <GeneralCard width={width || '270px'} height={height || '138px'}>
      <ContentContainer>
        <CardTitle>Esta campanha encerrará em</CardTitle>
        {time?.days >= 100 ? (
          <Countdown>
            {`0${time?.days}`.slice(1)}
            :
            {`0${time?.hours}`.slice(-2)}
            :
            {`0${time?.minutes}`.slice(-2)}
          </Countdown>
        ) : (
          <Countdown>
            {`0${time?.days}`.slice(-2)}
            :
            {`0${time?.hours}`.slice(-2)}
            :
            {`0${time?.minutes}`.slice(-2)}
          </Countdown>
        )}
        <SubtitleContainer>
          <Subtitle>Dias</Subtitle>
          <Subtitle>Horas</Subtitle>
          <Subtitle>Minutos</Subtitle>
        </SubtitleContainer>
      </ContentContainer>
    </GeneralCard>
  );
};

export default CountdownTimer;
