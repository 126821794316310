import styled from 'styled-components';
import {
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';

export const PaperComponent = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 100%;
  .MuiToolbar-root {
    justify-content: space-between !important;
  }
  .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
    background-color: transparent;
  }
  .MuiTableCell-root {
    color: #636363;
  }
`;

export const CellComponent = styled(TableCell)` 
   display: flex;
   justify-content:space-between;
`;

export const HeadComponent = styled(TableHead)`
  width: 100%;
  .MuiTableCell-head {
    color: #292929;
  }
`;

export const RowComponent = styled(TableRow)`
  width: 150%;
  .MuiTableCell-paddingCheckbox {
    /* width: 72px !important; */
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: #2962FF;
  }
  .MuiCheckbox-root {
    color: #6E6E6E;
  }
  .MuiTableCell-root {
    /* padding: -1px 36px 18px 10px; */
    text-align: left;
    width: 100%;
  }
`;

export const Pagination = styled(TablePagination)`
  /* align-self: right; */
  width: 100%;
`;

export const TableLink = styled.p`
  color: 'black';
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

export const UpRows = styled.div`
  
`;

export const ParticipantLink = styled.button`
  background-color: transparent;
  border: none;
  &:hover {
    cursor: pointer;
  }
`;
