/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/require-default-props */

import { StylesProvider } from '@material-ui/core/styles';
import { MaterialButton, SmallerText, ButtonText } from './style';

export interface ButtonProps {
  width?: string;
  height?: string;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  boxShadow?: string;
  // @ts-ignore
  iconLeft?: React.Element;
  // @ts-ignore
  iconRight?: React.Element;
  text: string;
  textColor?: string;
  fontSize?: string;
  hoverTextColor?: string;
  hoverBackground?: string;
  hoverBorder?: string;
  onClick?: () => void;
  isNewCampaignButton?: boolean;
  subText?: string;
  alinText?: string;
  onFocus?: () => void;
}

/* const Button: React.ElementType = ({
  width,
  height,
  backgroundColor,
  border,
  borderRadius,
  boxShadow,
  iconLeft,
  iconRight,
  text,
  textColor,
  fontSize,
  hoverBackground,
  hoverTextColor,
  hoverBorder,
  isNewCampaignButton,
  onClick,
  subText,
  alinText,
  onFocus,
}: ButtonProps) => (
  <StylesProvider injectFirst>
    <MaterialButton
      onFocus={onFocus}
      width={width}
      height={height}
      backgroundColor={backgroundColor}
      textColor={textColor}
      hoverTextColor={hoverTextColor}
      hoverBackground={hoverBackground}
      fontSize={fontSize}
      startIcon={iconLeft}
      endIcon={iconRight}
      border={border}
      borderRadius={borderRadius}
      boxShadow={boxShadow}
      onClick={onClick}
      hoverBorder={hoverBorder}
      isNewCampaignButton={isNewCampaignButton}
      subText={subText}
      alinText={alinText}
    >
      <ButtonText>
        {text}
        <SmallerText>{subText}</SmallerText>
      </ButtonText>
    </MaterialButton>
  </StylesProvider>

); */

function Button({
  width,
  height,
  backgroundColor,
  border,
  borderRadius,
  boxShadow,
  iconLeft,
  iconRight,
  text,
  textColor,
  fontSize,
  hoverBackground,
  hoverTextColor,
  hoverBorder,
  isNewCampaignButton,
  onClick,
  subText,
  alinText,
  onFocus,
}: ButtonProps): JSX.Element {
  return (
    <>
      <StylesProvider injectFirst>
        <MaterialButton
          onFocus={onFocus}
          width={width}
          height={height}
          backgroundColor={backgroundColor}
          textColor={textColor}
          hoverTextColor={hoverTextColor}
          hoverBackground={hoverBackground}
          fontSize={fontSize}
          startIcon={iconLeft}
          endIcon={iconRight}
          border={border}
          borderRadius={borderRadius}
          boxShadow={boxShadow}
          onClick={onClick || (() => null)}
          hoverBorder={hoverBorder}
          isNewCampaignButton={isNewCampaignButton}
          subText={subText}
          alinText={alinText}
        >
          <ButtonText>
            {text}
            <SmallerText>{subText}</SmallerText>
          </ButtonText>
        </MaterialButton>
      </StylesProvider>
    </>
  );
}

export default Button;
