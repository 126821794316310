import theme from '../../assets/styles/theme';
import {
  ProgressBarText, ProgressBarDiv, ProgressBarLine, Container,
} from './style';

export interface ProgressBarProps {
  presentValue:number;
  fullValue:number;
}

const getPercentage = (
  presentValue:number, fullValue:number,
): number => {
  if (presentValue >= fullValue) {
    return 100;
  }
  return Math.floor((100 * presentValue) / fullValue);
};

const ProgressBar: React.ElementType = ({
  presentValue,
  fullValue,
}: ProgressBarProps) => (
  <Container>

    <ProgressBarText>
      <b>
        {getPercentage(presentValue, fullValue)}
        % concluído
      </b>
      {' '}
      (
      {presentValue || 0}
      {' '}
      vendas)
    </ProgressBarText>
    <ProgressBarDiv>
      <ProgressBarLine
        percent={getPercentage(presentValue, fullValue)}
        strokeColor={theme.colors.darkViolet}
        strokeWidth={14}
        trailWidth={14}
        trailColor="#EBEBEB"
        showInfo={false}
      />
    </ProgressBarDiv>
  </Container>
);

export default ProgressBar;
