import { createContext } from 'react';

type CampaignData = {
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>
  image: File | string;
  setImage: React.Dispatch<React.SetStateAction<File | string>>
  imagePreview: string;
  setImagePreview: React.Dispatch<React.SetStateAction<string>>
  regulation: File | string;
  setRegulation: React.Dispatch<React.SetStateAction<File | string>>
  regulationPreview: string;
  setRegulationPreview: React.Dispatch<React.SetStateAction<string>>
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>
  initialDate: Date | null;
  setInitialDate: React.Dispatch<React.SetStateAction<Date | null>>
  expirationDate: Date | null;
  setExpirationDate: React.Dispatch<React.SetStateAction<Date | null>>
  teamModality: boolean;
  setTeamModality: React.Dispatch<React.SetStateAction<boolean>>
  teamNames: string[];
  setTeamNames: React.Dispatch<React.SetStateAction<string[]>>
  goals: ChosenGoal[],
  setGoals: React.Dispatch<React.SetStateAction<ChosenGoal[]>>,
}

export type Prize = {
  name?: string;
  setName?: React.Dispatch<React.SetStateAction<string>>
  percentageNeeded?: 20 | 50 | 100;
  setPercentageNeeded?: React.Dispatch<React.SetStateAction<20 | 50 | 100>>
  eachSalePrize?: number;
  setEachSalePrize?: React.Dispatch<React.SetStateAction<number>>
  price?: number;
  setPrice?: React.Dispatch<React.SetStateAction<number>>
  prizePhoto?: File | string;
  setPrizePhoto?: React.Dispatch<React.SetStateAction<File | string>>
}

type Goal = {
  productName: string;
  setProductName?: React.Dispatch<React.SetStateAction<string>>
  productDescription: string;
  setProductDescription?: React.Dispatch<React.SetStateAction<string>>
  unityPrice: number;
  setUnityPrice?: React.Dispatch<React.SetStateAction<number>>
  unityQuantity: number;
  setUnityQuantity?: React.Dispatch<React.SetStateAction<number>>
  productCode: string[];
  setProductCode: React.Dispatch<React.SetStateAction<string[]>>
  pointValue?: number;
  setPointValue?: React.Dispatch<React.SetStateAction<number>>
  productIdType: 'productCode';
  setProductIdType: React.Dispatch<React.SetStateAction< 'productCode'>>
  modalityChoice: 'prize' | 'bySale';
  setModalityChoice: React.Dispatch<React.SetStateAction<'prize' | 'bySale'>>
  prizes: Prize[]
  setPrizes: React.Dispatch<React.SetStateAction<Prize[]>>
}

export type ChosenGoal = {
  productName: string;
  productDescription: string;
  unityPrice: number;
  unityQuantity: number;
  productCode: string[];
  pointValue?: number;
  productIdType?: 'productCode';
  modalityChoice?: 'prize' | 'bySale';
  prizes?: Prize[]
  eachSalePrize?: number;
}

type CreateCampaignData = {
  campaign: CampaignData,
  goal: Goal,
  prize: Prize
}

export const campaignDataContext = createContext({} as CreateCampaignData);
