import React, { useState, useContext } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { AxiosError, AxiosResponse } from 'axios';
import { useAuth } from '../../../../hooks/Auth';

import useAxios from '../../../../utils/useAxios';

import {
  GeneralCard,
  Button,
  GoalsAndPrizes,
  ModalUi,
  Modal,
  Toast,
} from '../../../../components';
import { campaignDataContext, Prize, ChosenGoal } from '../../../../hooks/campaignContext';
import { Check, Calendar } from '../../../../assets/icons';
import theme from '../../../../assets/styles/theme';
import {
  StepContainer,
  CardTitle,
  InfoContainer,
  SectionTitle,
  SectionContainer,
  UploadDiv,
  CampaignInfo,
  DatePickerDiv,
  IndividualCampaignCode,
  TeamCampaignCode,
  LoadingContainer,
  RedirectText,
  LoadingModal,
  PictureIcon,
} from './style';

const Finalize: React.ElementType = () => {
  const { campaign } = useContext(campaignDataContext);
  const { user } = useAuth();
  const [launchCampaign, setLaunchCampaign] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [axiosPost] = useAxios('post');
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorToast, setErrorToast] = useState<boolean>(false);

  // eslint-disable-next-line consistent-return
  const uploadFiles = async () => {
    const {
      image, setImage, regulation, setRegulation,
    } = campaign;

    const files = { image: '', regulation: '' };

    if (image !== '' && image != null) {
      const data = new FormData();
      data.append('file', image);

      await axiosPost({
        url: 'campaign/file/upload',
        body: data,
        success: async (res: AxiosResponse) => {
          setImage(res.data.data.url);
          files.image = res.data.data.url;
          setErrorToast(false);
          setErrorMessage(null);
        },
        error: (error: AxiosError) => {
          setRedirect(false);
          setErrorMessage(error.response?.data?.message);
          setErrorToast(true);
        },
      });
    }

    if (regulation !== '' && regulation != null) {
      const data = new FormData();
      data.append('file', regulation);

      await axiosPost({
        url: 'campaign/file/upload',
        body: data,
        success: async (res: AxiosResponse) => {
          setRegulation(res.data.data.url);
          files.regulation = res.data.data.url;
          setErrorToast(false);
          setErrorMessage(null);
        },
        error: (error: AxiosError) => {
          setRedirect(false);
          setErrorMessage(error.response?.data?.message);
          setErrorToast(true);
        },
      });
    }

    await createCampaign(files.image, files.regulation);
  };

  const createCampaign = async (imageUrl: string, regulationUrl: string) => {
    const {
      title, description, expirationDate, initialDate, teamModality,
    } = campaign;

    const createdCampaign = {
      title,
      description: description || null,
      expirationDate,
      initialDate,
      teamModality,
      image: imageUrl || null,
      regulation: regulationUrl || null,
    };

    const filteredCampaign = Object.fromEntries(Object.entries(createdCampaign).filter(
      ([key, value]) => value !== null,
    ));

    const response = await axiosPost({
      url: `campaign/${user.manager}`,
      body: filteredCampaign,
      success: async (res: AxiosResponse) => {
        await createGoals(campaign.goals, res.data.data.code);
        if (teamModality) { await createTeams(campaign.teamNames, res.data.data.code); }
        setErrorToast(false);
        setErrorMessage(null);
        window.onbeforeunload = () => null;
        window.location.href = '../minhas-campanhas';
      },
      error: (error: AxiosError) => {
        setRedirect(false);
        setErrorMessage(error?.response?.data?.message);
        setErrorToast(true);
        setLaunchCampaign(false);
      },
    });

    return response;
  };

  const createGoals = async (goals: ChosenGoal[], campaignCode: string) => {
    const goalsArray = goals.map(async (goal) => {
      const createdGoal = {
        productName: goal.productName,
        productDescription: goal.productDescription || null,
        unityPrice: goal.unityPrice,
        unityQuantity: goal.unityQuantity,
        pointValue: goal.pointValue || null,
        productCode: goal.productCode.slice(1),
        eachSalePrize: goal.eachSalePrize || null,
      };
      const filteredGoal = Object.fromEntries(Object.entries(createdGoal).filter(
        ([key, value]) => value !== null,
      ));
      const response = await axiosPost({
        url: `goal/${campaignCode}`,
        body: filteredGoal,
        success: async (res: AxiosResponse) => {
          if (goal.prizes && !goal.eachSalePrize) {
            await createPrizes(goal.prizes, res.data.data.id);
          }
          setErrorToast(false);
          setErrorMessage(null);
        },
        error: (error: AxiosError) => {
          setErrorMessage(error?.response?.data?.message);
          setErrorToast(true);
        },
      });
      return response;
    });

    await Promise.all(goalsArray);
  };

  const uploadPrizePhoto = async (prize: Prize) => {
    const finalPrize = {
      name: prize.name,
      percentageNeeded: prize.percentageNeeded,
      price: prize.price,
      photo: 'still_needs_to_be_implemented.png',
    };
    if (prize.prizePhoto && prize.prizePhoto !== '') {
      const data = new FormData();
      data.append('file', prize.prizePhoto);
      await axiosPost({
        url: 'campaign/file/upload',
        body: data,
        success: async (res: AxiosResponse) => {
          setErrorToast(false);
          setErrorMessage(null);
          finalPrize.photo = res.data.data.url;
        },
        error: (error: AxiosError) => {
          setRedirect(false);
          setErrorMessage(error.response?.data?.message);
          setErrorToast(true);
        },
      });
    }

    return finalPrize;
  };

  const createPrizes = async (prizes: Prize[], goalId: string) => {
    const prizesArray = prizes.map(async (prize) => {
      const createdPrize = await uploadPrizePhoto(prize);
      await axiosPost({
        url: `prize/${goalId}`,
        body: createdPrize,
        success: () => {
          setErrorToast(false);
          setErrorMessage(null);
        },
        error: (error: AxiosError) => {
          setRedirect(false);
          setErrorMessage(error.response?.data?.message);
          setErrorToast(true);
        },
      });
    });
    await Promise.all(prizesArray);
  };

  const createTeams = async (teamNames: string[], campaignCode: string) => {
    teamNames.map(async (name) => {
      const createdTeam = {
        name,
      };

      const response = await axiosPost({
        url: `team/campaign/${campaignCode}`,
        body: createdTeam,
        success: () => {
          setErrorToast(false);
          setErrorMessage(null);
        },
        error: (error: AxiosError) => {
          setErrorMessage(error?.response?.data?.message);
          setErrorToast(true);
        },
      });
      return response;
    });
  };

  const ErrorToast = () => (
    <Toast
      type="error"
      message={errorMessage}
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  return (
    <>
      {ErrorToast()}
      <StepContainer>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ maxWidth: '1225px', width: '70%', marginBottom: '20px' }}>
            <CardTitle>Informações básicas da campanha</CardTitle>
            <GeneralCard height="400px" padding="10px">

              <InfoContainer>
                <SectionContainer width="33%" height="75%" justify="space-between">
                  <SectionTitle primaryFont>Foto da campanha</SectionTitle>
                  <UploadDiv imageUrl={campaign.imagePreview}>
                    <PictureIcon imageUrl={campaign.imagePreview} />
                  </UploadDiv>
                </SectionContainer>

                <SectionContainer height="100%" width="50%" justify="space-between">

                  <div>
                    <SectionTitle primaryFont={false}>
                      Título da campanha
                    </SectionTitle>
                    <CampaignInfo
                      primaryFont
                      style={{
                        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
                      }}
                    >
                      {campaign.title}

                    </CampaignInfo>
                  </div>
                  <div>
                    <SectionTitle primaryFont={false}>
                      Descrição da campanha
                    </SectionTitle>
                    <CampaignInfo
                      primaryFont={false}
                      style={{
                        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
                      }}
                    >
                      {campaign.description}
                    </CampaignInfo>
                  </div>
                  <div>
                    <SectionTitle primaryFont={false}>
                      Tempo da campanha
                    </SectionTitle>
                    <DatePickerDiv>
                      <p>{campaign.initialDate?.toLocaleDateString('pt-BR')}</p>
                      <p>-</p>
                      <p>{campaign.expirationDate?.toLocaleDateString('pt-BR')}</p>
                      <Calendar style={{ marginLeft: '15px' }} />
                    </DatePickerDiv>
                  </div>
                  <div>
                    <SectionTitle primaryFont>
                      Regulamento da campanha
                    </SectionTitle>
                    <SectionTitle
                      primaryFont={false}
                      style={{
                        textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',
                      }}
                    >
                      Arquivo atual:
                      {' '}
                      {campaign.regulationPreview}
                    </SectionTitle>
                  </div>

                </SectionContainer>

              </InfoContainer>
            </GeneralCard>

          </div>

          <Button
            text="LANÇAR CAMPANHA"
            backgroundColor={theme.colors.lighterViolet}
            hoverBackground={theme.colors.darkViolet}
            hoverTextColor={theme.colors.bodyWhite}
            borderRadius={theme.buttons.borderRadius.medium}
            width={theme.buttons.width.large}
            height={theme.buttons.height.medium}
            textColor={theme.colors.bodyWhite}
            iconRight={<Check />}
            onClick={() => setLaunchCampaign(true)}
          />
        </div>

        {!campaign.teamModality && (
          <>
            <CardTitle style={{ padding: '25px 0' }}>
              Código de acesso para participantes individuais da campanha
            </CardTitle>
            <IndividualCampaignCode>
              <CampaignInfo primaryFont={false}>
                Você poderá visualizar o código da campanha após finalizar a sua criação
              </CampaignInfo>
            </IndividualCampaignCode>
          </>
        )}

        {campaign.teamModality && (
          <>
            <CardTitle style={{ padding: '25px 0' }}>
              Código de acesso para times da campanha
            </CardTitle>
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {campaign.teamNames.map((name) => (
                <TeamCampaignCode>
                  <CampaignInfo primaryFont style={{ fontWeight: 'bold' }}>{name}</CampaignInfo>
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <SectionTitle primaryFont={false}>
                      Você poderá visualizar o código desse time após finalizar
                      a criação da campanha
                    </SectionTitle>
                  </div>
                </TeamCampaignCode>
              ))}
            </div>
          </>
        )}

        <CardTitle style={{ padding: '25px 0' }}>Premiação cadastradas na campanha</CardTitle>
        <GoalsAndPrizes
          goals={campaign.goals}
        />

        <ModalUi
          open={launchCampaign}
          onExited={() => setLaunchCampaign(false)}
          title="Confirmar lançamento"
          description={`Você está prestes a encerrar a criação da "${campaign.title}".
          Tem certeza que deseja continuar e lançar a campanha?`}
          width="579px"
          height="220px"
          cancelButton
          gapButtons="10px"
        >
          <Button
            text="Lançar campanha"
            backgroundColor={theme.colors.lighterViolet}
            width={theme.buttons.width.large}
            height={theme.buttons.height.large}
            textColor={theme.colors.mainWhite}
            fontSize={theme.fonts.primary.h2}
            hoverBackground={theme.colors.darkViolet}
            onClick={async () => {
              setRedirect(true);
              await uploadFiles();
            }}
          />
        </ModalUi>
      </StepContainer>

      <Modal
        showModal={redirect}
        setShowModal={setRedirect}
      >
        <LoadingContainer>
          <GeneralCard style={{ borderRadius: '33px' }} height="250px" width="350px">
            <LoadingModal>
              <CampaignInfo primaryFont style={{ fontWeight: 'bold' }}>Aguarde</CampaignInfo>
              <CircularProgress color="inherit" />
              <RedirectText>Sua campanha está sendo criada...</RedirectText>
            </LoadingModal>
          </GeneralCard>
        </LoadingContainer>
      </Modal>
    </>

  );
};
export default Finalize;
