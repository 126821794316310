import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from './assets/styles/global';
import Routes from './routes';
import { AuthProvider } from './hooks/Auth';

const App = (): React.ReactElement => (
  <BrowserRouter>
    <AuthProvider>
      <Routes />
    </AuthProvider>
    <GlobalStyles />
  </BrowserRouter>
);

export default App;
