import { Box } from '@mui/system';
import ModalUnstyled from '@mui/core/ModalUnstyled';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';

type ModalProps ={
  position?:string;
}

export const StyledModal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: ${(props: ModalProps) => props.position || '70%'};
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export const Body = styled(Box)`
  width: 520px;
  height: 175px;
  background-color: ${theme.colors.bodyWhite};
  border-radius: 6px;
  padding: 36px 58px 17px 58px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;

  @media(min-width: 1400px) {
    width: 600px;
  };
`;

export const TopBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
`;

export const SVGContainer = styled.div`
  svg{
    width: 70px;
    height: 70px;
  }
`;

export const BottomBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap:50px;
`;

export const Text = styled.h3`
  width: 100%;
  font: ${theme.fonts.primary.h3};
  color: ${theme.colors.mainBlack};
  font-weight: bold;
  margin-left: 30px;
  margin-top: 10px;
`;
