import styled from 'styled-components';
import {
  Paper,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import theme from '../../assets/styles/theme';

export const PaperComponent = styled(Paper)`
  width: 100%;
  background-color: red;

  .MuiToolbar-root {
    justify-content: space-between !important;
  }
`;

export const CellComponent = styled(TableCell)`
`;

export const HeadComponent = styled(TableHead)`
  width: 100%;
  
  .MuiTableCell-head {
    font: ${theme.fonts.primary.h3}
  }
`;

export const RowComponent = styled(TableRow)`
  width: 100%;
  font: ${theme.fonts.primary.body};

  .MuiTableCell-paddingCheckbox {
    width: 40px !important;
  }
`;

export const Pagination = styled(TablePagination)``;

export const TableLink = styled.p`
  font: ${theme.fonts.secondary.body};
  color: ${theme.colors.mainBlack};
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
`;
