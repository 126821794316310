import styled from 'styled-components';
import { Progress } from 'rsuite';
import theme from '../../assets/styles/theme';

const { Line } = Progress;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProgressBarText = styled.p`
  font: ${theme.fonts.secondary.small};
  color: ${theme.colors.mainBlack};
  margin-bottom: 10px;
`;

export const ProgressBarDiv = styled.div`
  width: 100%;
`;

export const ProgressBarLine = styled(Line)`
  padding: 8px 0px;
  .rs-progress-line-inner {
    border-radius:12px;
  }

  .rs-progress-line-bg{
    border-radius:12px;
  }
`;
