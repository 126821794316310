import Alert from '@mui/material/Alert';
import styled from 'styled-components';
import Stack from '@mui/material/Stack';
import theme from '../../../assets/styles/theme';

type AlertProps = {
  width?:string;
  height?:string;
  left?:string;
  top?:string;
  color?:string;
}

export const AlertBody = styled(Alert)<AlertProps>`
  position: absolute;
  left: ${(props: AlertProps) => props.left || '35%'};
  top: ${(props: AlertProps) => props.top || '5%'};
  align-items: center;
  width: ${(props: AlertProps) => props.width || '550px'};
  height: ${(props: AlertProps) => props.height || '90px'};
  svg{
    width: 40px;
    height: 40px;
    margin-right: 40px;
  }
`;

export const StackI = styled(Stack)<AlertProps>`
  transition: 0.3s ease-in-out;
  .css-1brxvf4-MuiPaper-root-MuiAlert-root{
    background-color: ${theme.colors.correctGreen};
  }
`;

export const AlertText = styled.div`
  display: flex;
  flex-direction: column;
  gap:5px;
  font: ${theme.fonts.primary.h3};
`;
