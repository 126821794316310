/* eslint-disable react/require-default-props */
import { Container } from './style';

// type WithChildren<T = unknown> = T & {
//   children?: React.ReactNode,
// };

export interface CardProps {
  width?: string;
  height?: string;
  padding?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
}

function GeneralCard({
  width,
  height,
  padding,
  children,
  style,
}: CardProps) : JSX.Element {
  return (
    <Container width={width} height={height} padding={padding} style={style}>
      {children}
    </Container>
  );
}

export default GeneralCard;
