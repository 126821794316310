import styled from 'styled-components';
import { Modal } from 'rsuite';

export const StyledModal = styled(Modal)`
    .rs-modal-header-close{
        display: none;
    } 

    .rs-modal-content{
        border-radius: 6px;
        border: "2px solid #D4D4D4";
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 175px;
        background-color: #FBFBFB;
    }

    .rs-modal-body{
        margin: 0;
        padding: 0;
    }
    
    width: 33%;
    max-width: 600px;
    min-width: 290px;
    margin-top: 0;

`;
