import styled from 'styled-components';
import { Progress } from 'rsuite';
import theme from '../../assets/styles/theme';

type ChartProps ={
  width?:string;
  height?:string;
  colorBol?:string;
}

export const ChartBody = styled.div<ChartProps>`
  width: ${(props: ChartProps) => (props.width || '100%')};
  height: ${(props: ChartProps) => (props.height || '418px')};
  background: ${theme.colors.bodyWhite};
  box-shadow: 0px 3px 6px #0000001A;
  border: 1px solid #E1E1E1;
  border-radius: ${theme.card.borderRadius.large};
  padding:30px 5%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ChartTitle = styled.h1`
  font: ${theme.fonts.primary.h1};
  color: ${theme.colors.darkerGray};
  
`;

export const ChartTitleContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 0;
  margin: 10px 0;
`;

export const ChartSales = styled.h2`
  font-weight: bold;
  font-size: 32px;
  color: ${theme.colors.darkerGray};
`;

export const ChartSelect = styled.div<ChartProps>`
  border: 1px solid #707070;
  border-radius: ${theme.buttons.borderRadius.large};
  width: ${theme.buttons.width.small};
  height: ${theme.buttons.height.small};
  display: flex;
  align-items: center;
  justify-content: center;
  font: ${theme.fonts.secondary.body};
  color: ${theme.colors.mainGray};
  svg{
    width: 15px;
    height: 8px;
  }
  
`;

export const ChartContent = styled.div`
  display: flex;
  justify-content: space-between ;
  margin-bottom: 20px ;
`;

export const ChartGraphicContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const { Line } = Progress;

export const ChartGraphic = styled(Line)`
  margin-bottom: 9px;
  transform: rotate(-180deg);
.rs-progress-line-inner {
  border-radius:12px;
  height: 180px;
}

.rs-progress-line-bg{
  border-radius:12px;
  height: 180px;
}
`;

export const ChartGraphicDate = styled.p`
  text-align: center;
  font: ${theme.fonts.secondary.h3};
  color: ${theme.colors.mainGray};
`;

export const ChartDescription = styled.div<ChartProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ChartDescriptionText = styled.p`
  font: ${theme.fonts.primary.body};
  color: ${theme.colors.mainGray};
  display: flex;
  flex-direction: row;
  margin-right: 36px;
  align-items: center;
`;

export const ChartDescriptionebol = styled.div`
  width: 34px;
  height: 17.5px;
  background: ${(props: ChartProps) => (props.colorBol || '#E1E1E1')};
  border-radius: ${theme.buttons.borderRadius.large};
  margin-right: 8px;
`;
