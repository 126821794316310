import styled from 'styled-components';
import { Modal } from 'rsuite';
import theme from '../../../assets/styles/theme';

type ModalProps = {
    rightCornerX?: boolean;
    width?: string;
    height?: string;
    maxWidth?: string;
}
export const StyledModal = styled(Modal) <ModalProps>`
    .rs-modal-header-close{
        display: none;
    } 

    .rs-modal-content{
        border-radius: 33px;
        border: 1px solid #D6D6D6;
        display: flex;
        flex-direction: column;
        height: ${(props: ModalProps) => props.height};
    }

    .rs-modal-title{
        color: ${theme.colors.mainBlack};
        font: ${theme.fonts.primary.h2};
        font-weight: bold;
        padding-top: 5px;
        width: 90%;
        margin: auto;
    }

    .rs-modal-dialog{
        width: 100%;
    }
    
    width: ${(props: ModalProps) => props.width};
    max-width: ${(props: ModalProps) => props.maxWidth};
    
    margin-top: 0;
    height: 100vh; 
    display: flex !important; 
    align-items: center;
    justify-content: center;
`;

export const CloseIcon = styled.div`
  stroke-width: 1.2px;
  outline: 0;
  position: absolute;
  right: 25px;
  top: 20px;
  font-size: 22px;
  color: #575757;
  width: 20px;
  padding: 0 4px;
  background: none;
  border-style: none;
  font-weight: bold;
  line-height: 1;
  cursor: pointer;
  
`;
