/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Toolbar,
  Checkbox,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';
import ParticipantsModal from '../ParticipantsModal';

import {
  CellComponent,
  HeadComponent,
  PaperComponent,
  RowComponent,
  Pagination,
  TableLink,
  UpRows,
  ParticipantLink,
} from './style';

import MockTableComponent from './MockTableComponent.json';

type HeadCell = {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
};

type Managers = {
  id: string;
  corporateName: string;
  representative: string;
  registrationDate: string;
  signature: string;
  payment: string;
  Id: string;
};

type Request = {
  id: string;
  corporateName: string;
  representative: string;
  registrationDate: string;
  signature: string;
  Id: string;
};

interface Goal {
  percentageSold: number;
}

interface Prize {
  name: string;
  price: number;
  prizeModality: string;
}

interface Participant {
  id: string;
  goalsPerfomance: Goal[];
  sold: number;
  prizes: Prize[];
}

type Participants = {
  id: string;
  participants: string;
  linkedStore: string;
  registrationDate: string;
  code: string;
};

export interface TableProps {
  columns: HeadCell[];
  total: number;
  // data?: Request[] | Managers[] | Participants[];
  requestData?: Participant[];
  managersData?: Managers[];
  participantsData?: Participants[];
  filterTable?: (query: string) => void;
  deleteRows?: (row: any[]) => void;
  onClickLink?: (arg: object | string) => void;
  title?: string;
  code: number;
}

function TableComponent({
  columns,
  total,
  requestData,
  managersData,
  participantsData,
  filterTable,
  deleteRows,
  onClickLink,
  title,
  code,
}: TableProps): JSX.Element {
  const [orderBy, setOrderBy] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [orderByQuery, setOrderByQuery] = useState<string>('order=');
  const [paginationQuery, setPaginationQuery] = useState<string>('page=0');
  const [rowsQuery, setRowsQuery] = useState<string>('rows=10');
  const [modal, setModal] = useState<boolean>(false);
  const [id, setId] = useState<string>();
  const [goalPerfomance, setGoalPerfomance] = useState<number>();
  const [prizes, setPrizes] = useState<Prize[]>();
  const [queryURL, setQueryURL] = useState<string>(
    'order=default&rows=10&page=0',
  );

  const handleCheckboxes = (row: any) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter((item) => item !== row));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleTableQuery = (
    order: string | null,
    page: number,
    rows: number,
  ): void => {
    const orderQuery = order === null ? 'order=' : `order=${order}`;
    const pageQuery = `page=${page}`;
    const rowQuery = `rows=${rows}`;

    setOrderBy(order === null ? '' : order);
    setOrderByQuery(orderQuery);

    setCurrentPage(page);
    setPaginationQuery(pageQuery);

    setRowsPerPage(rows);
    setRowsQuery(rowQuery);

    setQueryURL(`${orderQuery}&${rowQuery}&${pageQuery}`);
    if (filterTable) filterTable(`${orderQuery}&${rowQuery}&${pageQuery}`);
  };

  function TableColumns(): React.ReactElement {
    return (
      <HeadComponent>
        <RowComponent>
          {/* eslint-disable-next-line react/prop-types */}
          {columns.map((column: HeadCell) => {
            // eslint-disable-next-line react/prop-types
            const position = columns.indexOf(column);
            const getAlign = () => {
              if (column.numeric) return 'right';
              if (position === 0) return 'left';
              return 'left';
            };

            return (
              <CellComponent
                key={column.id}
                align={getAlign()}
                padding={position === 0 ? 'none' : 'default'}
              >
                {column.label}
              </CellComponent>
            );
          })}
        </RowComponent>
      </HeadComponent>
    );
  }

  function RequestRows(): React.ReactElement {
    return (
      <>
        {requestData?.map((row: any) => (
          <RowComponent
            hover
            role="list"
            tabIndex={-1}
            key={row.id}
            selected={selectedRows.includes(row)}
          >
            <CellComponent
              component="th"
              scope="row"
              padding="default"
              align="right"
            >
              <ParticipantLink onClick={() => [setModal(true), setId(row.id), setGoalPerfomance(row.goalsPerfomance[0].percentageSold), setPrizes(row.prizes)]} style={{ color: 'black' }}>
                {row.name}
              </ParticipantLink>

            </CellComponent>

            <CellComponent
              component="th"
              scope="row"
              padding="default"
              align="right"
            >
              {row.sold}
            </CellComponent>
          </RowComponent>
        ))}
      </>
    );
  }

  useEffect(() => {
    if (managersData) {
      setData(managersData);
    } else if (requestData) {
      setData(requestData);
    } else if (participantsData) {
      setData(participantsData);
    }
  }, [requestData, managersData, participantsData]);

  return (
    <>
      <PaperComponent>
        {TableColumns()}
        {/* {TableToolbar()} */}
        <TableContainer>
          <Table size="medium">
            <TableBody>
              {requestData && RequestRows()}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          count={total}
          page={currentPage}
          onRowsPerPageChange={(
            event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
          ) => {
            const page = 0;
            const rows = Number(event.target.value);
            setRowsPerPage(Number(event.target.value));
            setCurrentPage(0);
            handleTableQuery(orderBy, page, rows);
          }}
          onPageChange={(event: any, newPage: number) => {
            setCurrentPage(newPage);
            handleTableQuery(orderBy, newPage, rowsPerPage);
          }}
          labelDisplayedRows={
            ({ from, to, count }) => `${from}-${to} de ${count}`
          }
          labelRowsPerPage="Linhas por página: "
        />
      </PaperComponent>
      <ParticipantsModal
        modal={modal}
        id={id}
        onExited={() => setModal(false)}
        code={code}
        goalPerfomance={goalPerfomance}
        prizes={prizes}
      />
    </>
  );
}

export default TableComponent;
