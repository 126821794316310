import styled from 'styled-components';
import { Progress } from 'rsuite';
import theme from '../../assets/styles/theme';

type CardProps = {
  width?: string;
  height?: string;
  type?: string;
};

export const BigContainer = styled.div<CardProps>`
  width: ${(props) => (props.width ? props.width : '270px')};
  height: ${(props) => (props.height ? props.height : '272px')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 20px 20px 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const CardTitle = styled.p<CardProps>`
  text-align: left; 
  font: 550 Poppins, sans-serif;
  font-size: ${(props) => (props.type === 'small' ? '15px' : '18px')};
  letter-spacing: 0px;
  color: ${theme.colors.mainBlack};
  opacity: 1;
  max-width: 125px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

`;

export const CardSubtitle = styled.p<CardProps>`
  text-align: left;
  font: ${theme.fonts.primary.h3};
  font-size: ${(props) => (props.type === 'small' ? '12px' : '18px')};
  letter-spacing: 0px;
  color: ${theme.colors.mainGray};
  opacity: 1;
  margin-top: ${(props) => (props.type === 'small' && '-30px')};
`;

const { Circle } = Progress;

export const ProgressCircleLine = styled(Circle)`
  z-index: 190;

  .rs-progress {
    div {
      display: flex;
    justify-content: center;
    align-items: center;
    }
  }
  .rs-progress-line-inner {
    border-radius:12px;
  }

  .rs-progress-line-bg{
    border-radius:12px;
  }
`;
