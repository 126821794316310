/* eslint-disable react/require-default-props */
import { useState, useEffect } from 'react';
import { InputAdornment } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';

import {
  Input,
} from './style';

export interface StandardInputProps {
  type: string;
  title: string;
  placeholder?: string;
  value?: string;
  required: boolean;
  allowError?: boolean;
  errorMessage?: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}

const StandardInput: React.ElementType = ({
  type,
  title,
  placeholder,
  value,
  setValue,
  required,
  allowError,
  errorMessage,
}: StandardInputProps) => {
  const [check, setCheck] = useState<boolean | null>(null);
  const [error, setError] = useState<boolean>(!!errorMessage);
  const [errorText, setErrorText] = useState<string | null>(errorMessage || null);

  const regExpTypes = [
    { type: 'alpha', regExp: /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+/ },
    { type: 'numeric', regExp: /^[0-9]*$/ },
    { type: 'alphanumeric', regExp: /^[a-z A-Z à-ú À-Ú 0-9]*$/ },
    { type: 'password', regExp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/ },
    { type: 'telephone', regExp: /[1-9]{2}[0-9]{4,5}[0-9]{4}/ },
    { type: 'email', regExp: /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/ },
  ];

  const checkCharacters = (event: React.ChangeEvent<HTMLInputElement>): boolean => {
    const regExpType = regExpTypes.find((item) => item.type === type);
    const match = regExpType?.regExp.test(event.target.value);

    switch (type) {
    case 'alpha':
      setErrorText(!match ? 'Caracteres inválidos. Apenas letras são permitidas' : null);
      break;

    case 'numeric':
      setErrorText(!match ? 'Caracteres inválidos. Apenas números são permitidos' : null);
      break;

    case 'alphanumeric':
      setErrorText(!match ? 'Caracteres inválidos. Apenas letras e números são permitidos' : null);
      break;

    case 'password':
      setErrorText(!match ? 'Caracteres inválidos. Mínimo de 8 caracteres, sendo 1 maiúscula, 1 minúscula, 1 número e 1 caractere especial.' : null);
      break;

    case 'telephone':
      setErrorText(!match ? 'Caracteres inválidos. Apenas 10 ou 11 caracteres, sendo DDD + número de telefone.' : null);
      break;

    case 'email':
      setErrorText(!match ? 'Email inválido' : null);
      break;

    default:
      break;
    }
    setError(!match);

    return match || false;
  };

  useEffect(() => {
    if (errorMessage) {
      setError(true);
      setErrorText(errorMessage);
    }
  }, [errorMessage]);

  return (
    <>
      <Input
        type={type === 'password' ? type : 'text'}
        check={check}
        variant="standard"
        label={title}
        placeholder={placeholder}
        value={value}
        required={required}
        error={error}
        helperText={errorText}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.value.length === 0) {
            setError(false);
            setErrorText(null);
            setCheck(null);
            setValue('');
            return;
          }

          if (!allowError) {
            setErrorText(null);
            setCheck(null);
            setValue(event.target.value);
            return;
          }

          const checkResult = checkCharacters(event);
          setValue(event.target.value);
          setCheck(!checkResult ? null : true);
        }}
        InputProps={{
          endAdornment: value && (
            <InputAdornment position="end">
              {error ? <Close color="error" fontSize="small" /> : <Check htmlColor="#06A151" fontSize="small" />}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default StandardInput;
