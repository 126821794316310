/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { useCallback, useEffect, useRef } from 'react';
import { ModalBody, ModalContainer } from './style';

type WithChildren<T = unknown> = T & {
  children?: React.ReactNode,
};

export interface ModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  otherPage?: boolean;
  children?: WithChildren;
}

const Modal: React.ElementType = ({
  showModal,
  setShowModal,
  children,
}: ModalProps) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLDivElement>;

  const keyPress = useCallback(
    (e: any) => {
      if (e.key === 'Escape' && showModal) {
        setShowModal(false);
      }
    },
    [setShowModal, showModal],
  );

  useEffect(() => {
    document.addEventListener('keydown', keyPress);
    return () => document.removeEventListener('keydown', keyPress);
  }, [keyPress]);

  return (
    showModal ? (
      <ModalBody modalActive={showModal} ref={modalRef}>
        <ModalContainer>{children}</ModalContainer>
      </ModalBody>
    ) : null
  );
};

export default Modal;
