/* eslint-disable react/require-default-props */
import { AxiosResponse } from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import {
  ChevronDown, Settings, Speech, Exit,
} from '../../assets/icons';
import { useAuth } from '../../hooks/Auth';
import useAxios from '../../utils/useAxios';

import {
  Arrow,
  Body,
  Icon,
  ModalCard,
  ModalItem,
  Content,
  Description,
  Information,
  User,
  TopInformation,
} from './style';

export interface NavbarProps {
  color?: string;
  backgroundColor?: string;
}

const Navbar: React.ElementType = ({
  color,
  backgroundColor,
}: NavbarProps) => {
  const { user, signOut } = useAuth();
  const [axiosGet] = useAxios('get');
  const [name, setName] = useState<string | null>(null);
  const [role, setRole] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    (async () => {
      await axiosGet({
        url: `/manager/${user.manager}?get=true`,
        success: (res: AxiosResponse) => {
          const { manager } = res.data.data;
          setName(manager.name);
          setRole(manager.role);
        },
      });
    })();
  }, [axiosGet, user.manager]);

  return (
    <Content>
      <Body>
        <Icon backgroundColor={backgroundColor} />
        <Information>
          <TopInformation>
            <User>{name}</User>
            <Arrow onClick={() => setShowModal(!showModal)} transform={showModal}>
              <ChevronDown />
            </Arrow>
          </TopInformation>
          <Description color={color}>{role}</Description>
        </Information>
      </Body>
      {showModal ? (
        <ModalCard>
          <Link style={{ textDecoration: 'none' }} to="/configuracoes">
            <ModalItem>
              <Settings style={{ width: 27, height: 27 }} />
              <p>Configurações</p>
            </ModalItem>
          </Link>
          <Link style={{ textDecoration: 'none' }} to="suporte">
            <ModalItem>
              <Speech style={{ width: 25, height: 25 }} />
              <p>Suporte NovoCred</p>
            </ModalItem>
          </Link>
          <ModalItem onClick={() => {
            signOut();
            window.location.href = '/login';
          }}
          >
            <Exit style={{ width: 25, height: 25 }} />
            <p>Sair</p>
          </ModalItem>
        </ModalCard>
      ) : null}
    </Content>
  );
};

export default Navbar;
