/* eslint-disable react/require-default-props */
import {
  CardTitle,
  CardSubtitle,
  BigContainer,
  ContentContainer,
} from './style';

import GeneralCard from '../GeneralCard';

import theme from '../../assets/styles/theme';

export interface ProgressCircleProps {
  presentValue: number;
  fullValue: number;
  height?: string;
  width?: string;
  radius?: number;
  prize?: string;
  type?: string;
}

type PercentageType = {
  percentage: number;
};

const getPercentage = (
  presentValue: number,
  fullValue: number,
): number => {
  if (presentValue === 0 || fullValue === 0) {
    return 0;
  }
  if (presentValue >= fullValue) {
    return 100;
  }
  return Math.floor((100 * presentValue) / fullValue);
};

const ProgressCircle: React.ElementType = ({
  presentValue,
  fullValue,
  height,
  width,
  radius = 60,
  prize,
  type,
}: ProgressCircleProps) => {
  const calculatedPercentage = getPercentage(presentValue, fullValue);
  const progressColor = theme.colors.cherry;
  const strokeColor = theme.colors.lightGray;

  const Circle = (percentage: number, color?: string): React.ReactElement => {
    const circumference = 2 * Math.PI * radius;
    const strokePct = ((100 - percentage) * circumference) / 100;

    return (
      <circle
        r={radius}
        cx={100}
        cy={100}
        fill="transparent"
        stroke={color || ''}
        strokeWidth={type === 'small' ? '0.5rem' : '1rem'}
        strokeDasharray={circumference}
        strokeDashoffset={strokePct}
      />
    );
  };

  const Text = ({ percentage }: PercentageType): React.ReactElement => (
    <text
      x="50%"
      y="60%"
      dominantBaseline="central"
      textAnchor="middle"
      fontSize="1.1em"
    >
      {`${percentage.toFixed(0)}%`}
    </text>
  );

  const ProgressComponent = (percentage: number, color?: string) => (
    <svg width={200} height={170}>
      <g transform={`rotate(-90 ${'100 100'})`}>
        {Circle(100, strokeColor)}
        {Circle(percentage, color)}
      </g>
      {Text({ percentage })}
    </svg>
  );

  return (
    <BigContainer width={width} height={height}>
      <GeneralCard width={width || '270px'} height={height || '272px'} padding={type === 'small' ? '15px' : '30px'}>
        <ContentContainer>
          <CardTitle type={type}>{prize || 'Meta Geral'}</CardTitle>
          <div style={{ marginTop: -50 }}>
            {ProgressComponent(calculatedPercentage, progressColor)}
          </div>
          <CardSubtitle type={type}>% Alcançada*</CardSubtitle>
        </ContentContainer>
      </GeneralCard>
    </BigContainer>
  );
};

export default ProgressCircle;
