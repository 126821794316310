import React, { useEffect, useState } from 'react';

import { Steps } from 'antd';
import 'antd/lib/steps/style/index.css';

import {
  Navbar, Header,
} from '../../components';

import {
  BasicInfo, Goals, Participants, Finalize,
} from './Steps';

import {
  Container,
  CreateCampaignPage,
  StepperContainer,
} from './style';

import { campaignDataContext, Prize, ChosenGoal } from '../../hooks/campaignContext';

const { Step } = Steps;

const CreateCampaign: React.FC = () => {
  const [currentValue, SetCurrent] = useState(0);
  const steps = [
    <BasicInfo SetCurrent={SetCurrent} current={currentValue} />,
    <Goals SetCurrent={SetCurrent} current={currentValue} />,
    <Participants SetCurrent={SetCurrent} current={currentValue} />,
    <Finalize SetCurrent={SetCurrent} current={currentValue} />,
  ];

  const [title, setTitle] = useState('');
  const [image, setImage] = useState<any>('');
  const [imagePreview, setImagePreview] = useState('');
  const [regulation, setRegulation] = useState<any>('');
  const [regulationPreview, setRegulationPreview] = useState('');
  const [description, setDescription] = useState('');

  const nextWeek = new Date();
  nextWeek.setDate(nextWeek.getDate() + 20);
  const [initialDate, setInitialDate] = useState<Date | null>(new Date());
  const [expirationDate, setExpirationDate] = useState<Date | null>(nextWeek);
  const [teamModality, setTeamModality] = useState(false);
  const [goals, setGoals] = useState<ChosenGoal[]>([] as ChosenGoal[]);
  const [teamNames, setTeamNames] = useState<string[]>([] as string[]);

  const campaign = {
    title,
    setTitle,
    image,
    setImage,
    imagePreview,
    setImagePreview,
    regulation,
    setRegulation,
    regulationPreview,
    setRegulationPreview,
    description,
    setDescription,
    initialDate,
    setInitialDate,
    expirationDate,
    setExpirationDate,
    teamModality,
    setTeamModality,
    teamNames,
    setTeamNames,
    goals,
    setGoals,
  };

  const [prizeName, setPrizeName] = useState('');
  const [percentageNeeded, setPercentageNeeded] = useState<20 | 50 | 100>(20);
  const [eachSalePrize, setEachSalePrize] = useState(-1);
  const [prizePrice, setPrizePrice] = useState(-1);

  const prize = {
    name: prizeName,
    setName: setPrizeName,
    percentageNeeded,
    setPercentageNeeded,
    eachSalePrize,
    setEachSalePrize,
    price: prizePrice,
    setPrice: setPrizePrice,
  };
  const [prizes, setPrizes] = useState<Prize[]>([] as Prize[]);

  const [productName, setProductName] = useState('');
  const [productDescription, setProductDescription] = useState('');
  const [unityPrice, setUnityPrice] = useState(-1);
  const [unityQuantity, setUnityQuantity] = useState(-1);
  const [productCode, setProductCode] = useState(['']);
  const [pointValue, setPointValue] = useState(-1);
  const [productIdType, setProductIdType] = useState<'productCode'>('productCode');
  const [modalityChoice, setModalityChoice] = useState<'prize' | 'bySale'>('prize');

  const goal = {
    productName,
    setProductName,
    productDescription,
    setProductDescription,
    unityPrice,
    setUnityPrice,
    unityQuantity,
    setUnityQuantity,
    productCode,
    setProductCode,
    pointValue,
    setPointValue,
    productIdType,
    setProductIdType,
    modalityChoice,
    setModalityChoice,
    prizes,
    setPrizes,
  };

  const ContextData = {
    campaign, goal, prize,
  };

  useEffect(() => {
    window.onbeforeunload = () => 'Are you really want to perform the action?';
  }, []);

  return (
    <>
      <Container>
        <CreateCampaignPage>
          <Navbar />
          <Header
            title="Criação de campanhas"
            onClickReturn={() => {
              if (currentValue === 0) {
                window.location.href = '../minhas-campanhas';
              } else if (currentValue === 2 && campaign.teamModality) {
                campaign.setTeamModality(false);
              } else {
                SetCurrent(currentValue - 1);
              }
            }}
          />

          <campaignDataContext.Provider value={ContextData}>
            <StepperContainer>
              <Steps current={currentValue}>
                <Step title="Informações Básicas" />
                <Step title="Metas" />
                <Step title="Participantes" />
                <Step title="Finalização" />
              </Steps>
            </StepperContainer>

            {steps[currentValue]}
          </campaignDataContext.Provider>

        </CreateCampaignPage>
      </Container>
    </>

  );
};
export default CreateCampaign;
