/* eslint-disable no-underscore-dangle */
/* eslint-disable no-prototype-builtins */
import {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';
import Papa from 'papaparse';
import { useParams } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { AxiosError, AxiosResponse } from 'axios';
import Skeleton from '@mui/material/Skeleton';
import convert from 'xml-js';
import { relative } from 'path';
import useAxios from '../../utils/useAxios';
import theme from '../../assets/styles/theme';
import NameParticipants from '../Mocks/NameParticipants.json';
import {
  Header,
  Navbar,
  Button,
  CountdownTimer,
  ProgressCircle,
  SalesChart,
  TableComponent,
  ModalUi,
  GoalsAndPrizes,
  OutlinedInput,
  Toast,
  ReportTemplate,
} from '../../components';

import {
  AutoCompleteContainer,
  AutoCompleteItem,
  AutoCompleteItemButton,
  Container,
  Content,
  HeaderButtons,
  Section,
  SectionTitle,
  TopContent,
  ModalContent,
  DownloadIcon,
  UploadButton,
  Title,
  Goals,
  Prizes,
  ModalCard,
  ModalCardCategory,
  ModalCardPrize,
  Sales,
  CreatedTeam,
  TeamName,
  TeamCode,
  TeamCodeText,
  SelectedFileContainer,
  SelectedFileText,
} from './style';

import {
  Create,
  AddCircle,
  Document,
  Download,
} from '../../assets/icons';

type CampaignData = {
  campaign: Campaign,
  goals: Goal[],
  participants: Participant[],
  totalParticipants: number,
  totalSales: number,
  expectedTotalMoney: number,
};

type TeamData = {
  teams: Team[],
  totalTeams: number,
};

type ParticipantSalesData = {
  sales: Sale[],
  totalSales: number;
};

type Campaign = {
  id: string;
  code: number,
  title: string,
  description: string,
  expirationDate: string,
  initialDate: string,
  image: string,
  regulation: string,
  isActive: boolean,
  teamModality: boolean;
};

type Sale = {
  id?: string;
  name: string;
  cpf: string;
  productCode: string;
  companyCnpj: string;
  productAmount: number;
  receiptNumber: number;
  sellerName?: string;
};

type Goal = {
  productName: string,
  productDescription: string,
  unityQuantity: number,
  unityPrice: number,
  productCode: number [],
  eachSalePrize: number | null,
  pointValue: number,
  prizes: Prize[];
};

type Prize = {
  name: string,
  price: number,
  photo: string,
  goal: string,
  percentageNeeded: number,
  prizeModality: string,
};

type Participant = {
  id: string;
  name: string;
  sold: number;
  prizes: Prize[];
  goals: participantGoal[];
  goalsPerfomance: GoalsPerfomance[];
};

type GoalsPerfomance = {
  name: string,
  percentageSold: number;
};

type participantGoal = {
  name: string;
  presentValue: number;
  fullValue: number;
};

type Team = {
  name: string,
  accessCode: number,
};

interface IData {
  name: string;
  id?: string;
  code: string;
}

interface autoCompleteProps {
  NameParticipants: any[];
}

const campaignTeamColumns = [
  {
    disablePadding: false,
    id: 'name',
    label: 'Nome do time',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'accessCode',
    label: 'Código de acesso',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'team',
    label: 'Desempenho',
    numeric: false,
  },
];

const teamParticipantColumns = [
  {
    disablePadding: false,
    id: 'name',
    label: 'Nome do(a) participante',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'sales',
    label: 'Vendas(R$)',
    numeric: true,
  },
  {
    disablePadding: false,
    id: 'performance',
    label: 'Desempenho',
    numeric: false,
  },
];

const salesHistoryColumns = [
  {
    disablePadding: false,
    id: 'name',
    label: 'Nome da venda registrada',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'value',
    label: 'Valor(R$)',
    numeric: true,
  },
];

const Campaign: React.FC = () => {
  const [newTeamName, setNewTeamName] = useState<string>('Time ');
  const [newParticipantName, setNewParticipantName] = useState([] as any);
  const [suggestionList, setSuggestionList] = useState<IData[]>([]);
  const [isComponentVisible, setIsComponentVisible] = useState(false);
  const [closeCampaign, setCloseCampaign] = useState<boolean>(false);
  const [updateCampaign, setUpdateCampaign] = useState<boolean>(false);
  const [participantPerformance, setParticipantPerformance] = useState<boolean>(false);
  const [teamModal, setTeamModal] = useState<boolean>(false);
  const [participantModal, setParticipantModal] = useState<boolean>(false);
  const [createdTeam, setCreatedTeam] = useState<{ name: string, code: number; } | false>(false);
  const [campaignData, setCampaignData] = useState<CampaignData>();
  const [participantSalesData, setParticipantSalesData] = useState<ParticipantSalesData>();
  const [campaignTeamData, setCampaignTeamData] = useState<TeamData>();
  const [selectedParticipant, setSelectedParticipant] = useState<Participant>();
  const [percentages, setPercentages] = useState<number[]>(new Array(15).fill(0));
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [newSale, setNewSale] = useState<Sale[]>();
  const [selectedFile, setSelecteFile] = useState<any>();
  const [salesType, setSalesType] = useState<string>();
  const [axiosGet] = useAxios('get');
  const [axiosPut] = useAxios('put');
  const [axiosPost] = useAxios('post');
  const [axiosDelete] = useAxios('delete');
  const componentRefParticipant = useRef(null);
  const componentRefCampaign = useRef(null);
  const [selectedParticipantID, setSelectedParticipantID] = useState<string>();

  // console.log(campaignData?.participants);

  const [participantNameList, setParticipantNameList] = useState<typeof NameParticipants>([]);

  const [showDeletionModal, setShowDeletionModal] = useState<boolean>(false);
  const [deletionObject, setDeletionObject] = useState<{
    type: string,
    rows: any[],
  }>();

  const [loadingCampaign, setLoadingCampaign] = useState<boolean>(true);
  const [loadingTeam, setLoadingTeam] = useState<boolean>(true);

  const { code, teamCode } = useParams<{ code: string, teamCode: string; }>();

  const participantId = 'eb78b667-9fff-40ae-a210-cd291f498b2f';

  const handleFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      if (files[0].type === 'text/csv') {
        setSalesType('csv');
        setSelecteFile(files[0]);

        Papa.parse(files[0], {
          complete: (results: any) => {
            const sales: Sale[] = [];
            results.data.forEach((sale: any, index: number) => {
              if (index !== 0 && !Object.values(sale)
                .every((value) => String(value).length === 0)) {
                const auxSale: Sale = {
                  name: sale[0],
                  companyCnpj: sale[1],
                  productCode: sale[2],
                  productAmount: sale[3],
                  receiptNumber: sale[4],
                  sellerName: sale[5],
                  cpf: sale[6],
                };

                sales.push(auxSale);
              }
            });
            setNewSale(sales);
            console.log(sales);
          },
        });
      } else {
        setSalesType('xml');
        const target = await files[0].text();
        setSelecteFile(files[0]);
        const options = {
          ignoreComment: true,
          ignoreDoctype: true,
          ignoreInstruction: true,
          ignoreDeclaration: true,
          instructionHasAttributes: true,
          spaces: 2,
          compact: true,
        };
        const req: any = convert.xml2js(target, options);

        console.log(req.root);

        if (req.hasOwnProperty('nfeProc')) {
          const productArray = req.nfeProc?.NFe.infNFe.det instanceof Array
            ? req.nfeProc?.NFe.infNFe.det : [req.nfeProc?.NFe.infNFe.det];

          console.log('aaaa');
          const sales = productArray.map((product: any) => ({
            name: Object.values(product.prod.xProd)[0],
            companyCnpj: Object.values(req.nfeProc?.NFe.infNFe.emit.CNPJ)[0],
            productCode: Object.values(product.prod.cProd)[0],
            receiptNumber: String((
              Object.values(req.nfeProc?.NFe.infNFe._attributes)[0])).substr(3),
            productAmount: Number(Object.values(product.prod.qCom)[0]),
          }));

          setNewSale(sales);
        }

        if (!req.hasOwnProperty('nfeProc')) {
          const productArray = req.NFe.infNFe.det instanceof Array
            ? req.NFe.infNFe.det : [req.NFe.infNFe.det];

          console.log('alooooo');
          const sales = productArray.map((product: any) => ({
            name: Object.values(product.prod.xProd)[0],
            companyCnpj: Object.values(req.NFe.infNFe.emit.CNPJ)[0],
            productCode: Object.values(product.prod.cProd)[0],
            receiptNumber: String((Object.values(req.NFe.infNFe._attributes)[0])).substr(3),
            productAmount: Number(Object.values(product.prod.qCom)[0]),
          }));
          setNewSale(sales);
        }
      }
    }
  };

  const ErrorToast = () => (
    <Toast
      type="error"
      message={errorMessage || ''}
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  const handlePrintParticipant = useReactToPrint({
    content: () => componentRefParticipant.current,
  });

  const handlePrintCampaign = useReactToPrint({
    content: () => componentRefCampaign.current,
  });

  const closeCampaignModal = () => (
    <ModalUi
      open
      onExited={() => setCloseCampaign(false)}
      title="Confirmar encerramento"
      description={`Você está prestes a encerrar a ${campaignData?.campaign.title}. Tem certeza que deseja continuar?`}
      text="Esta ação é irreversível*"
      width="579px"
      height="250px"
      gapButtons="15px"
      cancelButton
    >
      <>
        <Button
          text="Encerrar Campanha"
          backgroundColor={theme.colors.errorRed}
          width={theme.buttons.width.large}
          height={theme.buttons.height.large}
          textColor={theme.colors.mainWhite}
          fontSize={theme.fonts.primary.h2}
          hoverBackground={theme.colors.darkCherry}
          onClick={async () => {
            await axiosPut({
              url: `/campaign/${code}`,
              body: { isActive: false, expirationDate: new Date() },
              success: (res: AxiosResponse) => {
                window.location.replace(`/campanha/${code}/finalizada`);
                setErrorMessage('');
                setErrorToast(false);
              },
              error: (error: any) => {
                const { data } = error.response;
                setErrorMessage(data.message);
                setErrorToast(true);
              },
            });
          }}
        />
      </>
    </ModalUi>
  );

  const updateCampaignModal = () => (
    <ModalUi
      open
      onExited={() => {
        setUpdateCampaign(false);
        setSelecteFile('');
      }}
      title="Atualize os dados da campanha"
      width="579px"
      height="auto"
      gapButtons="15px"
      cancelButton
      content={(
        <>
          {selectedFile ? (
            <SelectedFileContainer>
              <Title>Arquivo selecionado: </Title>
              <SelectedFileText>{selectedFile.name}</SelectedFileText>
            </SelectedFileContainer>
          ) : (
            <label htmlFor="xml_input">
              <UploadButton>
                <DownloadIcon />
                <h5 style={{ textAlign: 'center' }}>
                  Arraste os arquivos ou escolha do seu computador.

                </h5>
                <small style={{ textAlign: 'center' }}>Arquivos suportados: .xml e .csv</small>
                <input
                  id="xml_input"
                  type="file"
                  accept="text/xml, .csv"
                  onChange={handleFiles}
                // onClick={() => createSale}
                />
              </UploadButton>
            </label>
          )}
        </>
      )}
    >
      <Button
        text="Continuar"
        backgroundColor={theme.colors.lighterViolet}
        width={theme.buttons.width.medium}
        height={theme.buttons.height.medium}
        textColor={theme.colors.mainWhite}
        fontSize={theme.fonts.primary.h2}
        hoverBackground={theme.colors.darkViolet}
        onClick={() => {
          createSale();
          setSelecteFile('');
        }}
      />
    </ModalUi>
  );

  const addTeamModal = () => (
    <ModalUi
      open={teamModal}
      onExited={() => setTeamModal(false)}
      title="Adicionar novo time"
      description="Adicione time e os nomeie para que o sistema gere códigos de acesso para a sua campanha."
      width="579px"
      height="340px"
      gapButtons="15px"
      cancelButton
      content={(
        <div style={{
          width: '100%',
          marginTop: 10,
          marginBottom: 40,
        }}
        >
          <OutlinedInput
            type="alpha"
            title="Nome do time"
            value={newTeamName}
            setValue={setNewTeamName}
          />
        </div>
      )}
    >
      <Button
        text="Gerar código"
        backgroundColor={theme.colors.lighterViolet}
        width={theme.buttons.width.large}
        height={theme.buttons.height.large}
        textColor={theme.colors.mainWhite}
        fontSize={theme.fonts.primary.h2}
        hoverBackground={theme.colors.darkViolet}
        onClick={() => createTeam()}
      />
    </ModalUi>
  );

  const onTextChanged = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const suggestions = participantNameList
      .sort()
      .filter((v: IData) => String(v.name).toLowerCase().startsWith(value.toLowerCase()));
    if (suggestions.length > 0) {
      setIsComponentVisible(true);
    }

    setSuggestionList(suggestions);
    setNewParticipantName({ suggestions, text: value });
  };

  const suggestionSelected = (value: IData) => {
    setIsComponentVisible(false);

    setNewParticipantName({
      text: value.name,
      suggestions: [],
    });
  };

  const { suggestions } = newParticipantName;

  const deleteTeams = async (rows: any) => {
    try {
      const requests = rows.map((row: any) => {
        const { accessCode } = row;
        return axiosDelete({
          url: `/team/${accessCode}`,
          success: () => {
            setErrorMessage('');
            setErrorToast(false);
            window.location.reload();
          },
          error: (error: any) => {
            const { data } = error.response;
            setErrorMessage(data.message);
            setErrorToast(true);
          },
        });
      });
      await Promise.all(requests);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteSales = async (rows: any) => {
    try {
      const requests = rows.map((row: any) => {
        const { id } = row;
        return axiosDelete({
          url: `/sale/${id}`,
          success: () => {
            setErrorMessage('');
            setErrorToast(false);
            window.location.reload();
          },
          error: (error: any) => {
            const { data } = error.response;
            setErrorMessage(data.message);
            setErrorToast(true);
          },
        });
      });
      await Promise.all(requests);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteParticipants = async (rows: any) => {
    try {
      const requests = rows.map((row: any) => {
        const { id } = row;
        return axiosDelete({
          url: `/participantCampaign/campaign/${campaignData?.campaign.code}/participant/${id}`,
          success: () => {
            setErrorMessage('');
            setErrorToast(false);
            window.location.reload();
          },
          error: (error: any) => {
            const { data } = error.response;
            setErrorMessage(data.message);
            setErrorToast(true);
          },
        });
      });
      await Promise.allSettled(requests);
    } catch (e) { console.log(e); }
  };

  const deleteModal = () => {
    let functionHandler: any;
    if (deletionObject?.type === 'participant') {
      functionHandler = deleteParticipants;
    } else if (deletionObject?.type === 'sale') {
      functionHandler = deleteSales;
    } else {
      functionHandler = deleteTeams;
    }

    return (
      <ModalUi
        open
        onExited={() => setShowDeletionModal(false)}
        title="Confirmar exclusão"
        description={`Você está prestes a excluir ${deletionObject?.rows.reduce(
          (acc: string, row: any) => `${acc} ${row.name} da campanha, `,
          '',
        )} tem certeza que deseja prosseguir com a ação?`}
        // text="Esta ação é irreversível*"
        width="579px"
        height="250px"
        gapButtons="15px"
        cancelButton
      >
        <>
          <Button
            text="Excluir"
            backgroundColor={theme.colors.errorRed}
            width={theme.buttons.width.large}
            height={theme.buttons.height.large}
            textColor={theme.colors.mainWhite}
            fontSize={theme.fonts.primary.h2}
            hoverBackground={theme.colors.darkCherry}
            onClick={async () => {
              await functionHandler(deletionObject?.rows);
              setShowDeletionModal(false);
              setDeletionObject(undefined);
            }}
          />
        </>
      </ModalUi>
    );
  };

  const handleCreatePatientCampaignRelation = async (userID: string) => {
    await axiosPost({
      url: `/participantCampaign/campaign/${code}/participant/${userID}`,
      success: () => {
        setErrorMessage('');
        setErrorToast(false);
        window.location.reload();
      },
      error: (error: any) => {
        console.log(error);
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  };

  const addParticipantModal = () => (
    <ModalUi
      open={participantModal}
      onExited={() => setParticipantModal(false)}
      title="Gerencie participantes"
      description="Pesquise pelo nome e adicione mais participantes a essa campanha."
      width="579px"
      height="340px"
      gapButtons="15px"
      cancelButton
      content={(
        <div style={{
          width: '100%',
          marginTop: 10,
          marginBottom: 40,
        }}
        >

          <OutlinedInput
            type="alpha"
            autoComplete="off"
            title="Nome do Participante"
            value={newParticipantName.text}
            setValue={setNewParticipantName}
            suggestionList
            onChange={(e: ChangeEvent<HTMLInputElement>) => onTextChanged(e)}
          />
          {
            newParticipantName.text?.length > 0
            && suggestionList.length > 0 && isComponentVisible && (
              <div style={{
                position: 'relative',
              }}
              >
                <AutoCompleteContainer>
                  {suggestionList?.map((item: IData) => (
                    <AutoCompleteItem key={item.id}>
                      <AutoCompleteItemButton
                        key={item.id}
                        onClick={() => {
                          suggestionSelected(item);
                          setSelectedParticipantID(item.id);
                        }}
                      >
                        {item.name}
                      </AutoCompleteItemButton>
                    </AutoCompleteItem>
                  ))}
                </AutoCompleteContainer>
              </div>
            )
          }
        </div>
      )}
    >
      <Button
        text="Inserir na campanha"
        backgroundColor={theme.colors.lighterViolet}
        width={theme.buttons.width.large}
        height={theme.buttons.height.large}
        textColor={theme.colors.mainWhite}
        fontSize={theme.fonts.primary.h2}
        hoverBackground={theme.colors.darkViolet}
        onClick={() => {
          if (selectedParticipantID) {
            handleCreatePatientCampaignRelation(selectedParticipantID);
            setParticipantModal(false);
            setSelectedParticipantID(undefined);
          } else {
            setErrorMessage('Este participante não está cadastrado na campanha');
            setErrorToast(true);
          }
        }}
      />
    </ModalUi>
  );

  const createdTeamModal = () => (
    <ModalUi
      open={createdTeam}
      onExited={() => {
        setCreatedTeam(false);
        window.location.reload();
      }}
      title="Adicionar novo time"
      description="O código de acesso para os seguinte time foi gerado:"
      width="579px"
      height="340px"
      gapButtons="195px"
      content={(
        <CreatedTeam>
          <TeamName>{createdTeam && createdTeam.name}</TeamName>
          <TeamCodeText>
            Código de acesso:
            <TeamCode>{createdTeam && createdTeam.code}</TeamCode>
          </TeamCodeText>
        </CreatedTeam>
      )}
    >
      <Button
        text="Cadastrar mais times"
        backgroundColor={theme.colors.bodyWhite}
        width={theme.buttons.width.large}
        height={theme.buttons.height.large}
        textColor={theme.colors.lightViolet}
        fontSize={theme.fonts.primary.h2}
        border={`2px solid ${theme.colors.lightViolet}`}
        hoverBackground={theme.colors.bodyWhite}
        onClick={() => {
          setCreatedTeam(false);
          setNewTeamName('Time ');
          setTeamModal(true);
        }}
      />
      <Button
        text="Finalizar"
        backgroundColor={theme.colors.lighterViolet}
        width={theme.buttons.width.medium}
        height={theme.buttons.height.large}
        textColor={theme.colors.mainWhite}
        fontSize={theme.fonts.primary.h2}
        hoverBackground={theme.colors.darkViolet}
        onClick={() => {
          setCreatedTeam(false);
          window.location.reload();
        }}
      />
    </ModalUi>
  );

  const participantPerformanceModal = () => (
    <ModalUi
      open={participantPerformance}
      onExited={() => {
        setParticipantPerformance(false);
      }}
      title={selectedParticipant?.name}
      width="60%"
      height="650px"
      padding="30px 30px 30px 50px"
      content={(
        <ModalContent>
          <Goals>
            <Title>Desempenho das metas</Title>
            <div style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
            >
              {selectedParticipant?.goalsPerfomance?.map((goal) => (
                <ProgressCircle
                  type="small"
                  radius={30}
                  height="160px"
                  width="160px"
                  presentValue={goal.percentageSold}
                  fullValue={100}
                  prize={`Vender ${goal.name}`}
                />
              ))}
            </div>
          </Goals>
          <Prizes>
            <Title>Saldo de prêmios adquiridos</Title>
            <div style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
              marginTop: 10,
            }}
            >
              {selectedParticipant?.prizes.map((prize: Prize) => {
                if (prize.name && prize?.name.includes('PocketCard')) {
                  return (
                    <ModalCard>
                      <ModalCardCategory>{prize?.name}</ModalCardCategory>
                      <ModalCardPrize>
                        R$
                        {' '}
                        {prize?.price?.toLocaleString('pt-BR')}
                      </ModalCardPrize>
                    </ModalCard>
                  );
                }
                if (prize?.prizeModality === 'by each sale') {
                  return (
                    <ModalCard>
                      <ModalCardCategory>Total ganho pela venda de unidades</ModalCardCategory>
                      <ModalCardPrize>
                        R$
                        {' '}
                        {prize.price?.toLocaleString('pt-BR')}
                      </ModalCardPrize>
                    </ModalCard>
                  );
                }

                return (
                  <ModalCard>
                    <ModalCardCategory>Prêmio físico</ModalCardCategory>
                    <ModalCardPrize>{prize.name}</ModalCardPrize>
                  </ModalCard>
                );
              })}

              {selectedParticipant?.prizes.length === 0 && (
                <ModalCard style={{ textAlign: 'center' }}>
                  <ModalCardCategory>Este participante ainda não obteve prêmios</ModalCardCategory>
                </ModalCard>
              )}

            </div>
          </Prizes>
          <Sales>
            <div style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: 15,
            }}
            >
              <Title>Histórico de vendas</Title>
              <Button
                iconLeft={<Document />}
                iconRight={<Download />}
                width="280px"
                height={theme.buttons.height.large}
                onClick={handlePrintParticipant}
                // Trigger
                // Notification
                subText="Formatos disponíveis: .pdf e .xml"
                text="Fazer download do histórico"
                textColor={theme.colors.mainBlack}
                alinText="left"
              />
            </div>
            <TableComponent
              title="Histórico de vendas"
              saleNameData={participantSalesData?.sales}
              deleteRows={(value: any) => {
                setShowDeletionModal(true);
                setDeletionObject({
                  rows: value,
                  type: 'sale',
                });
              }}
              total={participantSalesData?.totalSales}
              columns={salesHistoryColumns}
              filterTable={async (query: string) => {
                console.log(`toaqui${query}`);
                const id = selectedParticipant?.id || '';
                await fetchParticipantSales(id, `&${query}&type=sales`);
              }}
            />
          </Sales>
          <div style={{ zIndex: -10, display: 'none' }}>
            {campaignData?.campaign.code && selectedParticipant?.id && (
              <ReportTemplate
                campaignCode={campaignData?.campaign.code}
                reportType="specific"
                componentRef={componentRefParticipant}
                participantId={selectedParticipant.id}
              />
            )}
          </div>
        </ModalContent>
      )}
    />
  );

  const fetchCampaignData = useCallback(async (query = '&order=default&rows=10&page=0') => {
    await axiosGet({
      url: `/campaign/${code}?get=full${query || ''}`,
      success: (res: AxiosResponse) => {
        console.log(`aaaaaaaaaaaa${query}`);
        setCampaignData(res.data.data);
        setErrorMessage('');
        setErrorToast(false);
        setTimeout(() => setLoadingCampaign(false), 500);
      },
      error: (error: any) => {
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  }, [axiosGet, code]);

  const fetchPercentages = useCallback(async () => {
    await axiosGet({
      url: `/sale/chart/${code}`,
      success: (res: AxiosResponse) => {
        setPercentages(res.data.data.percentages);
        setErrorMessage('');
        setErrorToast(false);
      },
      error: (error: any) => {
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  }, [axiosGet, code]);

  const fetchTeamData = useCallback(async (query = 'order=default&rows=10&page=0') => {
    await axiosGet({
      url: `/team/campaign/${code}?${query || ''}`,
      success: (res: AxiosResponse) => {
        setCampaignTeamData(res.data.data);
        setErrorMessage('');
        setErrorToast(false);
        setTimeout(() => setLoadingTeam(false), 500);
      },
      error: (error: any) => {
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  }, [axiosGet, code]);

  const fetchParticipantSales = async (id: string, query = 'order=default&rows=10&page=0') => {
    await axiosGet({
      url: `/sale/participant/${id}/campaign/${code}?${query || ''}`,
      success: (res: AxiosResponse) => {
        console.log(`aquiiii${query}`);
        console.log(res.data.data);
        setParticipantSalesData(res.data.data);
        setErrorMessage('');
        setErrorToast(false);
      },
      error: (error: AxiosError) => {
        setErrorMessage(error?.response?.data.data.message);
        setErrorToast(true);
      },
    });
  };

  const createTeam = async () => {
    if (newTeamName.length !== 0) {
      let newTeam = newTeamName.charAt(5).toUpperCase() + newTeamName.slice(6);
      if (newTeam.split(' ')[0] !== 'Time') {
        newTeam = `Time ${newTeam}`;
      }
      await axiosPost({
        url: `team/campaign/${code}`,
        body: { name: newTeam },
        success: (res: AxiosResponse) => {
          setTeamModal(false);
          setCreatedTeam({ name: newTeamName, code: res.data.data.accessCode });
          setErrorMessage('');
          setErrorToast(false);
        },
        error: (error: any) => {
          const { data } = error.response;
          setErrorMessage(data.message);
          setErrorToast(true);
        },
      });
    }
  };

  const createSale = async () => {
    console.log(newSale);
    newSale?.forEach(async (sale) => {
      if (salesType === 'csv') {
        console.log(code);
        console.log(sale);
        if (Object.keys(sale).length > 0) {
          await axiosPost({
            url: `/sale/campaign/${code}/participant/${sale.cpf}?type=cpf`,
            body: sale,
            success: () => {
              setUpdateCampaign(false);
              setErrorMessage('');
              setErrorToast(false);
              window.location.reload();
            },
            error: (error: any) => {
              console.log(error);
              const { data } = error.response;
              setErrorMessage(data.message);
              setErrorToast(true);
            },
          });
        }
      } else {
        await axiosPost({
          url: `/sale/participant/${participantId}/campaign/${code}`,
          body: sale,
          success: () => {
            setUpdateCampaign(false);
            setErrorMessage('');
            setErrorToast(false);
            window.location.reload();
          },
          error: (error: any) => {
            const { data } = error.response;
            setErrorMessage(data.message);
            setErrorToast(true);
          },
        });
      }
    });
  };

  const fetchParticipants = async () => {
    await axiosGet({
      url: '/participant',
      success: (res: AxiosResponse) => {
        setParticipantNameList(res.data.data.participants.map((participant: Participant) => ({
          id: participant.id,
          name: participant.name,
        })));
        setErrorMessage('');
        setErrorToast(false);
      },
      error: (error: any) => {
        const { data } = error.response;
        setErrorMessage(data.message);
        setErrorToast(true);
      },
    });
  };

  useEffect(() => {
    (async () => {
      await fetchCampaignData();
      await fetchTeamData();
      await fetchPercentages();
      await fetchParticipants();
    })();
  }, [fetchCampaignData, fetchTeamData, fetchPercentages]);

  return (
    <>
      {ErrorToast()}
      <Container>
        <Content>
          <Navbar />
          <Header
            title={campaignData?.campaign.title}
            description={campaignData?.campaign.description}
            campaignCode={campaignData?.campaign.code}
            onClickReturn={() => {
              window.location.href = '/minhas-campanhas';
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ alignSelf: 'flex-start', marginTop: '20px' }}>
                <Button
                  text="Editar"
                  backgroundColor={theme.colors.bodyWhite}
                  hoverBackground={theme.colors.bodyWhite}
                  width={theme.buttons.width.small}
                  height={theme.buttons.height.small}
                  textColor="#191919"
                  borderRadius={theme.buttons.borderRadius.medium}
                  iconLeft={<Create /> as unknown as React.ElementType}
                  border={`2px solid ${theme.colors.mainGray}`}
                  onClick={() => {
                    window.location.href = `../editar-campanha/${code}`;
                  }}
                />
              </div>
              <div style={{ alignSelf: 'flex-start', marginTop: '20px' }}>
                <Button
                  text="Baixar resumo da campanha"
                  backgroundColor="#F2F2F2"
                  hoverBackground="#F2F2F2"
                  width="280px"
                  height="66px"
                  textColor="#1C1C1C"
                  borderRadius={theme.buttons.borderRadius.medium}
                  iconRight={<Download /> as unknown as React.ElementType}
                  border="1px solid #ECECEC"
                  subText="Arquivos disponíveis: .pdf e .xml"
                  onClick={handlePrintCampaign}
                />
              </div>
            </div>
          </Header>

          <div style={{ zIndex: -10, display: 'none' }}>
            {campaignData?.campaign.code && (
              <ReportTemplate
                campaignCode={campaignData?.campaign.code}
                reportType={campaignData.campaign.teamModality ? 'team' : 'individual'}
                componentRef={componentRefCampaign}
              />
            )}
          </div>

          {showDeletionModal && deleteModal()}

          {closeCampaign && closeCampaignModal()}

          {updateCampaign && updateCampaignModal()}

          {participantPerformance && participantPerformanceModal()}

          {teamModal && addTeamModal()}

          {createdTeam && createdTeamModal()}

          {participantModal && addParticipantModal()}

          <HeaderButtons>
            <Button
              text="Encerrar Campanha"
              width={theme.buttons.width.large}
              height={theme.buttons.height.small}
              backgroundColor={theme.colors.mainBlack}
              borderRadius={theme.buttons.borderRadius.large}
              hoverBackground={theme.colors.cherry}
              textColor={theme.colors.bodyWhite}
              border={`2px solid ${theme.colors.mainBlack}`}
              hoverBorder={theme.colors.cherry}
              hoverTextColor={theme.colors.mainWhite}
              onClick={() => setCloseCampaign(true)}

            />

            <Button
              text="Atualizar manualmente os dados da campanha"
              onClick={() => setUpdateCampaign(true)}
              width="350px"
              height={theme.buttons.height.small}
              backgroundColor={theme.colors.bodyWhite}
              borderRadius={theme.buttons.borderRadius.small}
              textColor={theme.colors.lighterViolet}
              border={`2px solid ${theme.colors.lighterViolet}`}
            />
          </HeaderButtons>

          <Section>
            <SectionTitle>Vendas gerais</SectionTitle>
            <div style={{ display: 'flex', justifyContent: 'space-between', margin: '30px 0' }}>
              {loadingCampaign
                ? (
                  <Skeleton
                    variant="rectangular"
                    width="60%"
                    height={430}
                    animation="wave"
                    sx={{ bgcolor: theme.colors.lighterGray }}
                    style={{ borderRadius: theme.card.borderRadius.large }}
                  />
                )
                : (
                  <SalesChart
                    width="60%"
                    height="430px"
                    sales={campaignData?.totalSales}
                    percents={percentages}
                  />
                )}
              <div>
                {loadingCampaign
                  ? (
                    <>
                      <Skeleton
                        variant="rectangular"
                        width={270}
                        height={138}
                        animation="wave"
                        sx={{ bgcolor: theme.colors.lighterGray }}
                        style={{ borderRadius: theme.card.borderRadius.medium }}
                      />
                      <Skeleton
                        variant="rectangular"
                        width={270}
                        height={272}
                        animation="wave"
                        sx={{ bgcolor: theme.colors.lighterGray }}
                        style={{ borderRadius: theme.card.borderRadius.medium, margin: '20px 20px 20px 0' }}
                      />
                    </>
                  )
                  : (
                    <>
                      {campaignData
                        && <CountdownTimer date={campaignData?.campaign.expirationDate} />}
                      <ProgressCircle
                        presentValue={campaignData?.totalSales}
                        fullValue={campaignData?.expectedTotalMoney}
                      />
                    </>
                  )}
              </div>
            </div>
          </Section>

          <Section>
            <SectionTitle>Premiações cadastradas na campanha</SectionTitle>
            <div style={{ marginTop: 40 }}>
              {loadingCampaign
                ? (
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={145}
                    animation="wave"
                    sx={{ bgcolor: theme.colors.lighterGray }}
                    style={{ borderRadius: theme.card.borderRadius.medium }}
                  />
                )
                : (
                  <GoalsAndPrizes
                    goals={campaignData ? campaignData.goals : []}
                  />
                )}
            </div>
          </Section>
          {campaignData?.campaign.teamModality === true
            && (
              <Section>
                <TopContent>
                  <SectionTitle>Times cadastrados</SectionTitle>
                  <Button
                    text="Adicionar time"
                    width={theme.buttons.width.large}
                    height={theme.buttons.height.medium}
                    backgroundColor="transparent"
                    border={`2px solid ${theme.colors.mainGray}`}
                    borderRadius={theme.buttons.borderRadius.medium}
                    textColor={theme.colors.mainGray}
                    iconLeft={<AddCircle /> as unknown as React.ElementType}
                    onClick={() => setTeamModal(true)}
                  />
                </TopContent>

                {loadingTeam
                  ? (
                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height={145}
                      animation="wave"
                      sx={{ bgcolor: theme.colors.lighterGray }}
                      style={{ borderRadius: theme.card.borderRadius.small }}
                    />
                  )
                  : (
                    <TableComponent
                      title="Times da campanha"
                      columns={campaignTeamColumns}
                      campaignTeamData={campaignTeamData?.teams}
                      total={campaignTeamData?.totalTeams}
                      deleteRows={(value: any) => {
                        setShowDeletionModal(true);
                        setDeletionObject({
                          rows: value,
                          type: 'team',
                        });
                      }}
                      onClickLink={(row: Team) => {
                        window.location.href = `${window.location.pathname}/time/${row.accessCode}`;
                      }}
                      filterTable={(query: string) => {
                        fetchTeamData(`&${query}&type=teams`);
                      }}
                    />
                  )}
              </Section>
            )}
          <Section>
            <TopContent>
              <SectionTitle>Participantes individuais da campanha</SectionTitle>
              {!campaignData?.campaign.teamModality && (
                <Button
                  text="Adicionar participante"
                  width={theme.buttons.width.large}
                  height={theme.buttons.height.medium}
                  backgroundColor="transparent"
                  border={`2px solid ${theme.colors.mainGray}`}
                  borderRadius={theme.buttons.borderRadius.medium}
                  textColor={theme.colors.mainGray}
                  iconLeft={<AddCircle /> as unknown as React.ElementType}
                  onClick={() => setParticipantModal(true)}
                />
              )}
            </TopContent>
            {loadingCampaign
              ? (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={145}
                  animation="wave"
                  sx={{ bgcolor: theme.colors.lighterGray }}
                  style={{ borderRadius: theme.card.borderRadius.small }}
                />
              )
              : (
                <TableComponent
                  title="Participantes da campanha"
                  columns={teamParticipantColumns}
                  campaignParticipantData={campaignData?.participants}
                  total={campaignData?.totalParticipants}
                  teamModality={campaignData?.campaign.teamModality}
                  deleteRows={(value: any) => {
                    setShowDeletionModal(true);
                    setDeletionObject({
                      rows: value,
                      type: 'participant',
                    });
                  }}
                  onClickLink={async (row: Participant) => {
                    setParticipantPerformance(true);
                    console.log(row);
                    setSelectedParticipant(row);
                    await fetchParticipantSales(row.id);
                  }}
                  filterTable={(query: string) => {
                    fetchCampaignData(`&${query}&type=participants`);
                  }}
                />
              )}

          </Section>

        </Content>
      </Container>
    </>
  );
};

export default Campaign;
