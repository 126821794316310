/* eslint-disable react/require-default-props */
import {
  HeaderMain,
  HeaderButton,
  HeaderDescription,
  HeaderInformation,
  HeaderText,
  HeaderTextInformation,
  HeaderTextNumber,
  HeaderTitle,
  HeaderBody,
} from './style';
import { ChevronLeft } from '../../assets/icons';

type ButtonChildren<T = unknown> = T & {
  children?: React.ReactNode,
};

export interface HeaderProps {
  campaignCode?: string;
  title?: string;
  children?: ButtonChildren;
  description?: string;
  margin?: string,
  display?: string,
  backToPageText?: string;
  overTitleText?: string;
  onClickReturn?: () => void;
}

const Header: React.ElementType = ({
  title,
  children,
  campaignCode,
  description,
  margin,
  display,
  backToPageText,
  overTitleText,
  onClickReturn,
}: HeaderProps) => (
  <>
    <HeaderMain>
      <HeaderButton onClick={onClickReturn} display={display}>
        <ChevronLeft />
        {backToPageText || 'Voltar'}
      </HeaderButton>
      <HeaderBody>
        <HeaderInformation>
          <HeaderTextInformation>
            {campaignCode && <HeaderText>Código da campanha</HeaderText>}
            <HeaderTextNumber>{campaignCode}</HeaderTextNumber>
            <HeaderText>{overTitleText}</HeaderText>
          </HeaderTextInformation>
          <HeaderTitle margin={margin}>
            {title}
          </HeaderTitle>
          <HeaderDescription>{description}</HeaderDescription>
        </HeaderInformation>
        {children}
      </HeaderBody>
    </HeaderMain>
  </>
);

export default Header;
