import styled from 'styled-components';

import theme from '../../assets/styles/theme';
import { Download } from '../../assets/icons';

type ModalProps = {
  width?: string;
  height?: string;
  centered?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Content = styled.div`
  width: ${theme.pages.width};
  max-width: ${theme.pages.maxWidth};
  padding-bottom: 50px;
`;

export const HeaderButtons = styled.div`
  width: 570px;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const Section = styled.section`
  width: 100%;
  padding: 25px 0;
  margin: 20px 0;
`;

export const SectionTitle = styled.h1`
  font: ${theme.fonts.secondary.h2};
  font-weight: bold;
  color: ${theme.colors.mainBlack};
  margin-bottom: 15px;
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const ModalContent = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.centered ? 'center' : 'space-between')};
  align-items: ${(props) => (props.centered && 'center')};
  width: 100%;
  height: 550px;
  margin-top: -30px;
  padding-bottom: 30px;
  padding-right: 15px;
  margin-right: 5px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding-right: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.lightGray};
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb:hover{
    
  }
  ::-webkit-scrollbar-track{
    background: #ffffff;
    border-radius: 15px;
  }
`;

export const DownloadIcon = styled(Download)`
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
`;

export const UploadButton = styled.div`
  width: 300px;
  height: 200px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #EFEFEF;
  border: 2px dashed #AEAEAE;
  margin-bottom: 20px;
  cursor: pointer;
  input {
    display: none;
  }
`;

export const Title = styled.h3`
  font-size: 16px;
  margin: 0 0 15px 0;
  color: ${theme.colors.mainGray};
`;

export const Goals = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;
`;

export const Prizes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 10px 0;
`;

export const ModalCard = styled.div`
  background: ${theme.colors.bodyWhite};
  box-shadow: 0px 3px 6px #0000001A;
  border: 1px solid #E1E1E1;
  border-radius: 20px;
  width: 270px;
  height: 70px;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 10px;
  margin-right: 30px;
`;

export const ModalCardCategory = styled.p`
  font: ${theme.fonts.primary.body};
  color: ${theme.colors.mainBlack};
`;

export const ModalCardPrize = styled.h2`
  font: ${theme.fonts.primary.h2};
  color: ${theme.colors.mainBlack};
  font-weight: bold;
`;

export const Sales = styled.div`
  width: 100%;
  height: 550px;
  margin-top: 10px;
`;

export const NotificationText = styled.div`
  display: flex;
  align-items: center;
  font:${theme.fonts.primary.body};
  gap:3px;
  svg{
    width: 26px;
    height: 24px;
  }
`;

export const ModalInputs = styled.input`
  width: 70%;
  height: 40px;
`;

export const CreatedTeam = styled.div`
  width: 100%;
  height: 100px;
  border: 1px solid ${theme.colors.mainBlack};
  margin-bottom: 40px;
  border-radius: 10px;
  padding: 20px;
`;

export const TeamName = styled.h2`
  font: ${theme.fonts.secondary.h2};
  color: ${theme.colors.mainBlack};
`;

export const TeamCode = styled.h3`
  font: ${theme.fonts.secondary.h3};
  color: ${theme.colors.mainBlack};
  font-weight: bold;
  margin-left: 10px;
`;
export const TeamCodeText = styled.p`
  font: ${theme.fonts.secondary.small};
  color: ${theme.colors.mainBlack};
  display: flex;
  align-items: baseline;
  margin-top: 10px;
`;

export const SelectedFileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
`;

export const SelectedFileText = styled.p`
  font: ${theme.fonts.secondary.h3};
  color:${theme.colors.mainBlack};
`;

export const AutoCompleteContainer = styled.ul`
  background: ${theme.colors.bodyWhite};
  padding: 8px 0;
  list-style-type: none;
  min-width: 473px;
  position: absolute;
  top: 100%;
  border-radius: ${theme.buttons.borderRadius.small};
  max-height: 120px;
  overflow-y: auto;
  z-index: 1;
  border: 1px solid ${theme.colors.mediumGray};
  box-sizing: border-box;
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding-right: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.lightGray};
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb:hover{
    
  }
  ::-webkit-scrollbar-track{
    background: #ffffff;
    border-radius: 15px;
  }
`;

export const AutoCompleteItem = styled.li`
  width: 100%;
  &:hover {
    background-color:${theme.colors.lighterGray}
  }
`;

export const AutoCompleteItemButton = styled.button`
  padding: 0 24px;
  width: 100%;
  line-height: 32px;
  text-align: left;
  border-radius: ${theme.buttons.borderRadius.small};
  border: none;
  background-color: ${theme.colors.bodyWhite};
  &:hover {
    background-color:${theme.colors.lighterGray}
  }
  &:active {
    outline: none;
    color: ${theme.colors.lighterViolet}
  }
  
`;
