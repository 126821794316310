import styled from 'styled-components';
import { TextField } from '@material-ui/core';

type InputProps = {
  check: boolean | null;
}

export const Input = styled(TextField) <InputProps>`
  width: 100%;
  height: 100%;

  .MuiInput-underline:after {
    border-bottom-color: ${(props: InputProps) => props.check && '#06A151 !important'};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${(props: InputProps) => props.check && '#06A151 !important'};
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${(props: InputProps) => props.check && '#06A151 !important'};
  }
`;
