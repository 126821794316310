import React, {
  useCallback,
  useEffect,
  useState,
  useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useReactToPrint } from 'react-to-print';
import Skeleton from '@mui/material/Skeleton';
import useAxios from '../../utils/useAxios';
import {
  Navbar,
  Header,
  Button,
  RSuiteModal,
  TableComponent,
  ReportTemplate,
  Toast,
} from '../../components';
import { Download } from '../../assets/icons';
import theme from '../../assets/styles/theme';

import {
  Container,
  FinishedCampaignPage,
  ModalBody,
  CampaignInfo,
  PrizesAndSalesCards,
  PrizeCost,
  SalesMade,
  CardTitle,
  CardCurrency,
  CardValue,
  CardInfo,
  TableTitle,
  ModalCard,
  ModalCardCategory,
  ModalCardPrize,
} from './style';

type Campaign = {
  id: string,
  code: number,
  title: string,
  description: string,
  expirationDate: string,
  image: string,
  regulation: string,
  isActive: boolean,
  teamModality: boolean,
  manager: string,
  totalSales: number,
  totalPrizesCost: number,
  participants: object[]
}

type Prize = {
  id?: string,
  prizeModality?: string,
  name?: string,
  price?: number,
  photo?: string,
  goal?: string,
  percentageNeeded?: number
}

type Participant = {
  participantId: string;
  participantName: string;
  total: number;
  prizes: Prize[]
}

type CampaignData = {
  campaign: Campaign;
  totalSales: number;
  expectedTotalMoney: number;
  prizesCost: number;
  participants: Participant[];
  totalParticipants: number;
  teamModality: boolean;
}

const tableColumns = [
  {
    disablePadding: false,
    id: 'name',
    label: 'Nome do(a) participante',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'sales',
    label: 'Vendas(R$)',
    numeric: true,
  },
  {
    disablePadding: false,
    id: 'prizes',
    label: 'Premiações',
    numeric: false,
  },
];

const FinishedCampaign: React.FC = () => {
  const [axiosGet] = useAxios('get');
  const [campaignData, setCampaignData] = useState<CampaignData>();
  const [show, setShow] = useState(false);
  const componentRef = useRef(null);
  const [prizes, setPrizes] = useState<Prize[] | 'empty'>('empty');
  const [selectedParticipant, setSelectedParticipant] = useState<Participant>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorToast, setErrorToast] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const ErrorToast = () => (
    <Toast
      type="error"
      message={errorMessage}
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  const { code } = useParams<{ code: string }>();

  const close = () => {
    setShow(false);
  };

  const open = () => {
    setShow(true);
  };

  const fetchCampaignData = useCallback(async (query = 'order=default&rows=10&page=0') => {
    await axiosGet({
      url: `campaign/finished/${code}?${query || ''}`,
      success: (res: AxiosResponse) => {
        const { data } = res.data;
        setCampaignData(data);
        setErrorToast(false);
        setErrorMessage(null);
        setTimeout(() => setLoading(false), 500);
      },
      error: (error: any) => {
        setErrorMessage(error.response.data.message);
        setErrorToast(true);
      },
    });
  }, [axiosGet, code]);

  useEffect(() => {
    (async () => {
      await fetchCampaignData();
    })();
  }, [axiosGet, fetchCampaignData, code]);

  useEffect(() => {
    if (prizes !== 'empty') {
      open();
    }
  }, [prizes]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      {ErrorToast()}
      <Container>
        <FinishedCampaignPage>
          <Navbar />
          <CampaignInfo>
            <Header
              title={campaignData?.campaign.title}
              description={campaignData?.campaign.description}
              backToPageText="Voltar para a página de campanhas"
              overTitleText="Resumo da campanha"
              onClickReturn={() => {
                window.location.href = '/minhas-campanhas';
              }}

            >
              <div style={{ alignSelf: 'flex-start', marginTop: '20px' }}>
                <Button
                  text="Baixar resumo da campanha"
                  backgroundColor="#F2F2F2"
                  hoverBackground="#F2F2F2"
                  width="280px"
                  height="66px"
                  textColor="#1C1C1C"
                  borderRadius={theme.buttons.borderRadius.medium}
                  iconRight={<Download />}
                  border="1px solid #ECECEC"
                  subText="Arquivos disponíveis: .pdf e .xml"
                  onClick={handlePrint}
                />
              </div>
            </Header>

            <PrizesAndSalesCards>
              {loading
                ? (
                  <>
                    <Skeleton variant="rectangular" width="48%" height="100%" animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: '27px' }} />
                    <Skeleton variant="rectangular" width="48%" height="100%" animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: '27px' }} />
                  </>
                )
                : (
                  <>
                    <PrizeCost>
                      <CardTitle>Custo total das premiações</CardTitle>
                      <CardInfo>
                        <CardCurrency>R$</CardCurrency>
                        <CardValue
                          valueColor={theme.colors.lightViolet}
                        >
                          {campaignData?.prizesCost?.toLocaleString('pt-BR',
                            { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </CardValue>
                      </CardInfo>
                    </PrizeCost>

                    <SalesMade>
                      <CardTitle>Vendas totais da campanha</CardTitle>
                      <CardInfo>
                        <CardCurrency>R$</CardCurrency>
                        <CardValue>
                          {campaignData?.totalSales?.toLocaleString('pt-BR',
                            { minimumFractionDigits: 2, maximumFractionDigits: 2 })}

                        </CardValue>
                      </CardInfo>
                    </SalesMade>
                  </>
                )}

            </PrizesAndSalesCards>
          </CampaignInfo>

          <TableTitle>Relação dos participantes e premiações</TableTitle>
          {loading ? (<Skeleton variant="rectangular" width="100%" height={145} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: theme.card.borderRadius.small }} />) : (
            <TableComponent
              title="Participantes da campanha"
              columns={tableColumns}
              campaignParticipantData={campaignData?.participants}
              total={campaignData?.totalParticipants}
              onClickLink={async (row: Participant) => {
                setShow(true);
                setSelectedParticipant(row);
              }}
              filterTable={(query: string) => {
                fetchCampaignData(`${query}&type=teams`);
              }}
            />
          )}
        </FinishedCampaignPage>

        <div style={{ zIndex: -10, display: 'none' }}>
          {campaignData?.campaign.code && (
            <ReportTemplate
              campaignCode={campaignData?.campaign.code}
              reportType={campaignData.teamModality ? 'team' : 'individual'}
              componentRef={componentRef}
            />
          )}
        </div>

        <RSuiteModal
          show={show}
          rightCornerX
          title="Premiações alcançadas"
          size="md"
          width={theme.modals.width.medium}
          maxWidth={theme.modals.maxWidth.medium}
          height={theme.modals.height.large}
          onClickCancelButton={close}
        >
          <ModalBody>
            {selectedParticipant?.prizes.map((prize: Prize) => {
              if (prize.name?.includes('PocketCard')) {
                return (
                  <ModalCard>
                    <ModalCardCategory>{prize.name}</ModalCardCategory>
                    <ModalCardPrize>
                      R$
                      {' '}
                      {prize.price?.toLocaleString('pt-BR')}
                    </ModalCardPrize>
                  </ModalCard>
                );
              }
              if (prize.prizeModality === 'by each sale') {
                return (
                  <ModalCard>
                    <ModalCardCategory>Total ganho pela venda de unidades</ModalCardCategory>
                    <ModalCardPrize>
                      R$
                      {' '}
                      {prize.price?.toLocaleString('pt-BR')}
                    </ModalCardPrize>
                  </ModalCard>
                );
              }

              return (
                <ModalCard>
                  <ModalCardCategory>Prêmio físico</ModalCardCategory>
                  <ModalCardPrize>{prize.name}</ModalCardPrize>
                </ModalCard>
              );
            })}
          </ModalBody>
        </RSuiteModal>

      </Container>
    </>

  );
};

export default FinishedCampaign;
