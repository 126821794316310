import styled from 'styled-components';
import { TextField } from '@material-ui/core';

type InputProps = {
  check: boolean | null;
}

export const Input = styled(TextField) <InputProps>`
  width: 100%;
  height: 100%;

  .MuiFormLabel-root.Mui-focused {
    color: ${(props: InputProps) => props.check && '#06A151 !important'};
    border-color: ${(props: InputProps) => props.check && '#06A151 !important'};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props: InputProps) => props.check && '#06A151 !important'};
    color: ${(props: InputProps) => props.check && '#06A151 !important'};
  }

  .MuiFormHelperText-root.Mui-error {
    color: ${(props: InputProps) => props.check && '#06A151 !important'};
  }
`;
