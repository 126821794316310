const theme = {
  colors: {
    mainWhite: '#F4F4F4', // background and fonts
    mainBlack: '#2C2C2C', // base
    mainGray: '#6B6B6B', // support
    secondaryGray: '#707070',
    darkerGray: '#2C2C2C',
    mediumGray: '#B5B5B5',
    lightGray: '#E6E6E6',
    lighterGray: '#F4F4F4',
    darkerViolet: '#210855',
    darkViolet: '#41149C',
    mediumViolet: '#462097',
    lightViolet: '#8040FF',
    lighterViolet: '#7136E8',
    darkCherry: '#B50928',
    lightCherry: '#B10222',
    cherry: '#D71352',
    errorRed: '#D10F32',
    correctGreen: '#06A151',
    peachOrange: '#FFC993',
    bodyWhite: '#FFFFFF',
  },
  fonts: {
    primary: {
      h1: '500 20px Poppins, sans-serif',
      h2: '500 18px Poppins, sans-serif',
      h3: '500 16px Poppins, sans-serif',
      body: '400 14px Poppins, sans-serif',
      small: '400 12px Poppins, sans-serif',
    },
    secondary: {
      h1: '500 20px Roboto, sans-serif',
      h2: '500 18px Roboto, sans-serif',
      h3: '500 16px Roboto, sans-serif',
      body: '400 14px Roboto, sans-serif',
      small: '400 12px Roboto, sans-serif',
    },
  },
  buttons: {
    borderRadius: {
      small: '4px',
      medium: '10px',
      large: '25px',
    },
    width: {
      small: '120px',
      medium: '140px',
      large: '190px',
      extraLarge: '200px',
    },
    height: {
      small: '35px',
      medium: '40px',
      large: '50px',
    },
  },
  card: {
    borderRadius: {
      small: '12px',
      medium: '20px',
      large: '33px',
    },
  },
  pages: {
    width: '90%',
    maxWidth: '1229px',
  },
  modals: {
    width: {
      small: '25%',
      medium: '35%',
      large: '43%',
    },
    maxWidth: {
      small: '450px',
      medium: '525px',
      large: '600px',
    },
    height: {
      small: '272px',
      medium: '349px',
      large: '408px',
    },
  },
};

export default theme;
