/* eslint-disable react/require-default-props */
import React from 'react';
import { StyledModal, CloseIcon } from './style';
import Button from '../../Button';
import theme from '../../../assets/styles/theme';
import '../style.css';
import { Close } from '../../../assets/icons';

type WithChildren<T = unknown> = T & {
  children?: React.ReactNode,
};

export interface ModalProps {
  show: boolean;
  title?: string;
  size: 'xs' | 'sm' | 'md' | 'lg';
  backButton?: React.ElementType;
  rightCornerX?: boolean;
  width?: string;
  height?: string;
  cancelButton?: boolean,
  onClickCancelButton: () => void;
  confirmButtonText?: string;
  confirmButtonColor?: string;
  onClickConfirmButton?: () => void;
  bigConfirmButton?: boolean;
  children?: WithChildren;
  maxWidth?: string;
  onExited?: () => void;
}

const RSuiteModal: React.ElementType = ({
  show,
  title,
  backButton,
  confirmButtonText,
  confirmButtonColor,
  onClickConfirmButton,
  size,
  rightCornerX,
  width,
  height,
  cancelButton,
  onClickCancelButton,
  bigConfirmButton,
  children,
  maxWidth,
  onExited,
}: ModalProps) => (

  <StyledModal
    show={show}
    size={size}
    rightCornerX={rightCornerX}
    width={width}
    height={height}
    maxWidth={maxWidth}
    onExited={onExited}
  >
    <StyledModal.Header>
      <StyledModal.Title>{title}</StyledModal.Title>
      {rightCornerX
        && (
          <CloseIcon onClick={onClickCancelButton}>
            <Close />
          </CloseIcon>
        )}
    </StyledModal.Header>
    <StyledModal.Body>
      {children}
    </StyledModal.Body>
    <StyledModal.Footer>
      {cancelButton && (
        <Button
          text="Cancelar"
          width={theme.buttons.width.small}
          height={theme.buttons.height.small}
          textColor="#191919"
          backgroundColor={theme.colors.bodyWhite}
          hoverBackground={theme.colors.bodyWhite}
          border="0"
          fontSize="13px"
          boxShadow="0"
          onClick={onClickCancelButton}
        />
      )}

      {backButton}

      {confirmButtonText && (
        <Button
          text={confirmButtonText}
          backgroundColor={confirmButtonColor || theme.colors.lightViolet}
          hoverBackground={confirmButtonColor || theme.colors.lighterViolet}
          width={bigConfirmButton ? theme.buttons.width.medium : theme.buttons.width.small}
          height={theme.buttons.height.small}
          textColor={theme.colors.mainWhite}
          border="none"
          onClick={onClickConfirmButton}
        />
      )}
    </StyledModal.Footer>
  </StyledModal>
);

export default RSuiteModal;
