import React, {
  useContext, useState, ChangeEvent,
} from 'react';

import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { campaignDataContext } from '../../../../hooks/campaignContext';

import { Upload, ArrowRight } from '../../../../assets/icons';
import theme from '../../../../assets/styles/theme';

import {
  GeneralCard,
  OutlinedInput,
  Button,
  Toast,
} from '../../../../components';

import {
  StepContainer,
  InputsContainer,
  SectionTitle,
  SectionSubtitle,
  UploadDiv,
  UploadText,
  SectionContainer,
  UploadButtonDiv,
  DatePickerDiv,
  CalendarIcon,
  PictureIcon,
} from './style';

type StepProps = {
  current: number,
  SetCurrent: (current: number) => void;
}

const BasicInfo: React.ElementType = ({
  current,
  SetCurrent,
}: StepProps) => {
  const { campaign } = useContext(campaignDataContext);
  const [fileType, setFileType] = useState('');
  const [errorToast, setErrorToast] = useState<boolean>(false);

  const ErrorToast = () => (
    <Toast
      type="error"
      message="Este arquivo que você tentou selecionar é muito grande. Escolha arquivos de até 5mb."
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  const handleFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const reader = new FileReader();
      const target = e.target.files[0];

      if (e.target.files[0] && e.target.files[0].size <= 5000000) {
        reader.readAsDataURL(e.target.files[0]);
      } else {
        setErrorToast(true);
      }

      if (fileType !== '' && fileType === 'image') {
        reader.onloadend = () => {
          campaign.setImagePreview(String(reader?.result));
          campaign.setImage(target);
        };
      } else if (fileType !== '' && fileType === 'pdf') {
        reader.onloadend = () => {
          campaign.setRegulationPreview(target.name);
          campaign.setRegulation(target);
        };
      }
    }
  };

  return (
    <>
      {ErrorToast()}
      <StepContainer>
        <div style={{ maxWidth: '1225px', width: '75%' }}>
          <GeneralCard height="420px" padding="10px">
            <InputsContainer>

              <SectionContainer height="80%" width="26%">
                <SectionTitle primaryFont>Foto da campanha</SectionTitle>
                <UploadDiv imageUrl={campaign.imagePreview}>
                  <PictureIcon imageUrl={campaign.imagePreview} />
                </UploadDiv>
                <label htmlFor="image_input">
                  <UploadButtonDiv>
                    <UploadText>Fazer upload da foto</UploadText>
                    <Upload />
                    <input
                      id="image_input"
                      type="file"
                      accept="image/jpeg, image/jpg, image/pjpeg, image/png"
                      onChange={handleFiles}
                      onClick={() => setFileType('image')}
                    />
                  </UploadButtonDiv>
                </label>
              </SectionContainer>

              <SectionContainer height="95%" width="30%">
                <SectionContainer height="70%">
                  <div style={{ width: '100%' }}>
                    <OutlinedInput
                      type="alphanumeric"
                      title="Título da campanha"
                      value={campaign.title}
                      setValue={campaign.setTitle}
                      required
                    />
                  </div>
                  <div style={{ width: '100%' }}>
                    <OutlinedInput
                      type="alphanumeric"
                      title="Descrição da campanha"
                      setValue={campaign.setDescription}
                      value={campaign.description}
                      multiline
                    />
                  </div>
                </SectionContainer>
                <div>
                  <SectionTitle primaryFont={false}>Selecionar período da campanha</SectionTitle>

                  <DatePickerDiv>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                      <DatePicker
                        style={{ width: '75px' }}
                        value={campaign.initialDate}
                        format="dd/MM/yyyy"
                        onChange={campaign.setInitialDate}
                        disablePast
                        onAccept={(date) => {
                          if (date && campaign.expirationDate) {
                            if (date.getTime() > campaign.expirationDate.getTime()) {
                              const weekAfter = new Date(date);
                              weekAfter.setDate(weekAfter.getDate() + 7);
                              campaign.setExpirationDate(weekAfter);
                            }
                          }
                        }}
                      />
                      <p>-</p>
                      <DatePicker
                        style={{ width: '75px' }}
                        value={campaign.expirationDate}
                        format="dd/MM/yyyy"
                        onChange={campaign.setExpirationDate}
                        minDate={campaign.initialDate}
                        minDateMessage="Data deve ser após data inicial."
                      />
                      <CalendarIcon />
                    </MuiPickersUtilsProvider>
                  </DatePickerDiv>

                </div>

              </SectionContainer>

              <SectionContainer width="24%" height="35%">
                <SectionTitle primaryFont>Regulamento da campanha</SectionTitle>
                <SectionSubtitle>
                  Arquivo Atual:
                  {' '}
                  {campaign.regulationPreview}
                </SectionSubtitle>
                <div id="pdf_container">
                  <Button
                    text="Fazer upload do documento"
                    backgroundColor="#DBDBDB"
                    hoverBackground="#B8B8B8"
                    borderRadius={theme.buttons.borderRadius.medium}
                    width="100%"
                    height={theme.buttons.height.medium}
                    textColor="#191919"
                    iconRight={<Upload />}
                    // onClick={}
                  />
                  <input
                    id="pdf_input"
                    type="file"
                    accept="application/pdf"
                    onChange={handleFiles}
                    onClick={() => setFileType('pdf')}
                  />
                </div>
              </SectionContainer>

            </InputsContainer>
          </GeneralCard>
        </div>
        <Button
          text="PROSSEGUIR"
          backgroundColor={theme.colors.bodyWhite}
          hoverBackground="#EFEFEF"
          hoverTextColor="#363636"
          border={`2px solid ${theme.colors.mainGray}`}
          borderRadius={theme.buttons.borderRadius.medium}
          width="150px  "
          height={theme.buttons.height.medium}
          textColor={theme.colors.mainGray}
          iconRight={<ArrowRight />}
          onClick={() => {
            if (campaign.title !== '') {
              SetCurrent(current + 1);
            } else {
              alert('Preencha o campo "Título da campanha" antes de prosseguir.');
            }
          }}
        />
      </StepContainer>
    </>
  );
};
export default BasicInfo;
