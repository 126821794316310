import styled from 'styled-components';
import theme from '../../assets/styles/theme';

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 60px;
`;

export const CreateCampaignPage = styled.div`
  width: ${theme.pages.width};
  max-width: ${theme.pages.maxWidth};
`;

export const StepperContainer = styled.div`
  margin: 20px auto 80px auto;

  .ant-steps-item-finish .ant-steps-item-icon {
    border-color: ${theme.colors.lighterViolet};
    border-width: 4px;
    height: 60px;
    width: 60px;
    margin-right: 15px;
  }
  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${theme.colors.lighterViolet};
    font-size: 30px;
    width: 60px;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: ${theme.colors.lighterViolet};
    border-color: ${theme.colors.lighterViolet};
    border-width: 4px;
    color: ${theme.colors.bodyWhite};
    font-size: 20px;
    margin-right: 15px;

    width: 60px;
    height: 60px;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background-color: ${theme.colors.bodyWhite};
    border-color: ${theme.colors.mainGray};
    border-width: 3px;
    margin-right: 15px;

    width: 60px;
    height: 60px;
  }
  .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: ${theme.colors.mainGray};
    font-size: 20px;
    width: 60px;

  }

  .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
    color: ${theme.colors.mainGray};
  }
  .ant-steps-item-title{
    white-space: break-spaces;
  }

  .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
    background-color: ${theme.colors.lighterViolet};
  }

  .ant-steps-item-icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-steps-item-container{
    display: flex;
    align-items: center;
  }

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon .ant-steps-icon{
    width: 60px;
  }

`;
