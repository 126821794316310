import styled from 'styled-components';
import theme from '../../assets/styles/theme';

type CampaignInfoProps = {
  valueColor?: string;
}

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const FinishedCampaignPage = styled.div`
  width: ${theme.pages.width};
  max-width: ${theme.pages.maxWidth};
  padding-bottom: 50px;
`;

export const CampaignInfo = styled.div`
`;

export const PrizesAndSalesCards = styled.div`
  display: flex;
  justify-content: space-around;
  width: 60%;
  max-width: 1070px;
  height: 127px;
  align-items: center;
  margin: 30px 0;
`;

export const PrizeCost = styled.div`
  width: 48%;
  max-width: 534px;
  box-shadow: 0px 4px 6px #00000033;
  border: 2px solid ${theme.colors.lightViolet};
  border-radius: 27px;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 10px;
`;
export const SalesMade = styled.div`
  width: 48%;
  max-width: 534px;
  box-shadow: 0px 4px 6px #00000033;
  border-radius: 27px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  padding-bottom: 10px;
`;

export const CardTitle = styled.h3`
  padding: 5px 0;
  font: ${theme.fonts.primary.h3};
  font-weight: bold;
  color: ${theme.colors.mainBlack};
  width: 90%;

`;

export const CardCurrency = styled.p`
  font: ${theme.fonts.primary.small};
  color: ${theme.colors.mainGray};
  font-size: 20px;
`;

export const CardValue = styled.h1`
  font: ${theme.fonts.primary.h1};
  color: ${(props: CampaignInfoProps) => props.valueColor || `${theme.colors.mainBlack}`};
  font-size: 38px;
  margin-left: 25px;
  font-weight: bold;
`;

export const CardInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  width: 90%;
`;

export const TableTitle = styled.h3`
  margin: 60px 0 20px 0;
  font: ${theme.fonts.primary.h3};
  color: ${theme.colors.mainBlack};

`;
export const ModalBody = styled.div`
  display: flex;
  font: ${theme.fonts.primary.h3};
  color: ${theme.colors.mainBlack};
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
`;

export const ModalCard = styled.div`
  background: ${theme.colors.bodyWhite};
  box-shadow: 0px 3px 6px #0000001A;
  border: 1px solid #E1E1E1;
  border-radius: 20px;
  width: 90%;
  height: 70px;
  padding: 5px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-bottom: 10px;
`;

export const ModalCardCategory = styled.p`
  font: ${theme.fonts.primary.body};
  color: ${theme.colors.mainBlack};
`;

export const ModalCardPrize = styled.h2`
  font: ${theme.fonts.primary.h2};
  color: ${theme.colors.mainBlack};
  font-weight: bold;
`;
