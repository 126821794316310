/* eslint-disable no-empty */
/* eslint-disable max-len */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect, useRef } from 'react';
import Skeleton from '@mui/material/Skeleton';
import axios, { AxiosResponse } from 'axios';
import { useReactToPrint } from 'react-to-print';
import { Input } from '@mui/material';
import cwApi from '../../services/cwApi';
import {
  Container,
  CampaignListingPage,
  ActiveCampaigns,
  TextEndCampaign,
  EndCampaign,
  NoCampaignContainer,
  NoCampaignText,
  SendObsButton,
  ConfirmButton,
  ModalText,
  ModalContent,
} from './style';

import theme from '../../assets/styles/theme';
import MockTableComponent from '../../components/AwardTableComponent/MockTableComponent.json';

import {
  Warning,
  AddCircle,
  Eye,
  Download,
  Document,
  Send,
} from '../../assets/icons';

import {
  Navbar,
  Header,
  Button,
  CardCampaign,
  ProgressBar,
  Toast,
  ReportTemplate,
  ModalUi,
  TableComponent,
  AwardTable,
  OutlinedInput,
} from '../../components';

import useAxios from '../../utils/useAxios';
import { useAuth } from '../../hooks/Auth';

type Campaign = {
  id: string;
  createdAt: string;
  code: string;
  title: string;
  description: string;
  expirationDate: string;
  initialDate: string;
  image: string;
  regulation: string;
  isActive: boolean;
  teamModality: boolean;
}

interface CampaignCards {
  participantsNumber: number;
  goalSales: number | null;
  salesMade: number | null;
  campaign: Campaign;
}

interface Goal {
  percentageSold: number;
}

interface Prize {
  name:string;
  price: number;
  prizeModality: string;
}

interface Participant {
  id: string;
  goalsPerfomance: Goal[];
  sold: number;
  prizes: Prize[];
}

interface Prize {
  cnpj_empresa: string;
  cpf: string;
  nome: string;
  acao: string;
  valor: number;
}

interface Array {
  nome: string;
  cpf: string;
  cnpj_empresa: string;
  acao: string;
}

const salesHistoryColumns = [
  {
    disablePadding: false,
    id: 'name',
    label: 'Nome da venda registrada',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'value',
    label: 'Valor(R$)',
    numeric: true,
  },
];

const RequestColumns = [
  {
    disablePadding: false,
    id: 'representative',
    label: 'Participante',
    numeric: false,
  },
  {
    disablePadding: false,
    id: 'Id',
    label: 'Vendas',
    numeric: false,
  },
];

const CampaignListing: React.FC = () => {
  const [axiosGet] = useAxios('get');
  const { user } = useAuth();
  const componentRef = useRef(null);
  const [campaignData, setCampaignData] = useState<CampaignCards[] | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [selectedCampaign, setSelectedCampaign] = useState<any>({
    code: null,
    teamModality: null,
  });
  const [participants, setParticipants] = useState<Participant[]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [modal, setModal] = useState<boolean>(false);
  const [scndModal, setScndModal] = useState<boolean>(false);
  const [obsModal, setObsModal] = useState<boolean>(false);
  const [awardedParticipants, setAwardedParticipants] = useState();
  const participantsArray: Array[] = [];
  const prizeArray: any = [];
  let percentage = 100;

  const ErrorToast = () => (
    <Toast
      type="error"
      message={errorMessage}
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  const SuccessToast = () => (
    <Toast
      type="success"
      message="Dados enviados"
      open={successToast}
      setOpen={setSuccessToast}
    />
  );

  useEffect(() => {
    (async () => {
      await axiosGet({
        url: `/campaign/cards/manager/${user.manager}?get=true`,
        success: (res: AxiosResponse) => {
          const { campaignCards } = res.data.data;
          setCampaignData(campaignCards);
          setErrorToast(false);
          setErrorMessage(null);
          setTimeout(() => setLoading(false), 500);
        },
        error: (error: any) => {
          setErrorMessage(error.response.data.message);
          setErrorToast(true);
          setTimeout(() => setLoading(false), 500);
        },
      });
    })();
  }, [axiosGet, user.manager]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fetchCampaignData = async (query = '&order=default&rows=10&page=0') => {
    await axiosGet({
      url: `/campaign/${selectedCampaign.code}?get=full${query || ''}`,
      success: (res: AxiosResponse) => {
        res.data.data.goals.forEach((goal: any) => {
          goal.prizes.forEach((prize: any) => {
            if (prize.percentageNeeded < percentage) {
              percentage = prize.percentageNeeded;
            }
          });
        });
        setParticipants(res.data.data.participants.filter(
          (participant: Participant) => participant.goalsPerfomance[0].percentageSold >= percentage,
        ));
      },
    });
  };

  useEffect(() => {
    fetchCampaignData();
  }, [modal]);

  const sendCredit = async (token: string) => {
    console.log(prizeArray);
    await cwApi.post('/enviarCredito.php', {
      token_integracao: token,
      dados_funcionario: prizeArray,

    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const updateAward = async (token: string) => {
    await cwApi.post('/atualizarPremiado.php', {
      token_integracao: token,
      dados_funcionario: participantsArray,
    })
      .then((res) => sendCredit(token))
      .catch((err) => console.log(err));
  };

  const getToken = async () => {
    await cwApi.post('/getToken.php', {
      cnpj_principal: '07080045000162',
      senha_integracao: '123456',
    })
      .then((res) => [updateAward(res.data.token_integracao)])
      .catch((err) => console.log(err));
  };

  const getParticipantInfo = async (company: any, participant: any) => {
    await axiosGet({
      url: `/participant/${participant.id}?get=true`,
      success: (res: AxiosResponse) => {
        /* updateAward(company, res.data.data, tokenIntegration); */
        const newParticipant = {
          cnpj_novocred: '07080045000162',
          cnpj_empresa: company.cnpj.replace(/[^0-9]/g, ''),
          cpf: res.data.data.participant.cpf.replace(/[^0-9]/g, ''),
          nome: res.data.data.participant.name,
          acao: 'C',
        };
        participant.prizes.forEach((prize: any) => {
          const newPrize = {
            cnpj_empresa: company.cnpj.replace(/[^0-9]/g, ''),
            cpf: res.data.data.participant.cpf.replace(/[^0-9]/g, ''),
            nome: res.data.data.participant.name,
            acao: 'C',
            valor: prize.price,
          };
          prizeArray.push(newPrize);
        });
        participantsArray.push(newParticipant);
        console.log(prizeArray);
      },
    });
  };

  const getCompanyInfo = async () => {
    await axiosGet({
      url: `/manager/${user.manager}?get=true`,
      success: (res: AxiosResponse) => {
        participants?.forEach((participant) => {
          getParticipantInfo(res.data.data.manager, participant);
        });
        getToken();
      },
    });
  };

  return (
    <>
      {ErrorToast()}
      <Container>
        <CampaignListingPage>
          <Navbar />
          <Header
            title="Campanhas"
            description="Campanhas ativas"
            margin="25px 0 60px 0"
            display="none"
          >
            <Button
              text="Nova campanha"
              width={theme.buttons.width.large}
              height={theme.buttons.height.medium}
              backgroundColor={theme.colors.lighterViolet}
              borderRadius={theme.buttons.borderRadius.small}
              hoverBackground={theme.colors.darkViolet}
              textColor={theme.colors.mainWhite}
              iconLeft={<AddCircle />}
              isNewCampaignButton
              onClick={() => {
                window.location.href = '../criar-campanha';
              }}
            />
          </Header>
          {loading ? (
            <ActiveCampaigns>
              <Skeleton variant="rectangular" width={270} height={350} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: '33px', marginTop: '60px' }} />
              <Skeleton variant="rectangular" width={270} height={350} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: '33px', marginTop: '60px' }} />
              <Skeleton variant="rectangular" width={270} height={350} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: '33px', marginTop: '60px' }} />
            </ActiveCampaigns>
          ) : (
            <ActiveCampaigns style={{ marginTop: campaignData ? '60px' : '40px' }}>
              {campaignData?.map((
                campaignInfo: CampaignCards,
              ) => campaignInfo.campaign.isActive && (
                <CardCampaign
                  title={campaignInfo.campaign.title}
                  description={campaignInfo.campaign.description}
                  backgroundImage={campaignInfo.campaign.image}
                >
                  <ProgressBar
                    presentValue={campaignInfo.salesMade}
                    fullValue={campaignInfo.goalSales}
                  />
                  <Button
                    text="Ver Campanha"
                    width={theme.buttons.width.medium}
                    height={theme.buttons.height.small}
                    backgroundColor={theme.colors.mainWhite}
                    borderRadius={theme.buttons.borderRadius.large}
                    hoverBackground={theme.colors.lightViolet}
                    textColor={theme.colors.mediumViolet}
                    iconLeft={<Eye />}
                    border={`2px solid ${theme.colors.mediumViolet}`}
                    hoverBorder={theme.colors.lightViolet}
                    hoverTextColor={theme.colors.mainWhite}
                    onClick={() => {
                      window.location.href = `campanha/${campaignInfo.campaign.code}`;
                    }}
                  />
                </CardCampaign>
              ))
                || (
                  <>

                    <NoCampaignContainer>
                      <Warning />
                      <NoCampaignText>Você ainda não tem campanhas ativas</NoCampaignText>
                    </NoCampaignContainer>
                  </>
                )}
            </ActiveCampaigns>
          )}
          <TextEndCampaign> Campanhas finalizadas </TextEndCampaign>
          {loading ? (
            <ActiveCampaigns>
              <Skeleton variant="rectangular" width={270} height={280} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: '33px', marginTop: '60px' }} />
              <Skeleton variant="rectangular" width={270} height={280} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: '33px', marginTop: '60px' }} />
              <Skeleton variant="rectangular" width={270} height={280} animation="wave" sx={{ bgcolor: theme.colors.lighterGray }} style={{ borderRadius: '33px', marginTop: '60px' }} />
            </ActiveCampaigns>
          ) : ((campaignData?.some(
            (campaign: CampaignCards) => !campaign.campaign.isActive,
          )
            && (
              <>
                <EndCampaign>
                  {campaignData?.map((
                    campaignInfo: CampaignCards,
                  ) => !campaignInfo.campaign.isActive && (
                    <CardCampaign
                      title={campaignInfo.campaign.title}
                      description={`Data de conclusão: ${new Date(campaignInfo.campaign.expirationDate).toLocaleDateString('pt-BR')}`}
                      backgroundColor="#EBEBEB"
                      backgroundImage={campaignInfo.campaign.image}
                      height="220px"
                      onClickImage={() => {
                        window.location.href = `/campanha/${campaignInfo.campaign.code}/finalizada`;
                      }}
                    >
                      <Button
                        iconLeft={<Document />}
                        iconRight={<Download />}
                        width="100%"
                        height={theme.buttons.height.large}
                        onClick={handlePrint}
                        onFocus={() => setSelectedCampaign(campaignInfo.campaign)}
                        // Trigger
                        // Notification
                        subText={`Campanha-${campaignInfo.campaign.code}.pdf`}
                        text="Baixar relatório"
                        textColor={theme.colors.mainBlack}
                        alinText="left"
                      />
                      <Button
                        iconLeft={<Send />}
                        width="100%"
                        height={theme.buttons.height.large}
                        onClick={() => setModal(true)}
                        onFocus={() => setSelectedCampaign(campaignInfo.campaign)}
                        // Trigger
                        // Notification
                        text="Enviar dados para Novocred"
                        textColor="white"
                        alinText="left"
                        backgroundColor="#2C2C2C"
                        hoverBackground="black"
                      />
                    </CardCampaign>
                  ))}
                </EndCampaign>
              </>
            ))
            || (
              <NoCampaignContainer style={{ marginTop: '40px' }}>
                <Warning />
                <NoCampaignText>Você ainda não tem campanhas finalizadas</NoCampaignText>
              </NoCampaignContainer>
            ))}

          <div style={{ zIndex: -10, display: 'none' }}>
            {selectedCampaign && (
              <ReportTemplate
                campaignCode={selectedCampaign.code}
                reportType={selectedCampaign.teamModality ? 'team' : 'individual'}
                componentRef={componentRef}
              />
            )}
          </div>

        </CampaignListingPage>
        <ModalUi open={modal} height="650px" width="1000px" onExited={() => setModal(false)} title="Participantes premiados">
          <AwardTable
            requestData={participants}
            columns={RequestColumns}
            total={10}
            code={selectedCampaign.code}
          />
          <SendObsButton onClick={() => [setModal(false), setObsModal(true)]}>Enviar com observacao</SendObsButton>
          <ConfirmButton onClick={() => [setModal(false), setSuccessToast(true), getCompanyInfo()]}>Confirmar dados</ConfirmButton>
        </ModalUi>
        <ModalUi open={scndModal} height="650px" width="1000px" />
        <ModalUi open={obsModal} height="408px" width="800px" title="Enviar com observação" onExited={() => [setModal(true), setObsModal(false)]}>
          <ModalContent>
            <OutlinedInput placeholder="Digite o motivo da mudança" type="alphanumeric" />
            <ModalText>Esta mensagem será enviada para os gestores do sistema Novocred</ModalText>
            <button type="submit">Confirmar envio</button>
          </ModalContent>
        </ModalUi>
      </Container>
      <Toast
        type="success"
        message="Dados enviados"
        open={successToast}
        setOpen={setSuccessToast}
      />
    </>
  );
};

export default CampaignListing;
