import styled from 'styled-components';
import theme from '../../assets/styles/theme';

type ReportTemplateProps = {
  margin?: boolean;
  size?: 'small' | 'large';
  row?: boolean;
  marginTop?: string
}

export const Template = styled.div`
  width: 797px;
  margin: 10px 0;

  @media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @page {
    size: auto;
    margin: 20mm;
  }
`;

export const TemplateHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderLogo = styled.h1`
  font-weight: 900;
  font: ${theme.fonts.primary.h1};
  font-size: 36px;
  color: ${theme.colors.darkViolet};
`;

export const HeaderTitle = styled.h3`
  font: ${theme.fonts.primary.h2};
`;

export const CampaignInfo = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  margin-bottom: 15px;
`;

export const CampaignName = styled.h3`
  font: ${theme.fonts.primary.h2};
  font-weight: bold;
  font-size: 20px;
  padding-left: 10px;
`;

export const TeamSection = styled.section`
  width: 100%;
  height: 30px;
  padding: 10px;
  background-color: ${theme.colors.cherry};
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

export const TeamName = styled.h3`
  font-weight: bold;
  font: ${theme.fonts.primary.h3};
  font-size: 18px;
  color: ${theme.colors.bodyWhite};
`;

export const InfoText = styled.p<ReportTemplateProps>`
  font-weight: bold;
  width: ${(props) => (props.size === 'small' ? '200px' : '290px')};
`;

export const ParticipantNameSection = styled.div`
  width: 100%;
  height: 30px;
  padding: 10px;
  background-color: #D9D9D9;
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

export const ParticipantName = styled.h3`
  font-weight: bold;
  font: ${theme.fonts.primary.h3};
  font-size: 18px;
  color: ${theme.colors.mainBlack};
`;

export const ParticipantSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15;
`;

export const SectionTitle = styled.p`
  width: 100%;
  border-bottom: 1px solid #D9D9D9;
  margin-top: 10px;
  padding-bottom: 10px;
  font-weight: bold !important;
  font: ${theme.fonts.primary.body};
  font-size: 16px;
  padding-left: 10px;
`;

export const SectionItems = styled.div<ReportTemplateProps>`
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
  margin-top: ${(props) => props.marginTop || '10px'};
`;

export const ItemText = styled.p<ReportTemplateProps>`
  margin-bottom: 10px;
  font: ${theme.fonts.primary.body};
  font-size: 16px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
`;
