import styled from 'styled-components';
import theme from '../../assets/styles/theme';
import { Calendar, Picture } from '../../assets/icons';

type TitleProp = {
  primaryFont: boolean
}

type ContainerProps = {
  height: string,
  justify?: string,
  width?: string,
}

type ImageProps = {
  imageUrl?: string,
}

export const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  position: relative;
`;

export const EditPage = styled.div`
  width: ${theme.pages.width};
  max-width: ${theme.pages.maxWidth};
  padding-bottom: 50px;
`;

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
export const InputsContainer = styled.div`
  display: flex;
  width: 95%;
  height: 90%;
  justify-content: space-around;
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: ContainerProps) => props.justify || 'space-between'};
  height: ${(props: ContainerProps) => props.height};
  width: ${(props: ContainerProps) => props.width};

  #pdf_container {

    position: relative;
    border-radius: 10px;
    background-color: red;

    #pdf_input {
      position: absolute;
      right: 0;
      height: 100%;
      cursor: pointer;
      min-width: 320px;
      opacity: 0;
    }
  }
`;

export const SectionTitle = styled.h3`
  font: ${(props: TitleProp) => (props.primaryFont ? theme.fonts.primary.h3 : theme.fonts.secondary.body)};
  color: ${(props: TitleProp) => (props.primaryFont ? theme.colors.secondaryGray : theme.colors.mainGray)};
`;

export const SectionSubtitle = styled.p`
  font: ${theme.fonts.secondary.body};
  color: ${theme.colors.secondaryGray};
  word-break: break-all;
  margin-top: 15px;
  margin-bottom: 18px;
`;

export const UploadDiv = styled.div`
  width: 100%;
  height: 220px;
  background: ${(props: ImageProps) => (props.imageUrl ? `url(${props.imageUrl}) no-repeat center` : theme.colors.mainBlack)};
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
`;

export const UploadButtonDiv = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-evenly;
  width: 105%;
  margin: 0 auto;
  cursor: pointer;

  input {
    display: none;
  }
`;

export const UploadText = styled.h4`
  font: ${theme.fonts.secondary.small};
  color: ${theme.colors.mainBlack};
  font-weight: bold;
`;

export const DatePickerDiv = styled.div`
  margin-top: 13px;
  border-bottom: 0.5px solid ${theme.colors.mainBlack};
  display: flex;
  align-items: center;
  width: 92%;
  justify-content: space-between;

  .MuiInput-underline:before {
    border: 0;
  }

  .MuiInputBase-root{
    font: ${theme.fonts.secondary.body};
    color: ${theme.colors.mainBlack}
  }

  @media(max-width: 935px){
    .MuiInputBase-root{
    font: ${theme.fonts.secondary.small};
  }

  .MuiInputBase-input{
    padding: 0;
  }
  }
  @media(max-width: 780px){
    .MuiInputBase-root{
    font-size: 11px;
  }
  }
`;

export const CalendarIcon = styled(Calendar)`
  margin-left: 10%;

  @media(max-width: 1200px){
    margin-left: 5%;
  }

  @media(max-width: 1120px){
    margin-left: 0;
  }
  @media(max-width: 1050px){
    display: none;
  }
`;
export const PictureIcon = styled(Picture)`
  color: ${theme.colors.bodyWhite};
  width: 80px;
  height: 80px;
  display: ${(props: ImageProps) => (props.imageUrl ? 'none' : 'inline')};
`;
