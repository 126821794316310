/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { TextField, InputAdornment } from '@material-ui/core';

type InputProps = {
  check: boolean | null;
  focus?: boolean;
  right?: boolean;
  padding?: boolean;
  error: boolean | null;
}

export const Input = styled(TextField) <InputProps>`
  width: 100%;
  height: 100%;

  // remove up and down arrow keys on number input
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }

  input {
    padding-left: ${(props: InputProps) => (!props.padding && '10px')};
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${(props: InputProps) => (props.check ? '#06A151' : props.error && '#F44336')};
  }
  
  .MuiInputAdornment-positionStart {
    p {
      color: ${(props: InputProps) => (props.check ? '#06A151' : props.error && '#F44336')};
    }
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;

    div {
      border-color: ${(props: InputProps) => (props.check ? '#06A151' : props.error && '#F44336')};
      color: ${(props: InputProps) => (props.check ? '#06A151' : props.error && '#F44336')};
    }
  }

  .MuiOutlinedInput-adornedStart {
    padding-left: ${(props: InputProps) => (!props.padding && 0)};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props: InputProps) => props.check && '#06A151 !important'};
    color: ${(props: InputProps) => props.check && '#06A151 !important'};
  }
`;

export const TypedLabel = styled.div<InputProps>`
  display: flex;
  align-items: center;
  text-align: center;
  border-left: ${(props: InputProps) => props.right && '1px solid'};
  border-right: ${(props: InputProps) => !props.right && '1px solid'};
  border-top-right-radius: ${(props: InputProps) => props.right && '4px'};
  border-bottom-right-radius: ${(props: InputProps) => props.right && '4px'};
  border-top-left-radius: ${(props: InputProps) => !props.right && '4px'};
  border-bottom-left-radius: ${(props: InputProps) => !props.right && '4px'};
  height: 100%;
  color: ${(props: InputProps) => (
    props.focus
      ? props.check ? '#06A151 !important'
        : props.error ? '#F44336' : '#3F51B5'
      : props.check ? '#06A151 !important'
        : props.error ? '#F44336' : 'rgba(0, 0, 0, 0.23)'
  )};
`;

export const TypedLegend = styled(InputAdornment) <InputProps>`
  p {
    color: ${(props: InputProps) => (
    props.focus
      ? props.check ? '#06A151 !important' : '#3F51B5'
      : 'rgba(0, 0, 0, 0.23)'
  )};
  }
`;
