import styled from 'styled-components';

type CardProps = {
  width?: string;
  height?: string;
  padding?: string;
};

export const Container = styled.div<CardProps>`
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: ${(props: CardProps) => props.width || 'auto'};
  height: ${(props: CardProps) => props.height || 'auto'};
  padding: ${(props: CardProps) => props.padding || '15px'};;
  box-shadow: 0px 3px 6px #0000001A;
  border: 1px solid #E1E1E1;
  border-radius: 20px;
  opacity: 1;
`;
