import React, { useState, useContext, useEffect } from 'react';
import {
  Radio, RadioGroup, FormControlLabel, FormControl,
} from '@material-ui/core';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { IoClose } from 'react-icons/io5';

import {
  GeneralCard,
  Button,
  OutlinedInput,
  TypedInput,
  Modal,
} from '../../../../components';

import ChoosePrizeModal from './ChoosePrizeModal';

import { campaignDataContext, ChosenGoal, Prize } from '../../../../hooks/campaignContext';
import { ArrowRight } from '../../../../assets/icons';
import theme from '../../../../assets/styles/theme';
import {
  StepContainer,
  CardTitle,
  ProductSection,
  PrizeSection,
  ProductDetails,
  ProductID,
  SectionTitle,
  SectionDescription,
  PrizeSectionDescription,
  SectionColumn,
  ModalitySwitch,
  InputTitle,
  PrizeBox,
  PrizeSelection,
  CreateIcon,
  PlusIcon,
  EditContainer,
  PrizeUnderText,
  ValueInput,
  RegistredGoal,
  RegistredGoalText,
  GoalInfo,
  FlexBox,
  IconContainer,
  ModalContainer,
  SecondIconContainer,
  ContentContainer,
  ModalContent,
  PlusButton,
  TeamNamesContainer,
  TeamName,
  CloseButton,
} from './style';

type StepProps = {
  current: number,
  SetCurrent: (current: number) => void;
}

const Goals: React.ElementType = ({
  current,
  SetCurrent,
}: StepProps) => {
  const { campaign, goal, prize } = useContext(campaignDataContext);
  const [show, setShow] = useState<boolean>(false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'productCode') {
      goal.setProductIdType(event.target.value);
    }
  };

  const [firstPrizePrice, setFirstPrizePrice] = useState<number>(-1);
  const [secondPrizePrice, setSecondPrizePrice] = useState<number>(-1);
  const [thirdPrizePrice, setThirdPrizePrice] = useState<number>(-1);
  const [newCode, setNewCode] = useState<string>('');
  const prizesPrices = [firstPrizePrice, secondPrizePrice, thirdPrizePrice];
  const setPrizesPrices = [setFirstPrizePrice, setSecondPrizePrice, setThirdPrizePrice];

  const [modal, setModal] = useState<boolean>(false);

  const openChoosePrize = (percentage: number, showPrize: Prize | boolean) => {
    if (prize.setPercentageNeeded && !showPrize) {
      setShow(true);
      if (percentage === 20 || percentage === 50 || percentage === 100) {
        prize.setPercentageNeeded(percentage);
      }
    }
  };

  const cleanGoal = () => {
    if (goal.setProductName) goal.setProductName('');
    if (goal.setProductDescription) goal.setProductDescription('');
    if (goal.setUnityPrice) goal.setUnityPrice(-1);
    if (goal.setUnityQuantity) goal.setUnityQuantity(-1);
    if (goal.setProductCode) goal.setProductCode(['']);
    if (goal.setPointValue) goal.setPointValue(-1);
    if (goal.setPrizes) goal.setPrizes([] as Prize[]);
    if (prize.setEachSalePrize) prize.setEachSalePrize(-1);
  };

  const openModal = () => {
    setModal(true);
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <>
      <StepContainer>
        <div style={{ maxWidth: '1225px', width: '85%' }}>
          <CardTitle>Cadastrar meta por participante</CardTitle>
          <GeneralCard height="950px" padding="10px">
            <IconContainer onClick={() => openModal()}>
              <AiOutlineQuestionCircle size="20px" color="#6D34DF" />
            </IconContainer>

            <ProductSection>
              <ProductDetails>
                <SectionTitle big>Detalhes do item</SectionTitle>

                <SectionColumn height="55%" margin="15px 0">
                  <div>
                    <OutlinedInput
                      type="alphanumeric"
                      title="Nome"
                      value={goal.productName}
                      setValue={goal.setProductName}
                      required
                    />
                  </div>
                  <div>
                    <OutlinedInput
                      type="alphanumeric"
                      title="Descrição"
                      multiline
                      lineRows={2}
                      value={goal.productDescription}
                      setValue={goal.setProductDescription}
                    />
                  </div>
                  <div style={{ height: '66px' }}>
                    <TypedInput
                      value={goal.unityPrice !== -1 ? goal.unityPrice : ''}
                      placeholder="Preço"
                      setValue={goal.setUnityPrice}
                      categoryText="R$"
                      required
                    />
                  </div>
                </SectionColumn>

                <SectionColumn height="66px" margin="5px 0">
                  <SectionTitle big={false}>Meta de venda</SectionTitle>
                  <SectionDescription style={{ padding: '7px 0' }}>
                    É a quantidade de itens a ser vendida pelo participante.
                  </SectionDescription>

                  <div>
                    <TypedInput
                      value={goal.unityQuantity !== -1 ? goal.unityQuantity : ''}
                      setValue={goal.setUnityQuantity}
                      required
                      type="integer"
                    />
                  </div>
                </SectionColumn>

              </ProductDetails>

              <ProductID>
                <SectionTitle big>Identificação do item</SectionTitle>
                <div style={{ height: '51%' }}>
                  <SectionColumn height="35%" margin="5px 0">
                    <div style={{ marginBottom: '3px' }}>
                      <SectionDescription>
                        Numeração geralmente atribuída pela empresa
                      </SectionDescription>
                    </div>
                    <div style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      gap: 15,
                    }}
                    >
                      <OutlinedInput
                        type="alphanumeric"
                        title="Código do item"
                        value={
                          newCode
                        }
                        setValue={
                          setNewCode
                        }
                        required
                      />
                      <PlusButton onClick={() => {
                        const code = newCode;
                        if (newCode !== '') {
                          goal.setProductCode([
                            ...goal.productCode,
                            code,
                          ]);
                          setNewCode('');
                        }
                      }}
                      />
                    </div>
                  </SectionColumn>

                  <TeamNamesContainer>
                    {goal.productCode.slice(1).map((code) => (
                      <TeamName>
                        <div style={{ display: 'flex', justifyContent: 'center', marginRight: '5px' }}>
                          {code}
                        </div>
                        <CloseButton onClick={() => {
                          goal.setProductCode([
                            ...goal.productCode.filter((value) => code !== value),
                          ]);
                        }}
                        />
                      </TeamName>
                    ))}
                  </TeamNamesContainer>
                </div>
                {goal.modalityChoice === 'prize' && (
                  <SectionColumn height="28%" margin="45px 0 0 0">
                    <SectionTitle big={false}>Pontos x Valor(R$)</SectionTitle>

                    <SectionDescription style={{ padding: '8px 0' }}>
                      É a paridade para alimentar a mecânica do sistema.
                    </SectionDescription>

                    <div style={{ height: '66px' }}>
                      <TypedInput
                        value={goal.pointValue !== -1 ? goal.pointValue : ''}
                        setValue={goal.setPointValue}
                        type="quantity"
                        required
                      />
                    </div>
                  </SectionColumn>
                )}

              </ProductID>

            </ProductSection>

            <PrizeSection>

              <SectionTitle big>Prêmiações</SectionTitle>
              <PrizeSectionDescription style={{ margin: '15px 0 30px 0' }}>
                Ao final da campanha você terá a opção de enviar as premiações obtidas para a
                equipe Ramp-Up fazer a entrega.
              </PrizeSectionDescription>

              <div style={{ display: 'flex' }}>
                <ModalitySwitch
                  style={{ marginRight: '3%' }}
                  value={goal.modalityChoice}
                  expectedValue="prize"
                  onClick={() => goal.setModalityChoice('prize')}
                >
                  Modalidade prêmios
                </ModalitySwitch>

                <ModalitySwitch
                  value={goal.modalityChoice}
                  expectedValue="bySale"
                  onClick={() => goal.setModalityChoice('bySale')}
                >
                  Modalidade venda por unidade
                </ModalitySwitch>
              </div>

              <div style={{ height: '48%' }}>
                {goal.modalityChoice === 'prize'
                  && (
                    <PrizeSelection>
                      {[20, 50, 100].map((percentage, index) => {
                        const showPrize = goal.prizes.filter(
                          (chosenPrize) => chosenPrize.percentageNeeded === percentage,
                        )[0];

                        if (showPrize?.price && prizesPrices[index] === -1) {
                          setPrizesPrices[index](showPrize.price);
                        }

                        return (
                          <>
                            <PrizeBox
                              prizeName={showPrize?.name}
                              onClick={() => openChoosePrize(percentage, showPrize)}
                            >
                              <div style={{
                                display: 'flex', flexDirection: 'column', alignItems: 'center', width: '80%',
                              }}
                              >
                                <EditContainer onClick={() => openChoosePrize(percentage, false)}>
                                  <CreateIcon />
                                  <PrizeUnderText>Editar</PrizeUnderText>
                                </EditContainer>

                                <SectionTitle big={false}>
                                  Se alcançar
                                  {' '}
                                  {percentage}
                                  %
                                </SectionTitle>
                              </div>
                              {!showPrize && (
                                <>
                                  <PlusIcon />
                                  <PrizeUnderText color="#605F5F">Selecionar prêmio</PrizeUnderText>
                                </>
                              )}

                              {showPrize && (
                                <>
                                  <PrizeUnderText color="#605F5F">Selecionado</PrizeUnderText>
                                  <PrizeUnderText color={theme.colors.mainBlack}>
                                    {showPrize.name}
                                  </PrizeUnderText>
                                  <ValueInput>
                                    <TypedInput
                                      small
                                      value={prizesPrices[index] !== -1 ? prizesPrices[index] : ''}
                                      setValue={setPrizesPrices[index]}
                                      required
                                      categoryText="Valor"
                                    />
                                  </ValueInput>
                                </>
                              )}

                            </PrizeBox>

                            <ChoosePrizeModal
                              show={show}
                              setShow={setShow}
                              percentageNeeded={prize.percentageNeeded}
                            />
                          </>
                        );
                      })}
                    </PrizeSelection>
                  )}

                {goal.modalityChoice === 'bySale'
                  && (
                    <div>
                      <PrizeSectionDescription style={{ margin: '40px 0 25px 0' }}>
                        Digite o valor que o participante ganhará na venda de 1 unidade. No final da
                        campanha ele ganhará esse valor multiplicado pela quantidade de vendas desse
                        item
                      </PrizeSectionDescription>

                      <InputTitle>Digite o valor ganho na venda de 1 item</InputTitle>
                      <div style={{ width: '40%', marginTop: '20px', height: '66px' }}>
                        <TypedInput
                          value={prize.eachSalePrize !== -1 ? prize.eachSalePrize : ''}
                          setValue={prize.setEachSalePrize}
                          categoryText="R$"
                          required
                        />
                      </div>
                    </div>
                  )}
              </div>

              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  text="Adicionar nova meta"
                  backgroundColor={theme.colors.lighterViolet}
                  hoverBackground="#4A4797"
                  hoverTextColor={theme.colors.bodyWhite}
                  borderRadius={theme.buttons.borderRadius.medium}
                  width={theme.buttons.width.large}
                  fontSize="13px"
                  height={theme.buttons.height.medium}
                  textColor={theme.colors.bodyWhite}
                  onClick={() => {
                    let numberInPrizeInput = true;
                    let updatedGoals: Prize[] | null = null;
                    if (goal.modalityChoice === 'prize' && goal.prizes.length > 0) {
                      if (!prizesPrices.some((chosenPrice) => Number.isNaN(chosenPrice))) {
                        updatedGoals = (goal.prizes.map((chosenPrize) => {
                          if (chosenPrize.percentageNeeded === 20) {
                            return { ...chosenPrize, price: prizesPrices[0] };
                          } if (chosenPrize.percentageNeeded === 50) {
                            return { ...chosenPrize, price: prizesPrices[1] };
                          }
                          return { ...chosenPrize, price: prizesPrices[2] };
                        }));
                      } else {
                        numberInPrizeInput = false;
                      }
                    }
                    const goalData = {
                      productName: goal.productName,
                      productDescription: goal.productDescription,
                      unityPrice: Number(goal.unityPrice) ? Number(goal.unityPrice) : -1,
                      unityQuantity: Number(goal.unityQuantity) ? Number(goal.unityQuantity) : -1,
                      pointValue: goal.modalityChoice === 'prize' && Number(goal.pointValue)
                        ? Number(goal.pointValue) : undefined,
                      eachSalePrize: goal.modalityChoice === 'bySale' && Number(prize.eachSalePrize)
                        ? Number(prize.eachSalePrize) : undefined,
                      prizes: goal.modalityChoice === 'prize' && numberInPrizeInput && updatedGoals ? updatedGoals : undefined,
                      productCode: goal.productCode,
                    };
                    const filteredGoal = Object.values(goalData).filter(
                      (value: undefined | string | number | Prize[]| string[]) => (
                        value !== '' && value !== -1 && value !== undefined),
                    );
                    if (campaign.goals.some(
                      (obj) => JSON.stringify(obj) === JSON.stringify(goalData),
                    )) {
                      alert('Uma meta não pode ser idêntica a uma já existente.');
                    } else if ((filteredGoal.length >= 6 && goal.modalityChoice === 'prize')
                      || (filteredGoal.length >= 5 && goal.modalityChoice === 'bySale')) {
                      campaign.setGoals([...campaign.goals, goalData]);
                      cleanGoal();
                    } else {
                      alert('Preencha todos os campos corretamente.');
                    }
                  }}
                />
              </div>

            </PrizeSection>

          </GeneralCard>
        </div>

        <div style={{
          display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '40%',
        }}
        >
          <Button
            text="PROSSEGUIR"
            backgroundColor={theme.colors.bodyWhite}
            hoverBackground="#EFEFEF"
            hoverTextColor="#363636"
            border={`2px solid ${theme.colors.mainGray}`}
            borderRadius={theme.buttons.borderRadius.medium}
            width="165px"
            height={theme.buttons.height.medium}
            textColor={theme.colors.mainGray}
            iconRight={<ArrowRight />}
            onClick={() => {
              if (campaign.goals.length > 0) {
                SetCurrent(current + 1);
              } else {
                alert('Crie pelo menos uma meta para sua campanha.');
              }
            }}
          />
          <SectionTitle
            big={false}
            style={{ color: `${theme.colors.secondaryGray}`, margin: '50px 0 20px 0' }}
          >
            Metas cadastradas
          </SectionTitle>

          <div style={{
            height: '850px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
          >
            {campaign.goals.map((chosenGoal: ChosenGoal) => (
              <RegistredGoal>
                <section>
                  <SectionTitle big style={{ fontWeight: 'bold' }}>{chosenGoal.productName}</SectionTitle>
                  <RegistredGoalText>{chosenGoal.productDescription}</RegistredGoalText>
                </section>

                <FlexBox style={{ height: '60%', justifyContent: 'space-around', flexDirection: 'column' }}>
                  <FlexBox>
                    <GoalInfo bold>Quantidade |</GoalInfo>
                    <GoalInfo bold={false}>{`${chosenGoal.unityQuantity} unidades`}</GoalInfo>
                  </FlexBox>
                  {chosenGoal.pointValue && (
                    <FlexBox>
                      <GoalInfo bold>Por item |</GoalInfo>
                      <GoalInfo bold={false}>{`${chosenGoal.pointValue} pontos`}</GoalInfo>
                    </FlexBox>
                  )}
                  <FlexBox>
                    <GoalInfo style={{ whiteSpace: 'nowrap' }} bold>Meta final por participante |</GoalInfo>
                    <GoalInfo
                      style={{
                        whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', maxWidth: '160px',
                      }}
                      bold={false}
                    >
                      {chosenGoal?.pointValue ? `${chosenGoal.pointValue * chosenGoal.unityQuantity} pontos`
                        : `R$ ${(chosenGoal.unityPrice * chosenGoal.unityQuantity).toLocaleString('pt-BR')}`}
                    </GoalInfo>
                  </FlexBox>
                  <FlexBox>
                    <GoalInfo style={{ whiteSpace: 'nowrap' }} bold>Premiações |</GoalInfo>
                    <GoalInfo bold={false} style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                      {chosenGoal.prizes
                        ? chosenGoal.prizes.map((chosen) => chosen.name).join(', ')
                        : `R$ ${chosenGoal.eachSalePrize} por venda`}

                    </GoalInfo>
                  </FlexBox>
                  {!chosenGoal?.pointValue && (
                    <FlexBox />
                  )}
                </FlexBox>
              </RegistredGoal>
            ))}
          </div>
        </div>

        <Modal showModal={modal}>
          <ModalContainer>
            <SecondIconContainer onClick={closeModal}>
              <IoClose size="25px" />
            </SecondIconContainer>
            <ModalContent>
              <ContentContainer>
                <SectionTitle big style={{ fontSize: '17px', fontWeight: 'bold', marginBottom: '5px' }}>Nome:</SectionTitle>
                <SectionDescription style={{ fontSize: '14px' }}>
                  Você deverá inserir o nome do produto.
                  Ele será exibido para os vendedores dentro da campanha de incentivo.
                </SectionDescription>
              </ContentContainer>
              <ContentContainer>
                <SectionTitle big style={{ fontSize: '17px', fontWeight: 'bold', marginBottom: '5px' }}>Descrição:</SectionTitle>
                <SectionDescription style={{ fontSize: '14px' }}>
                  Você deverá inserir a descrição referente ao produto.
                  Ela será exibida para detalhar o produto que está sendo vendido na campanha.
                </SectionDescription>
              </ContentContainer>
              <ContentContainer>
                <SectionTitle big style={{ fontSize: '17px', fontWeight: 'bold', marginBottom: '5px' }}>Preço:</SectionTitle>
                <SectionDescription style={{ fontSize: '14px' }}>
                  Você deverá colocar o preço do produto referente à uma unidade do mesmo.
                </SectionDescription>
              </ContentContainer>
              <ContentContainer>
                <SectionTitle big style={{ fontSize: '17px', fontWeight: 'bold', marginBottom: '5px' }}>Meta de venda:</SectionTitle>
                <SectionDescription style={{ fontSize: '14px' }}>
                  Você deverá inserir a quantidade de produtos que será vendida
                  dentro da campanha de incentivo por cada participante.
                </SectionDescription>
              </ContentContainer>
              <ContentContainer>
                <SectionTitle big style={{ fontSize: '17px', fontWeight: 'bold', marginBottom: '5px' }}>Identificação do item:</SectionTitle>
                <SectionDescription style={{ fontSize: '14px' }}>
                  Você deverá selecionar que o produto que será vendido irá ser verificado.
                  Após escolher, você deverá cadastrar os dados referentes ao
                  produto no campo especificado.
                </SectionDescription>
              </ContentContainer>
              <ContentContainer>
                <SectionTitle big style={{ fontSize: '17px', fontWeight: 'bold', marginBottom: '5px' }}>Pontuação e Valoração:</SectionTitle>
                <SectionDescription style={{ fontSize: '14px' }}>
                  Você deverá inserir o valor que será referente á 1 ponto na campanha.
                  Ex: Se você inserir R$ 0,50,  1 ponto será gerado a cada R$ 0,50 vendido
                </SectionDescription>
              </ContentContainer>
            </ModalContent>
          </ModalContainer>
        </Modal>
      </StepContainer>
    </>

  );
};
export default Goals;
