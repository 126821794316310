/* eslint-disable react/require-default-props */
/* eslint-disable indent */
import { useEffect, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import Check from '@material-ui/icons/Check';
import Close from '@material-ui/icons/Close';

import {
  Input,
} from './style';

export interface OutlinedInputProps {
  type: string;
  title: string;
  small?: boolean;
  value?: string;
  placeholder?: string;
  required: boolean;
  allowError?: boolean;
  errorMessage?: string;
  setValue: React.Dispatch<React.SetStateAction<string>>,
  multiline?: boolean;
  lineRows?: number;
  onChange: Function;
  suggestionList?: boolean;
}

const OutlinedInput: React.ElementType = ({
  type,
  title,
  value,
  small,
  placeholder,
  setValue,
  required,
  allowError,
  errorMessage,
  multiline,
  lineRows,
  onChange,
  suggestionList,
}: OutlinedInputProps) => {
  const [check, setCheck] = useState<boolean | null>(null);
  const [error, setError] = useState<boolean>(!!errorMessage);
  const [errorText, setErrorText] = useState<string | null>(errorMessage || null);

  const checkInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === '') {
      setError(false);
      setErrorText(null);
      setCheck(null);
      setValue('');
      return;
    }

    if (allowError === false) {
      setErrorText(null);
      setCheck(null);
      setValue(event.target.value);
      return;
    }

    const checkResult = checkCharacters(event);
    setValue(event.target.value);
    setCheck(!checkResult ? null : true);
    if (checkResult) {
      setError(false);
      setErrorText(null);
    }
  };

  const regExpTypes = [
    { type: 'alpha', regExp: /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ#]+/ },
    { type: 'integer', regExp: /^[0-9]*$/ },
    { type: 'numeric', regExp: /^[0-9]+(,[0-9]{1,2})?$/ },
    // { type: 'alphanumeric', regExp: /^[a-z A-Z à-ú À-Ú 0-9 .,!"'/$?:;/#[{}]]*$/ },
    { type: 'alphanumeric', regExp: /./ },
    { type: 'password', regExp: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}/ },
    { type: 'telephone', regExp: /[1-9]{2}[0-9]{4,5}[0-9]{4}/ },
    { type: 'email', regExp: /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*/ },
  ];

  const checkCharacters = (event: React.ChangeEvent<HTMLInputElement>): boolean => {
    const regExpType = regExpTypes.find((item) => item.type === type);
    const match = regExpType?.regExp.test(event.target.value);

    switch (type) {
      case 'alpha':
        setErrorText(!match ? 'Apenas letras são permitidas' : null);
        break;

      case 'numeric':
        setErrorText(!match ? 'Apenas números são permitidos' : null);
        break;

      case 'integer':
        setErrorText(!match ? 'Apenas números inteiros são permitidos' : null);
        break;

      case 'alphanumeric':
        setErrorText(null);
        // (!match ? 'Apenas letras, números e algumas pontuações são permitidas' : null);
        break;

      case 'password':
        setErrorText(!match ? 'Mínimo de 8 caracteres, sendo 1 maiúscula, 1 minúscula, 1 número e 1 caractere especial.' : null);
        break;

      case 'telephone':
        setErrorText(!match ? 'Apenas 10 ou 11 caracteres, sendo DDD + número de telefone.' : null);
        break;

      case 'email':
        setErrorText(!match ? 'Email inválido' : null);
        break;

      default:
        break;
    }
    setError(!match);

    return match || false;
  };

  useEffect(() => {
    if (errorMessage) {
      setError(true);
      setErrorText(errorMessage);
    }
  }, [errorMessage]);

  return (
    <>
      <Input
        size={small ? 'small' : 'medium'}
        type={type === 'password' ? type : 'text'}
        check={check}
        variant="outlined"
        multiline={multiline}
        rows={multiline ? (lineRows || 7) : 1}
        label={title}
        placeholder={placeholder}
        value={value}
        required={required}
        error={error}
        helperText={errorText}
        onChange={suggestionList ? (e) => onChange(e)
          : (e: React.ChangeEvent<HTMLInputElement>) => checkInput(e)}
        InputProps={{
          endAdornment: value && (
            <InputAdornment position="end">
              {error ? <Close color="error" fontSize="small" /> : <Check htmlColor="#06A151" fontSize="small" />}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default OutlinedInput;
