import React from 'react';
import { Snackbar } from '@mui/material';

import MuiAlert from '@mui/material/Alert';

interface ToastProps {
  type: 'error' | 'info' | 'success' | 'warning';
  title: string;
  message: string;
  open: boolean;
  setOpen: (bool: boolean) => void;
}

const Toast: React.ElementType = ({
  type,
  title,
  message,
  open,
  setOpen,
}: ToastProps) => (
  <>
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={() => setOpen(false)}
        severity={type}
        sx={{ width: '100%' }}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  </>
);

export default Toast;
