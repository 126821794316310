import {
  GoalInfo,
  GoalText,
  GoalQuantity,
  CardsTitle,
  PrizeCard,
  PrizeDescription,
  PrizePercentage,
  PrizeCardsContainer,
} from './style';

type Prize = {
  percentageNeeded?: number;
  name?: string;
}

type Goal = {
  productName: string;
  unityQuantity: number;
  productCode: string[];
  prizes?: Prize[];
  eachSalePrize?: number;
  unityPrice?: number;
}

type GoalsAndPrizesProps = {
  goals: Goal[];
}

const getDescription = (prize: Prize): string => {
  if (prize.name?.includes('PocketCard')) {
    return 'Cartão com função crédito para comprar em milhares de estabelecimentos.';
  }
  return '';
};

const GoalsAndPrizes: React.ElementType = ({
  goals,
}: GoalsAndPrizesProps) => {
  console.log(goals[0].unityPrice);

  return (
    <>
      <CardsTitle>Dados da meta</CardsTitle>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

        {goals.map((goal) => (
          <div style={{ display: 'flex', padding: '10px 0' }}>

            <GoalInfo>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                { goal.productCode.map((code) => (
                  <GoalText>
                    { code }
                  </GoalText>
                ))}
              </div>
              <GoalText style={{ fontWeight: 'bold' }}>
                Vender
                {' '}
                {goal.productName}
              </GoalText>
              <div style={{ display: 'flex' }}>
                <GoalQuantity style={{ color: '#5A5A5A' }}>Qtd.</GoalQuantity>
                <GoalQuantity>
                  {goal.unityQuantity}
                  {' '}
                  unidades
                </GoalQuantity>
              </div>
            </GoalInfo>

            <PrizeCardsContainer>
              {!goal.eachSalePrize && goal.prizes?.map((prize: Prize) => (
                <PrizeCard key={prize.name}>
                  <GoalText style={{ fontWeight: 'bold' }}>{prize.name}</GoalText>
                  <PrizeDescription>{getDescription(prize)}</PrizeDescription>
                  <div style={{ display: 'flex', alignItems: 'baseline' }}>
                    <PrizePercentage>
                      {prize.percentageNeeded}
                      %
                    </PrizePercentage>
                    <GoalQuantity style={{ paddingLeft: '5px' }}>da meta :</GoalQuantity>
                    <PrizePercentage>
                      R$
                      {prize.percentageNeeded && goal.unityPrice ? ((prize.percentageNeeded / 100) * goal.unityPrice).toFixed() : '0'}
                    </PrizePercentage>
                  </div>
                </PrizeCard>
              ))}
              {goal.eachSalePrize && (
                <PrizeCard>
                  <GoalText style={{ fontWeight: 'bold', fontSize: '14px' }}>
                    Valor ganho na venda de um item:
                  </GoalText>
                  <PrizePercentage>
                    R$
                    {goal.eachSalePrize.toLocaleString('pt-BR')}
                  </PrizePercentage>
                </PrizeCard>
              )}
            </PrizeCardsContainer>
          </div>
        ))}

      </div>
    </>
  );
};

export default GoalsAndPrizes;
