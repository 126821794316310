import styled from 'styled-components';
import theme from '../../assets/styles/theme';

export const LoginPage = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: ${theme.colors.bodyWhite};
`;

export const LoginContainerBorder = styled.div`
  width: 380px;
  height: auto;
  background: transparent linear-gradient(175deg, #7136E8 80%, #D71352 120%) 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  padding: 7px;
  margin-bottom: 35px;

  @media(max-width: 500px) {
    width: 280px;
  };
`;

export const LoginContainer = styled.div`
  width: 100%;
  height: 370px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: ${theme.colors.bodyWhite};
  border: 1px solid #E1E1E1;
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: ${theme.card.borderRadius.large};
  opacity: 1;

  @media(max-width: 500px) {
    height: 300px;
  };
`;

export const LoginTitle = styled.p`
  font-size: 26px;
  font: 500 25px Poppins, sans-serif;
  color: ${theme.colors.lighterViolet};
  padding-bottom: 40px;

  @media(max-width: 500px) {
    font: 500 20px Poppins, sans-serif;
    padding-bottom: 25px;
  };
`;

export const InputContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding-bottom: 23px;
  
  @media(max-width: 500px) {
    padding-bottom: 18px;
    gap: 7px;
  };
`;

export const InputTitle = styled.p`
  font: ${theme.fonts.secondary.h3};
  color: ${theme.colors.mainGray};
  
  @media(max-width: 500px) {
    font: ${theme.fonts.secondary.small};
  };
`;

export const ForgotPasswordText = styled.p`
  font: ${theme.fonts.secondary.body};
  color: ${theme.colors.mainGray};
  text-decoration: underline;
  align-self: flex-start;
  cursor: pointer;
  
  @media(max-width: 500px) {
    font: 400 10px Roboto,sans-serif;
  };
`;

export const AuxiliarTextOne = styled.p`
  font: ${theme.fonts.primary.h2};
  color: #6E6E6E;

  @media(max-width: 500px) {
    font: ${theme.fonts.primary.body};
  };
`;

export const AuxiliarTextTwo = styled.p`
  font: ${theme.fonts.primary.h2};
  color: #6E6E6E;
  font: 550 18px Poppins, sans-serif;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 7px;

  @media(max-width: 500px) {
    font: ${theme.fonts.primary.body};
  };
`;

export const ModalBody = styled.div`
  display: flex;
  font: ${theme.fonts.primary.h3};
  color: ${theme.colors.mainBlack};
  font-weight: 600;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const GenericContainer = styled.div`
`;

export const PasswordModalText = styled.p`
  font: ${theme.fonts.secondary.h2};
  color: ${theme.colors.mainBlack};
`;

export const ModalContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
