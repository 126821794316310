import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

export const SelectFormControl = styled(FormControl)`
  width: 177px;
  height:45px;
  text-align: start;

  option{
    border-radius: 23px;
  }

  .MuiSelect-select:focus{
    background-color: transparent;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #7136E8;
    border-width: 2px;
  }

  .MuiInputLabel-outlined {
    transform: translate(28px, 11px) scale(1);
  }

  .MuiFormControl-root {
    border-radius: 23px;
  }
  
  .MuiOutlinedInput-root {
    border-radius: 23px;
  }
  .MuiSelect-outlined {
    border-radius: 23px;
  }

  .MuiOutlinedInput-input {
    padding: 11px 28px 13px 25px;
  }

  .MuiFormLabel-root {
    font: normal normal normal 16px/21px Roboto; 
    color: #707070;
  }
  
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }

  .PrivateNotchedOutline-legendNotched-4 {
    display: none;
  }

  
`;

export const SelectSelect = styled(Select)`
  svg{
    width: 15.13px;
    height: 7.57px;
    margin-right:24px;
    top: calc(50% - 4px);
  }
  transform-origin: 60px 0px !important ;
`;

export const SelectMenuItem = styled(MenuItem)`

`;

export const SelectInputLabel = styled(InputLabel)`

`;
