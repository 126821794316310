import styled from 'styled-components';
import { Button } from '@material-ui/core';

import theme from '../../assets/styles/theme';

type ButtonProps = {
  width?: string;
  height?: string;
  backgroundColor?: string;
  border?: string;
  borderRadius?: string;
  boxShadow?: string;
  textColor?: string;
  fontSize?: string;
  hoverBackground?: string;
  hoverTextColor?: string;
  hoverBorder?: string;
  isNewCampaignButton?: boolean;
  subText?: string;
  alinText?:string;
}

export const MaterialButton = styled(Button) <ButtonProps>`
  width: ${(props: ButtonProps) => props.width || `${theme.buttons.width.medium}`};
  height: ${(props: ButtonProps) => props.height || `${theme.buttons.height.small}`};
  background-color: ${(props: ButtonProps) => props.backgroundColor || `${theme.colors.mainWhite}`};
  border: ${(props: ButtonProps) => props.border || 0};
  border-radius: ${(props: ButtonProps) => props.borderRadius || '10px'};
  box-shadow: ${(props: ButtonProps) => props.boxShadow || '0px 2px 6px #0000004D'};
  color: ${(props: ButtonProps) => props.textColor || `${theme.colors.mainGray}`};
  text-transform: none;
  font: ${theme.fonts.primary.body};
  font-size: ${(props: ButtonProps) => props.fontSize || '12px'};

  font-weight: ${(props: ButtonProps) => props.subText && 'bold'};

  .MuiButton-label{
    width: ${(props: ButtonProps) => props.subText && '95%'};
    height: ${(props: ButtonProps) => props.subText && '90%'};
    justify-content: ${(props: ButtonProps) => props.subText && 'space-between'};
  }

  
  &:hover{
    background-color: ${(props: ButtonProps) => props.hoverBackground};
    color: ${(props: ButtonProps) => props.hoverTextColor};
    border: ${(props: ButtonProps) => props.hoverBorder};
  }

  svg {
    margin-right: ${(props: ButtonProps) => (props.isNewCampaignButton ? '10px' : 0)}
  }
`;

export const SmallerText = styled.p`
  font-weight: normal;
  color: ${theme.colors.mainGray};
  font: ${theme.fonts.primary.small};
  font-size: 11px;
  text-align: ${(props: ButtonProps) => props.alinText || 'left'};
`;

export const ButtonText = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: flex-start;
`;
