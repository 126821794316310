import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Settings,
  Campaign,
  FinishedCampaign,
  CampaignListing,
  Login,
  CreateCampaign,
  EditCampaign,
  ResetPassword,
  Team,
} from '../pages';
import PrivateRoute from './PrivateRoute';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/login" component={Login} />
    <PrivateRoute path="/configuracoes" component={Settings} isPrivate />
    <PrivateRoute path="/minhas-campanhas" component={CampaignListing} isPrivate />
    <PrivateRoute path="/campanha/:code/time/:teamCode" component={Team} isPrivate />
    <PrivateRoute path="/campanha/:code/finalizada" component={FinishedCampaign} isPrivate />
    <PrivateRoute path="/campanha/:code" component={Campaign} isPrivate />
    <PrivateRoute path="/criar-campanha" component={CreateCampaign} isPrivate />
    <PrivateRoute path="/editar-campanha/:code" component={EditCampaign} isPrivate />
    <Route path="/esqueci-minha-senha/:token" component={ResetPassword} />
    <Route exact path="/">
      <Redirect to="/login" />
    </Route>
  </Switch>
);
export default Routes;
