import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import Skeleton from '@mui/material/Skeleton';
import useAxios from '../../utils/useAxios';
import { useAuth } from '../../hooks/Auth';

import {
  Navbar,
  Header,
  Button,
  WarningUi,
  Toast,
} from '../../components';

import {
  PurpleCircle,
  Upward,
} from '../../assets/icons';

import {
  SettingsInfo,
  SettingsSection,
  SettingsTitle,
  Container,
  SettingsPage,
  FieldTitle,
  FieldInfo,
  CompleteField,
  CreatedIn,
  Drawing,
} from './style';

import theme from '../../assets/styles/theme';

type Manager = {
  cnpj: string,
  corporateName: string,
  email: string,
  name: string,
  phoneNumber: string,
  role: string,
  password: string,
  createdAt: string,
}

const Settings: React.FC = () => {
  const { user } = useAuth();
  const [axiosGet] = useAxios('get');
  const [managerData, setManagerData] = useState<Manager | null>(null);
  const [show, SetShow] = useState(false);
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const close = () => {
    SetShow(false);
  };

  const open = () => {
    SetShow(true);
  };

  const ErrorToast = () => (
    <Toast
      type="error"
      message={errorMessage}
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  useEffect(() => {
    const getManager = async () => {
      await axiosGet({
        url: `manager/${user.manager}?get=true`,
        success: (res: AxiosResponse) => {
          const { manager } = res.data.data;
          setErrorToast(false);
          setErrorMessage(null);
          setManagerData(manager);
          setTimeout(() => setLoading(false), 500);
        },
        error: (err: any) => {
          setErrorMessage(err.response.data.message);
          setErrorToast(true);
          setTimeout(() => setLoading(false), 500);
        },
      });
    };

    getManager();
  }, [axiosGet, user.manager]);

  return (
    <>
      {ErrorToast()}
      <Container>
        <SettingsPage>
          <Navbar />
          <Header
            title="Configurações"
            onClickReturn={() => { window.location.href = '../minhas-campanhas'; }}
          >
            <Button
              text="Editar"
              backgroundColor={theme.colors.bodyWhite}
              hoverBackground={theme.colors.bodyWhite}
              width={theme.buttons.width.small}
              height={theme.buttons.height.small}
              textColor="#191919"
              borderRadius={theme.buttons.borderRadius.medium}
              iconLeft={<Upward />}
              border={`2px solid ${theme.colors.mainGray}`}
              onClick={open}
            />
          </Header>
          <SettingsSection>
            <SettingsTitle>Perfil</SettingsTitle>

            <SettingsInfo>
              <CompleteField>
                <FieldTitle>Razão Social</FieldTitle>
                {loading ? (<Skeleton variant="text" width={200} height={29} />)
                  : (<FieldInfo>{managerData?.corporateName}</FieldInfo>)}
              </CompleteField>

              <CompleteField>
                <FieldTitle>Telefone</FieldTitle>
                {loading ? (<Skeleton variant="text" width={200} height={29} />)
                  : (<FieldInfo>{managerData?.phoneNumber || 'Não cadastrado'}</FieldInfo>)}
              </CompleteField>

              <CompleteField>
                <FieldTitle>CNPJ</FieldTitle>
                {loading ? (<Skeleton variant="text" width={200} height={29} />)
                  : (<FieldInfo>{managerData?.cnpj}</FieldInfo>)}
              </CompleteField>

              <CompleteField>
                <FieldTitle>E-mail</FieldTitle>
                {loading ? (<Skeleton variant="text" width={200} height={29} />)
                  : (<FieldInfo>{user.email}</FieldInfo>)}
              </CompleteField>

              <CompleteField>
                <FieldTitle>Nome do representante</FieldTitle>
                {loading ? (<Skeleton variant="text" width={200} />)
                  : (<FieldInfo>{managerData?.name}</FieldInfo>)}
              </CompleteField>

              <CompleteField>
                <FieldTitle>Senha</FieldTitle>
                {loading ? (<Skeleton variant="text" width={200} height={29} />)
                  : (<FieldInfo>{'*'.repeat(8)}</FieldInfo>)}
              </CompleteField>

              <CompleteField>
                <FieldTitle>Cargo</FieldTitle>
                {loading ? (<Skeleton variant="text" width={200} height={29} />)
                  : (<FieldInfo>{managerData?.role}</FieldInfo>)}
              </CompleteField>
            </SettingsInfo>

            <CreatedIn>
              Conta criada em:
              {' '}
              {managerData && new Date(managerData.createdAt).toLocaleDateString('pt-BR')}
              {' '}
              às
              {' '}
              {managerData && new Date(managerData.createdAt).toLocaleTimeString('pt-BR')}
            </CreatedIn>
          </SettingsSection>
        </SettingsPage>

        {show ? (
          <WarningUi
            open
            onClose={close}
            confirmButton={() => {
              window.open('http://www.novocred.com.br', '_blank');
              close();
            }}
          />
        ) : null}

        <Drawing src={PurpleCircle} alt="Desenho" draggable={false} />
      </Container>
    </>

  );
};

export default Settings;
