import React, { useState, useContext, ChangeEvent } from 'react';
import {
  Button,
  ModalUi,
  OutlinedInput,
  TypedInput,
  Toast,
} from '../../../../components';
import { campaignDataContext } from '../../../../hooks/campaignContext';
import { AddCircle, Upload } from '../../../../assets/icons';
import theme from '../../../../assets/styles/theme';
import {
  SectionDescription,
  PrizeModalContainer,
  PrizeModality,
  ModalCard,
  SectionTitle,
  UploadButtonDiv,
  UploadDiv,
  PictureIcon,
} from './style';

type ModalProps = {
  show: boolean,
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  percentageNeeded: 20 | 50 | 100;
}

type Prize = {
  name: string;
  price: number;
  prizePhoto?: string | File;
  setPrice?: React.Dispatch<React.SetStateAction<number>>
}

const ChoosePrizeModal: React.ElementType = ({
  show,
  setShow,
  percentageNeeded,
}: ModalProps) => {
  const { goal } = useContext(campaignDataContext);
  const [prizeType, setPrizeType] = useState<'virtual' | 'personalized'>('virtual');
  const [selectedPrize, setSelectedPrize] = useState<number>(-1);
  const [addPrize, setAddPrize] = useState<boolean>(false);
  const [prizeImage, setPrizeImage] = useState<string | File>('');
  const [prizeImagePreview, setPrizeImagePreview] = useState<string>('');
  const [prizeName, setPrizeName] = useState<string>('');
  const [prizePrice, setPrizePrice] = useState<number>(-1);

  const [errorToast, setErrorToast] = useState<boolean>(false);

  const [personalizedPrizes, setPersonalizedPrizes] = useState<Prize[]>([] as Prize[]);
  const virtualPrizes = ['PocketCard® Incentivo Mastercard', 'PocketCard® Vale-prêmios'];

  const ErrorToast = () => (
    <Toast
      type="error"
      message="Este arquivo que você tentou selecionar é muito grande. Escolha arquivos de até 5mb."
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  const handleFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const reader = new FileReader();
      const target = e.target.files[0];

      if (e.target.files[0] && e.target.files[0].size <= 5000000) {
        reader.readAsDataURL(e.target.files[0]);
      } else {
        setErrorToast(true);
      }

      reader.onloadend = () => {
        setPrizeImagePreview(String(reader?.result));
        setPrizeImage(target);
      };
    }
  };

  return (
    <>
      {ErrorToast()}
      <ModalUi
        open={show}
        onExited={() => setShow(false)}
        title="Selecionar premiação para a meta"
        width="460px"
        height="500px"
        cancelButton
        gapButtons="20px"
        description={(
          <div style={{ height: '100%' }}>
            <PrizeModalContainer>
              <div style={{ display: 'flex' }}>
                <PrizeModality
                  active={prizeType === 'virtual'}
                  onClick={() => setPrizeType('virtual')}
                >
                  Virtuais
                </PrizeModality>
                <PrizeModality
                  active={prizeType === 'personalized'}
                  onClick={() => setPrizeType('personalized')}
                >
                  Personalizados
                </PrizeModality>
              </div>
              <Button
                text="Adicionar prêmios"
                backgroundColor={theme.colors.bodyWhite}
                hoverBackground={theme.colors.bodyWhite}
                hoverTextColor={theme.colors.lightViolet}
                borderRadius={theme.buttons.borderRadius.large}
                border={`2px solid ${theme.colors.lighterViolet}`}
                width="170px"
                iconLeft={<AddCircle />}
                height={theme.buttons.height.small}
                textColor={theme.colors.lighterViolet}
                onClick={() => {
                  setShow(false);
                  setAddPrize(true);
                }}
              />
            </PrizeModalContainer>

            <div style={{ height: '280px', overflow: 'auto' }}>
              {prizeType === 'virtual'
                && virtualPrizes.map((name, index) => (
                  <ModalCard
                    index={index}
                    selected={selectedPrize}
                    onClick={() => setSelectedPrize(index)}
                  >
                    <PrizeModality style={{ fontWeight: 'bold' }} active={false}>{name}</PrizeModality>
                    <SectionDescription style={{ width: '70%' }}>
                      Cartão com função crédito para comprar em milhares de
                      estabelecimentos.
                    </SectionDescription>
                  </ModalCard>
                ))}

              {prizeType === 'personalized'
                && personalizedPrizes.map((prize: Prize, index) => (
                  <ModalCard
                    index={index}
                    selected={selectedPrize}
                    onClick={() => setSelectedPrize(index)}
                  >
                    <PrizeModality style={{ fontWeight: 'bold' }} active={false}>{prize.name}</PrizeModality>
                    <SectionDescription style={{ width: '70%' }}>
                      Valor: R$
                      {prize.price.toLocaleString('pt-BR')}
                    </SectionDescription>
                  </ModalCard>
                ))}
            </div>
          </div>
        )}
      >
        <Button
          text="Adicionar"
          backgroundColor="#210855"
          hoverBackground="#210855"
          hoverTextColor={theme.colors.bodyWhite}
          borderRadius={theme.buttons.borderRadius.medium}
          width="100px"
          height={theme.buttons.height.small}
          textColor={theme.colors.bodyWhite}
          onClick={() => {
            if (selectedPrize !== -1) {
              const filteredPrizes = goal.prizes.filter(
                (existingPrize) => (existingPrize.percentageNeeded !== percentageNeeded),
              );
              if (prizeType === 'virtual') {
                goal.setPrizes([
                  ...filteredPrizes,
                  {
                    name: virtualPrizes[selectedPrize],
                    percentageNeeded,
                  },
                ]);
              } else {
                goal.setPrizes([
                  ...filteredPrizes,
                  {
                    name: personalizedPrizes[selectedPrize].name,
                    percentageNeeded,
                    price: personalizedPrizes[selectedPrize].price,
                    prizePhoto: personalizedPrizes[selectedPrize].prizePhoto,
                  },
                ]);
              }
            }
            setShow(false);
          }}
        />
      </ModalUi>

      <ModalUi
        open={addPrize}
        onExited={() => {
          setAddPrize(false);
          setShow(true);
          setPrizeName('');
          setPrizePrice(-1);
        }}
        title={(
          <>
            Adicionar prêmios
            <SectionTitle big style={{ paddingTop: '8px' }}>Informações do prêmio</SectionTitle>
          </>
        )}
        width="460px"
        height="360px"
        cancelButton
        gapButtons="20px"
        description={(
          <div style={{ height: '170px', display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <UploadDiv imageUrl={prizeImagePreview}>
                <PictureIcon imageUrl={prizeImagePreview} />
              </UploadDiv>
              <label htmlFor="image_input">
                <UploadButtonDiv>
                  <p>Fazer upload da foto</p>
                  <Upload />
                  <input
                    id="image_input"
                    type="file"
                    accept="image/jpeg, image/jpg, image/pjpeg, image/png"
                    onChange={handleFiles}
                  />
                </UploadButtonDiv>
              </label>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ width: '190px', height: '80px' }}>
                <OutlinedInput
                  type="alphanumeric"
                  title="Nome do prêmio"
                  setValue={setPrizeName}
                  value={prizeName}
                />
              </div>
              <div style={{ width: '190px', height: '25px' }}>
                <TypedInput
                  value={prizePrice !== -1 ? prizePrice : ''}
                  placeholder="Preço"
                  setValue={setPrizePrice}
                  categoryText="R$"
                />
              </div>

            </div>

          </div>
        )}
      >
        <Button
          text="Adicionar"
          backgroundColor="#210855"
          hoverBackground="#210855"
          hoverTextColor={theme.colors.bodyWhite}
          borderRadius={theme.buttons.borderRadius.medium}
          width="100px"
          height={theme.buttons.height.small}
          textColor={theme.colors.bodyWhite}
          onClick={() => {
            if (prizeName !== '' && prizePrice > 0) {
              setPersonalizedPrizes([
                ...personalizedPrizes,
                { name: prizeName, price: prizePrice, prizePhoto: prizeImage },
              ]);
              setAddPrize(false);
              setShow(true);
              setPrizeName('');
              setPrizePrice(-1);
            }
          }}
        />
      </ModalUi>
    </>

  );
};

export default ChoosePrizeModal;
