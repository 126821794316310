/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
import { useState, useEffect } from 'react';

import {
  Input, TypedLabel, TypedLegend,
} from './style';

export interface TypedInputProps {
  type: string;
  title: string;
  small?: boolean;
  placeholder?: string;
  required: boolean;
  allowError?: boolean;
  errorMessage?: string;
  value?: number,
  setValue: React.Dispatch<React.SetStateAction<number>>;
  categoryText?: string;
}

const TypedInput: React.ElementType = ({
  type,
  setValue,
  placeholder,
  value,
  required,
  allowError,
  errorMessage,
  categoryText,
  small,
}: TypedInputProps) => {
  const [check, setCheck] = useState<boolean | null>(null);
  const [inputOnFocus, setInputOnFocus] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(!!errorMessage);
  const [errorText, setErrorText] = useState<string | null>(errorMessage || null);

  const checkCharacters = (event: React.ChangeEvent<HTMLInputElement>): boolean => {
    const number = Number(event.target.value.toString().replace(',', '.'));

    if (Number.isNaN(number)) {
      setErrorText('Você deve inserir apenas números.');
      setError(true);
      return false;
    }

    if (number < 0) {
      setErrorText('Você deve inseir um número maior que 0.');
      setError(true);
      return false;
    }

    if (type === 'integer') {
      if (!Number.isInteger(number)) {
        setErrorText('Número inválido. Você deve inserir um número inteiro.');
        setError(true);
        return false;
      }
    }

    return true;
  };

  useEffect(() => {
    if (errorMessage) {
      setError(true);
      setErrorText(errorMessage);
    }
  }, [errorMessage]);

  return (
    <>
      <Input
        check={check}
        size={small ? 'small' : 'medium'}
        padding={type === 'quantity'}
        variant="outlined"
        type={type !== 'integer' ? 'number' : ''}
        placeholder={placeholder}
        required={required}
        error={error}
        value={value}
        helperText={errorText}
        onBlur={() => setInputOnFocus(false)}
        onFocus={() => setInputOnFocus(true)}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (event.target.value.length === 0) {
            setError(false);
            setErrorText(null);
            setCheck(null);
            setValue({} as number);
            return;
          }

          if (allowError === false) {
            setErrorText(null);
            setCheck(null);
            return;
          }

          const checkResult = checkCharacters(event);
          setValue(Number(event.target.value) || 0);
          setCheck(!checkResult ? null : true);
          if (checkResult) {
            setError(false);
            setErrorText(null);
          }
        }}
        InputProps={{
          startAdornment: type === 'quantity' ? (
            <TypedLegend check={check} focus={inputOnFocus} position="start" error={error}><p>1 ponto =</p></TypedLegend>
          ) : (
            <TypedLabel check={check} focus={inputOnFocus} right={false} error={error}>
              <p style={{ marginLeft: 15, marginRight: 15 }}>{categoryText || 'Qtd.'}</p>
            </TypedLabel>
          ),
          endAdornment: type === 'quantity' ? (
            <TypedLabel check={check} focus={inputOnFocus} right error={error}>
              <p style={{ marginLeft: 10, marginRight: 10 }}>R$</p>
            </TypedLabel>
          ) : null,
        }}
      />
    </>
  );
};

export default TypedInput;
