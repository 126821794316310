/* eslint-disable no-nested-ternary */
import { AxiosError, AxiosResponse } from 'axios';
import React, { useState, useEffect } from 'react';
import theme from '../../assets/styles/theme';
import useAxios from '../../utils/useAxios';
import {
  Template,
  TemplateHeader,
  HeaderLogo,
  HeaderTitle,
  CampaignInfo,
  CampaignName,
  TeamSection,
  TeamName,
  InfoText,
  ParticipantNameSection,
  ParticipantName,
  ParticipantSection,
  SectionTitle,
  SectionItems,
  ItemText,
} from './style';

type Goal = {
  productName: string;
  unityQuantity: number;
  unityPrice: number;
  productCode: string[];
  totalSold: number;
  expectedSold: number;
  totalAmount: number;
  expectedAmount: number;
}

type Prize = {
  name: string;
  price: number;
  percentageNeeded: number;
  goal: Goal;
}

type Participant = {
  name: string;
  prizes: Prize[];
  goals: Goal[];
  totalSold: number;
  expectedSold: number;
}

type Team = {
  name: string;
  totalSold: number;
  expectedSold: number;
  participants: Participant[];
}

type Campaign = {
  title: string;
  totalSold: number;
  expectedSold: number;
  initialDate: number;
  expirationDate: number;
  teams: number;
  participants: number;
}

type Report = {
  campaign: Campaign;
  teams?: Team[];
  team?: Team;
  participants?: Participant[];
  participant?: Participant;
}

interface ReportTemplateProps {
  componentRef: React.MutableRefObject<any>;
  campaignCode: string;
  reportType: string;
  participantId: string
  teamCode: string;
}

const ReportTemplate: React.ElementType = ({
  campaignCode, componentRef, reportType, participantId, teamCode,
}: ReportTemplateProps) => {
  const [axiosGet] = useAxios('get');
  const [reportData, setReportData] = useState<Report>();

  const renderParticipantInfo = (participant: Participant) => (
    <div>
      <ParticipantNameSection>
        <ParticipantName><h5>{participant.name}</h5></ParticipantName>
      </ParticipantNameSection>

      <ParticipantSection>
        <div style={{ width: '34%' }}>
          <SectionTitle><p>Meta</p></SectionTitle>
          <SectionItems>
            {participant.goals.length > 0 ? participant.goals.map((goal: Goal) => (
              <ItemText key={goal.productName}>{goal.productName}</ItemText>
            )) : <ItemText>-</ItemText>}
          </SectionItems>
        </div>
        <div style={{ width: '34%' }}>
          <SectionTitle><p>Vendas / Meta</p></SectionTitle>
          <SectionItems>
            {participant.goals.length > 0 ? participant.goals.map((goal: Goal) => (
              <ItemText key={goal.productName}>
                {goal.totalAmount}
                {' '}
                /
                {' '}
                {goal.expectedAmount}
              </ItemText>
            )) : <ItemText>-</ItemText>}
          </SectionItems>
        </div>
        <div style={{ width: '34%' }}>
          <SectionTitle><p>Percentual alcançado</p></SectionTitle>
          <SectionItems>
            {participant.goals.length > 0 ? participant.goals.map((goal: Goal) => (
              <ItemText key={goal.productName}>
                {
                  goal.totalSold === 0 || goal.expectedSold === 0
                    ? 0
                    : ((goal.totalSold * 100) / goal.expectedSold).toFixed(1)
                }
                %
              </ItemText>

            )) : <ItemText>-</ItemText>}
          </SectionItems>
        </div>
      </ParticipantSection>

      <ParticipantSection>
        <div style={{ width: '66.2%' }}>
          <SectionTitle><p>Premiações</p></SectionTitle>
          <SectionItems>
            {participant.prizes.length > 0 ? participant.prizes.map((prize: Prize) => (
              <ItemText key={prize.name}>{prize.name}</ItemText>
            )) : <ItemText>-</ItemText> }
          </SectionItems>
        </div>
        <div style={{ width: '33.8%' }}>
          <SectionTitle><p>Saldo</p></SectionTitle>
          <SectionItems>
            {participant.prizes.length > 0 ? participant.prizes.map((prize: Prize) => (
              <ItemText key={prize.price}>{String(prize.price).toLowerCase().includes('pocket') ? `R$ ${prize.price.toLocaleString('pt-BR')}` : '-'}</ItemText>
            )) : <ItemText>-</ItemText>}
          </SectionItems>
        </div>
      </ParticipantSection>

    </div>
  );

  const renderTeamInfo = (team: Team) => (
    <div>
      <TeamSection>
        <TeamName><h5>{team.name.toUpperCase()}</h5></TeamName>
      </TeamSection>

      <div style={{
        display: 'flex',
        flexDirection: 'column',
        font: `${theme.fonts.primary.h3}`,
        paddingLeft: 10,
        paddingRight: 10,
      }}
      >
        <SectionItems row marginTop="20px">
          <InfoText size="large">Total arrecadado pelo time:</InfoText>
          <p>
            R$
            {' '}
            {team.totalSold.toLocaleString('pt-BR')}
          </p>
        </SectionItems>

        <SectionItems row>
          <InfoText size="large">Meta estabelecida:</InfoText>
          <p>
            R$
            {' '}
            {team.expectedSold.toLocaleString('pt-BR')}
          </p>
        </SectionItems>

        <SectionItems row>
          <InfoText size="large">Percentual alcançado pelo time:</InfoText>
          <p>
            {
              team.totalSold === 0 || team.expectedSold === 0
                ? 0
                : ((team.totalSold * 100) / team.expectedSold).toFixed(1)
            }
            %
          </p>
        </SectionItems>

        <SectionItems row>
          <InfoText size="large">Participantes:</InfoText>
          <p>{team.participants.length}</p>
        </SectionItems>
      </div>
      {team.participants.map((participant: Participant) => renderParticipantInfo(participant))}
    </div>
  );

  document.title = `Campanha-${campaignCode}`;

  useEffect(() => {
    (async () => {
      await axiosGet({
        url: `/campaign/report/${campaignCode}?type=${reportType}&team=${teamCode}&participant=${participantId}`,
        success: (res: AxiosResponse) => {
          const { data } = res.data;
          setReportData(data);
        },
        error: (error: AxiosError) => {
          console.log(error);
        },
      });
    })();
  }, [axiosGet, participantId, campaignCode, reportType, teamCode]);

  return (
    <>
      {reportData ? (
        <>
          <Template ref={componentRef}>
            <TemplateHeader id="header">
              <HeaderLogo>novocred</HeaderLogo>
              <HeaderTitle><h3>Relatório Final de Campanha</h3></HeaderTitle>
            </TemplateHeader>

            <CampaignInfo>
              <CampaignName><h3>{reportData.campaign.title.toUpperCase()}</h3></CampaignName>

              <div style={{
                display: 'flex',
                flexDirection: 'column',
                font: `${theme.fonts.primary.h3}`,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              >
                <SectionItems row marginTop="30px">
                  <InfoText size="small">Total arrecadado:</InfoText>
                  <p>
                    R$
                    {' '}
                    {reportData.campaign.totalSold}
                  </p>
                </SectionItems>

                <SectionItems row>
                  <InfoText size="small">Meta geral:</InfoText>
                  <p>
                    R$
                    {' '}
                    {reportData.campaign.expectedSold}
                  </p>
                </SectionItems>

                <SectionItems row>
                  <InfoText size="small">Percentual alcançado:</InfoText>
                  <p>

                    {reportData.campaign.totalSold === 0
                        || reportData.campaign.expectedSold === 0
                      ? 0
                      : (
                        (reportData.campaign.totalSold * 100)
                      / reportData?.campaign.expectedSold).toFixed(1)}
                    %
                  </p>
                </SectionItems>

                <SectionItems row>
                  <InfoText size="small">Duração total:</InfoText>
                  <p>
                    {new Date(reportData.campaign.initialDate).toLocaleDateString('pt-BR')}
                    {' '}
                    -
                    {' '}
                    {new Date(reportData.campaign.expirationDate).toLocaleDateString('pt-BR')}
                  </p>
                </SectionItems>

                <SectionItems row>
                  <InfoText size="small">Times:</InfoText>
                  <p>{reportData.campaign.teams || '0'}</p>
                </SectionItems>

                <SectionItems row>
                  <InfoText size="small">Participantes:</InfoText>
                  <p>{reportData.campaign.participants}</p>
                </SectionItems>
              </div>
            </CampaignInfo>
            {

              reportType === 'team'
                ? reportData.teams && reportData.teams.map((team: Team) => renderTeamInfo(team))
                : reportType === 'individualTeam' ? reportData.team && renderTeamInfo(reportData.team)
                  : reportType === 'individual'
                    ? reportData.participants && reportData.participants
                      .map((participant: Participant) => renderParticipantInfo(participant))
                    : reportData.participant && renderParticipantInfo(reportData.participant)
            }
          </Template>
        </>
      ) : (
        <div>
          <h3>
            Relatório
          </h3>
        </div>
      )}
    </>

  );
};

export default ReportTemplate;
