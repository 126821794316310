import React, { useState, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AxiosResponse } from 'axios';
import ptBR from 'date-fns/locale/pt-BR';
import useAxios from '../../utils/useAxios';
import theme from '../../assets/styles/theme';
import {
  GeneralCard,
  Header,
  Navbar,
  OutlinedInput,
  Button,
  Toast,
} from '../../components';
import { InputsContainer } from '../CreateCampaign/Steps/BasicInfo/style';
import {
  SectionContainer,
  SectionTitle,
  SectionSubtitle,
  UploadButtonDiv,
  UploadDiv,
  UploadText,
  PictureIcon,
  DatePickerDiv,
  CalendarIcon,
  StepContainer,
  Container,
  EditPage,
} from './style';

import {
  Upload,
} from '../../assets/icons';

// const campaignId = '5e9e48eb-5aec-4ce9-add0-8780d05c672c';

const EditCampaign: React.FC = () => {
  const [axiosGet] = useAxios('get');
  const [axiosPut] = useAxios('put');
  const [axiosPost] = useAxios('post');

  const [newImage, setNewImage] = useState<string | File>('');
  const [newRegulation, setNewRegulation] = useState<string | File>('');

  const [title, setTitle] = useState<string>('');
  const [image, setImage] = useState<string>('');
  const [imagePreview, setImagePreview] = useState<string>('');
  const [regulation, setRegulation] = useState<string>('');
  const [regulationPreview, setRegulationPreview] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [initialDate, setInitialDate] = useState <Date | string | null>();
  const [expirationDate, setExpirationDate] = useState<Date | string | null>();

  const [fileType, setFileType] = useState('');
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { code } = useParams<{ code: string }>();

  const uploadFiles = async () => {
    const files = { image: '', regulation: '' };

    if (newImage !== '' && newImage != null) {
      const data = new FormData();
      data.append('file', newImage);

      const response = await axiosPost({
        url: 'campaign/file/upload',
        body: data,
        success: async (res: AxiosResponse) => {
          setImage(res.data.data.url);
          files.image = res.data.data.url;
          setErrorToast(false);
          setErrorMessage(null);
        },
        error: (error: any) => {
          setErrorMessage(error.response?.data?.message);
          setErrorToast(true);
        },
      });
    }

    if (newRegulation !== '' && newRegulation != null) {
      const data = new FormData();
      data.append('file', newRegulation);

      const response = await axiosPost({
        url: 'campaign/file/upload',
        body: data,
        success: async (res: AxiosResponse) => {
          setRegulation(res.data.data.url);
          files.regulation = res.data.data.url;
          setErrorToast(false);
          setErrorMessage(null);
        },
        error: (error: any) => {
          setErrorMessage(error.response?.data?.message);
          setErrorToast(true);
        },
      });
    }

    await updateCampaign(files.image || image, files.regulation || regulation);
  };

  const ErrorToast = () => (
    <Toast
      type="error"
      message={errorMessage}
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  const SuccessToast = () => (
    <Toast
      type="success"
      message="Campanha atualizada com sucesso."
      open={successToast}
      setOpen={setSuccessToast}
    />
  );

  const handleFiles = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const reader = new FileReader();
      const target = e.target.files[0];

      if (e.target.files[0] && e.target.files[0].size <= 5000000) {
        reader.readAsDataURL(e.target.files[0]);
      } else {
        setErrorMessage('Este arquivo que você tentou selecionar é muito grande. Escolha arquivos de até 5mb.');
        setErrorToast(true);
      }

      if (fileType !== '' && fileType === 'image') {
        reader.onloadend = () => {
          setImagePreview(String(reader?.result));
          setNewImage(target);
        };
      } else if (fileType !== '' && fileType === 'pdf') {
        reader.onloadend = () => {
          setRegulationPreview(target.name);
          setNewRegulation(target);
        };
      }
    }
  };

  const updateCampaign = async (imageUrl: string, regulationUrl: string) => {
    const updatedCampaign = {
      title,
      image: imageUrl || null,
      regulation: regulationUrl || null,
      description: description || null,
      initialDate,
      expirationDate,
    };
    const filteredCampaign = Object.fromEntries(Object.entries(updatedCampaign).filter(
      ([key, value]) => value !== null,
    ));
    await axiosPut({
      url: `campaign/${code}`,
      body: filteredCampaign,
      success: (res: AxiosResponse) => {
        setSuccessToast(true);
      },
      error: (error: any) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    const getCampaign = async () => {
      await axiosGet({
        url: `campaign/${code}?get=true`,
        success: (res: AxiosResponse) => {
          setTitle(res.data.data?.campaign?.title);
          setDescription(res.data.data?.campaign?.description);
          setInitialDate(res.data.data?.campaign?.initialDate);
          setExpirationDate(res.data.data?.campaign?.expirationDate);
          setImage(res.data.data?.campaign?.image);
          setImagePreview(res.data.data?.campaign?.image);
          setRegulation(res.data.data?.campaign?.regulation);
          setRegulationPreview(res.data.data?.campaign?.regulation);
        },
        error: (error: any) => {
          console.log(error);
        },
      });
    };
    getCampaign();
  }, [axiosGet, code]);

  return (
    <>
      {ErrorToast()}
      {SuccessToast()}
      <Container>
        <EditPage>
          <Navbar />
          <Header
            title="Editar informações básicas da campanha"
            onClickReturn={() => {
              window.location.href = `../campanha/${code}`;
            }}
          />
          <StepContainer>
            <div style={{ maxWidth: '1225px', width: '75%' }}>
              <GeneralCard height="420px" padding="10px">
                <InputsContainer>

                  <SectionContainer height="80%" width="26%">
                    <SectionTitle primaryFont>Foto da campanha</SectionTitle>
                    <UploadDiv imageUrl={imagePreview}>
                      <PictureIcon imageUrl={imagePreview} />
                    </UploadDiv>
                    <label htmlFor="image_input">
                      <UploadButtonDiv>
                        <UploadText>Fazer upload da foto</UploadText>
                        <Upload />
                        <input
                          id="image_input"
                          type="file"
                          accept="image/jpeg, image/jpg, image/pjpeg, image/png"
                          onChange={handleFiles}
                          onClick={() => setFileType('image')}
                        />
                      </UploadButtonDiv>
                    </label>
                  </SectionContainer>

                  <SectionContainer height="95%" width="30%">
                    <SectionContainer height="70%">
                      <div style={{ width: '100%' }}>
                        <OutlinedInput
                          type="alphanumeric"
                          title="Título da campanha"
                          value={title}
                          setValue={setTitle}
                          required
                        />
                      </div>
                      <div style={{ width: '100%' }}>
                        <OutlinedInput
                          type="alphanumeric"
                          title="Descrição da campanha"
                          setValue={setDescription}
                          value={description}
                          multiline
                        />
                      </div>
                    </SectionContainer>
                    <div>
                      <SectionTitle
                        primaryFont={false}
                      >
                        Selecionar período da campanha

                      </SectionTitle>

                      <DatePickerDiv>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptBR}>
                          <DatePicker
                            style={{ width: '75px' }}
                            value={initialDate}
                            format="dd/MM/yyyy"
                            onChange={setInitialDate}
                            onAccept={(date) => {
                              if (date && expirationDate) {
                                const actualDate = new Date(expirationDate);
                                if (date.getTime() > actualDate.getTime()) {
                                  const weekAfter = new Date(date);
                                  weekAfter.setDate(weekAfter.getDate() + 7);
                                  setExpirationDate(weekAfter);
                                }
                              }
                            }}
                          />
                          <p>-</p>
                          <DatePicker
                            style={{ width: '75px' }}
                            value={expirationDate}
                            format="dd/MM/yyyy"
                            onChange={setExpirationDate}
                            minDate={initialDate}
                            minDateMessage="Data deve ser após data inicial."
                          />
                          <CalendarIcon />
                        </MuiPickersUtilsProvider>
                      </DatePickerDiv>

                    </div>

                  </SectionContainer>

                  <SectionContainer width="24%" height="35%">
                    <SectionTitle primaryFont>Regulamento da campanha</SectionTitle>
                    <SectionSubtitle>
                      Arquivo Atual:
                      {' '}
                      {regulationPreview}
                    </SectionSubtitle>
                    <div id="pdf_container">
                      <Button
                        text="Fazer upload do documento"
                        backgroundColor="#DBDBDB"
                        hoverBackground="#B8B8B8"
                        borderRadius={theme.buttons.borderRadius.medium}
                        width="100%"
                        height={theme.buttons.height.medium}
                        textColor="#191919"
                        iconRight={<Upload />}
                        // onClick={}
                      />
                      <input
                        id="pdf_input"
                        type="file"
                        accept="application/pdf"
                        onChange={handleFiles}
                        onClick={() => setFileType('pdf')}
                      />
                    </div>
                  </SectionContainer>

                </InputsContainer>
              </GeneralCard>
            </div>
            <Button
              text="Salvar alterações"
              backgroundColor="#7136E8"
              hoverBackground="#7136E8"
              borderRadius={theme.buttons.borderRadius.medium}
              width={theme.buttons.width.large}
              height={theme.buttons.height.large}
              textColor="#FFFFFF"
              onClick={() => {
                if (title !== '') {
                  uploadFiles();
                } else {
                  alert('Preencha o título da campanha ');
                }
              }}
            />
          </StepContainer>

        </EditPage>
      </Container>
    </>
  );
};
export default EditCampaign;
