import styled from 'styled-components';

import theme from '../../assets/styles/theme';

type ModalProps = {
  width?: string;
  height?: string;
  centered?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const CampaignListingPage = styled.div`
  width: ${theme.pages.width};
  max-width: ${theme.pages.maxWidth};
  padding-bottom: 50px;
`;

export const ActiveCampaigns = styled.div`
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  gap: 49px;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;

`;

export const TextEndCampaign = styled.h3`
  font:${theme.fonts.primary.h3};
  color: ${theme.colors.secondaryGray};
`;

export const EndCampaign = styled.div`
  padding-top:60px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 49px;
  justify-content: flex-start;
`;

export const NoCampaignContainer = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  align-items: center;
  svg{
    width: 20px;
    height: 20px;
    color: ${theme.colors.darkViolet};
  }
`;

export const NoCampaignText = styled.p`
  color: ${theme.colors.darkViolet};
  font: ${theme.fonts.primary.body};
`;

export const SendObsButton = styled.button`
  border: none;
  background: transparent;
  font-weight: 400;
  font-size: 18px;
  margin-top: 300px;
  margin-left: 520px;
  &:hover {
    cursor: pointer;
  }
`;

export const ConfirmButton = styled.button`
  border: none;
  color: #7136E8;
  background: transparent;
  font-weight: 400;
  font-size: 18px;
  margin-top: 300px;
  margin-left: 25px;
  &:hover {
    cursor: pointer;
  }
`;

export const ModalText = styled.p`
align-self: flex-end;
`;

export const ModalContent = styled.div<ModalProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.centered ? 'center' : 'space-between')};
  align-items: ${(props) => (props.centered && 'center')};
  width: 100%;
  height: 550px;
  margin-top: -30px;
  padding-bottom: 30px;
  padding-right: 15px;
  margin-right: 5px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    padding-right: 15px;
  }
  ::-webkit-scrollbar-thumb {
    background: ${theme.colors.lightGray};
    border-radius: 15px;
  }
  ::-webkit-scrollbar-thumb:hover{
    
  }
  ::-webkit-scrollbar-track{
    background: #ffffff;
    border-radius: 15px;
  }
`;
