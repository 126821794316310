import styled from 'styled-components';
import theme from '../../../../assets/styles/theme';
import { Picture } from '../../../../assets/icons';

type ContainerProps = {
  height: string,
  justify?: string,
  width?: string,
}

type TitleProp = {
  primaryFont: boolean
}

type ImageProps = {
  imageUrl?: string,
}

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardTitle = styled.h3`
  font: ${theme.fonts.primary.h3};
  font-weight: bold;
  padding-bottom: 15px;
`;

export const UploadDiv = styled.div`
  width: 100%;
  height: 220px;
  background: ${(props: ImageProps) => (props.imageUrl ? `url(${props.imageUrl}) no-repeat center` : theme.colors.mainBlack)};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
`;

export const PictureIcon = styled(Picture)`
  color: ${theme.colors.bodyWhite};
  width: 80px;
  height: 80px;
  display: ${(props: ImageProps) => (props.imageUrl ? 'none' : 'inline')};
`;

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${(props: ContainerProps) => props.justify || 'space-around'};
  height: ${(props: ContainerProps) => props.height};
  width: ${(props: ContainerProps) => props.width};

`;

export const SectionTitle = styled.h3`
  font: ${(props: TitleProp) => (props.primaryFont ? theme.fonts.primary.h3 : theme.fonts.secondary.body)};
  color: ${(props: TitleProp) => (props.primaryFont ? theme.colors.secondaryGray : theme.colors.mainGray)};
  padding-bottom: 15px;
`;

export const CampaignInfo = styled.h2`
  font: ${(props: TitleProp) => (props.primaryFont ? theme.fonts.primary.h2 : theme.fonts.primary.h3)};
  color: ${theme.colors.mainBlack};
`;

export const InfoContainer = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: space-between;
  align-self: flex-start;
  padding-left: 20px;
`;

export const DatePickerDiv = styled.div`
  margin-top: 13px;
  border-bottom: 0.5px solid ${theme.colors.mainBlack};
  display: flex;
  align-items: center;
  width: 210px;
  justify-content: space-between;
  font: ${theme.fonts.secondary.body};
  color: ${theme.colors.mainBlack};
`;

export const IndividualCampaignCode = styled.div`
  width: 340px;
  height: 80px;
  border-radius: 9px;
  border: 2px solid ${theme.colors.lighterViolet};
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
  margin-bottom: 20px;
`;

export const TeamCampaignCode = styled.div`
  width: 300px;
  height: 120px;
  border-radius: 20px;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 25px 20px 5px 20px;
  margin-bottom: 20px;
  margin-right: 15px;
`;

export const LoadingContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #C0C0C0;
`;

export const RedirectText = styled.h3`
  color: #000000;
  font: ${theme.fonts.secondary.h3};
`;

export const LoadingModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 80%;
  flex-direction: column;
`;
