import styled from 'styled-components';
import theme from '../../../../assets/styles/theme';
import { Plus, Close } from '../../../../assets/icons';

export const StepContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Title = styled.h2`
  font: ${theme.fonts.primary.h2};
  color: ${theme.colors.mainBlack};
  font-weight: bold;
  grid-column: 2;
  white-space: nowrap;
`;

export const CardsContainer = styled.div`
  display: flex;
  width: 65%;
  justify-content: space-around;
`;

export const CardTitle = styled.h1`
  font: ${theme.fonts.primary.h1};
  color: ${theme.colors.mainBlack};
  font-weight: bold;
  display: flex;
`;

export const CardDescription = styled.p`
  font: ${theme.fonts.primary.body};
  color: ${theme.colors.mainGray};
  text-align: center;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 90%;
  width: 100%;
`;

export const PlusButton = styled(Plus)`
  stroke-width: 0.3px;
  height: 100%;
  width: 56px;
  border: 1px solid ${theme.colors.mainGray};
  border-radius: 10px;
  cursor: pointer;
`;

export const CloseButton = styled(Close)`
  width: 12px;
  stroke-width: 2px;
  cursor: pointer;
`;

export const TeamName = styled.div`
  display: flex;
  justify-content: space-between;
  height: 25px;
  background-color: ${theme.colors.mainBlack};
  color: ${theme.colors.bodyWhite};
  font: ${theme.fonts.primary.body};
  border-radius: 23px;
  align-items: center;
  padding: 0 10px;
  margin: 0 10px 5px 0;
`;

export const TeamNamesContainer = styled.div`
  display: flex;
  width: 70%;
  justify-content: flex-start;
  overflow-y : auto;
  flex-wrap: wrap;
  height: 60px;
`;

export const TitleAndButtonDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  margin-bottom: 50px;
  align-items: center;
  justify-items: center;
`;
