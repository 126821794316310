/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  Toolbar,
  Checkbox,
  Typography,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from '@material-ui/icons';

import {
  CellComponent,
  HeadComponent,
  PaperComponent,
  RowComponent,
  Pagination,
  TableLink,
} from './style';

type CampaignTeam = {
  id: string;
  team: string;
  accessCode: string;
};

type TeamParticipant = {
  participantId: string,
  participantName: string,
  totalSales: number,
  participantSales: SaleName[];
};

type SaleName = {
  id: string;
  name: string;
  sales: number;
  totalPrice: number;
};

type CampaignParticipant = {
  id: string;
  name?: string;
  participantName?: string;
  sold?: number;
  total?: number;
  prizes: object[];
};

type HeadCell = {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
};

export interface TableProps {
  title: string;
  columns: HeadCell[];
  total: number;
  data: CampaignTeam[] | TeamParticipant[] | SaleName[] | CampaignParticipant[];
  campaignTeamData?: CampaignTeam[];
  teamParticipantData?: TeamParticipant[];
  saleNameData?: SaleName[];
  campaignParticipantData?: CampaignParticipant[];
  teamModality?: boolean;
  filterTable: (query: string) => void;
  deleteRows: (row: any[]) => void;
  onClickLink?: (arg: object | string) => void;
}

const TableComponent: React.ElementType = ({
  title,
  columns,
  campaignTeamData,
  teamParticipantData,
  saleNameData,
  campaignParticipantData,
  teamModality,
  total,
  filterTable,
  deleteRows,
  onClickLink,
}: TableProps) => {
  const [orderBy, setOrderBy] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [orderByQuery, setOrderByQuery] = useState<string>('order=');
  const [paginationQuery, setPaginationQuery] = useState<string>('page=0');
  const [rowsQuery, setRowsQuery] = useState<string>('rows=10');
  const [queryURL, setQueryURL] = useState<string>('order=default&rows=10&page=0');

  const handleCheckboxes = (row: any) => {
    if (selectedRows.includes(row)) {
      setSelectedRows(selectedRows.filter((item) => item !== row));
    } else {
      setSelectedRows([...selectedRows, row]);
    }
  };

  const handleTableQuery = (order: string | null, page: number, rows: number): void => {
    const orderQuery = order === null ? 'order=' : `order=${order}`;
    const pageQuery = `page=${page}`;
    const rowQuery = `rows=${rows}`;

    setOrderBy(order === null ? '' : order);
    setOrderByQuery(orderQuery);

    setCurrentPage(page);
    setPaginationQuery(pageQuery);

    setRowsPerPage(rows);
    setRowsQuery(rowQuery);

    setQueryURL(`${orderQuery}&${rowQuery}&${pageQuery}`);
    filterTable(`${orderQuery}&${rowQuery}&${pageQuery}`);
  };

  const TableColumns = (): React.ReactElement => (
    <HeadComponent>
      <RowComponent>
        <CellComponent padding="checkbox" />
        {/* eslint-disable-next-line react/prop-types */}
        {columns.map((column: HeadCell) => {
          // eslint-disable-next-line react/prop-types
          const position = columns.indexOf(column);
          const getAlign = () => {
            if (column.numeric) return 'right';
            if (position === 0) return 'left';
            return 'right';
          };

          return (
            <CellComponent
              key={column.id}
              align={getAlign()}
              padding={position === 0 ? 'none' : 'default'}
            >
              {column.label}
            </CellComponent>
          );
        })}
      </RowComponent>
    </HeadComponent>
  );

  const TableToolbar = (): React.ReactElement => (
    <Toolbar>
      {selectedRows.length > 0 ? (
        <Typography>
          {`${selectedRows.length} selecionados`}
        </Typography>
      ) : (
        <Typography>
          {title}
        </Typography>
      )}

      {selectedRows.length > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={() => deleteRows(selectedRows)}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={`${orderBy}`}>
          <IconButton onClick={() => {
            const order = (): string => {
              if (orderBy === 'ASC') {
                return 'DESC';
              } if (orderBy === 'DESC') {
                return 'default';
              }
              return 'ASC';
            };

            handleTableQuery(order(), currentPage, rowsPerPage);
          }}
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );

  const CampaignTeamRows = (): React.ReactElement => (
    <>
      {data.map((row: any) => (
        <RowComponent
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.id}
          selected={selectedRows.includes(row)}
        >
          <CellComponent padding="checkbox">
            <Checkbox
              onClick={() => handleCheckboxes(row)}
              checked={selectedRows.includes(row)}
            />
          </CellComponent>

          <CellComponent
            component="th"
            scope="row"
            padding="none"
            align="left"
          >
            {row.name}
          </CellComponent>
          <CellComponent
            component="th"
            scope="row"
            padding="default"
            align="right"
          >
            {row.accessCode}
          </CellComponent>

          <CellComponent
            component="th"
            scope="row"
            padding="default"
            align="right"
          >
            {onClickLink && (
              <div role="contentinfo" onClick={() => onClickLink(row)}>
                <TableLink>Ver desempenho</TableLink>
              </div>
            )}
          </CellComponent>
        </RowComponent>
      ))}
    </>
  );

  const TeamParticipantRows = (): React.ReactElement => (
    <>
      {data.map((row: TeamParticipant) => (
        <RowComponent
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.participantId}
          selected={selectedRows.includes(row)}
        >
          <CellComponent padding="checkbox">
            <Checkbox
              onClick={() => handleCheckboxes(row)}
              checked={selectedRows.includes(row)}
            />
          </CellComponent>

          <CellComponent
            component="th"
            scope="row"
            padding="none"
            align="left"
          >
            {row.participantName}
          </CellComponent>
          <CellComponent
            component="th"
            scope="row"
            padding="default"
            align="right"
          >
            {row.totalSales || 0}
          </CellComponent>

          <CellComponent
            component="th"
            scope="row"
            padding="default"
            align="right"
          >

            {onClickLink && (
              <div role="contentinfo" onClick={() => onClickLink(row)}>
                <TableLink>Ver desempenho</TableLink>
              </div>
            )}
          </CellComponent>
        </RowComponent>
      ))}
    </>
  );

  const CampaignParticipantRows = (): React.ReactElement => (
    <>
      {data.map((row: CampaignParticipant) => (
        <RowComponent
          hover
          tabIndex={-1}
          key={row.id}
        >
          <CellComponent padding="checkbox">
            {!teamModality && (
              <Checkbox
                onClick={() => handleCheckboxes(row)}
                checked={selectedRows.includes(row)}
              />
            )}
          </CellComponent>
          <CellComponent
            component="th"
            scope="row"
            padding="none"
            align="left"
          >
            {row.name || row.participantName}
          </CellComponent>
          <CellComponent
            component="th"
            scope="row"
            padding="default"
            align="right"
          >
            {row.sold || row.total || 0}
          </CellComponent>
          <CellComponent
            component="th"
            scope="row"
            padding="default"
            align="right"
          >

            {onClickLink && (
              <div role="contentinfo" onClick={() => onClickLink(row)}>
                <TableLink>Ver relatório</TableLink>
              </div>
            )}
          </CellComponent>
        </RowComponent>
      ))}
    </>
  );

  const SaleNameRows = (): React.ReactElement => (
    <>
      {data.map((row: SaleName) => (
        <RowComponent
          hover
          role="checkbox"
          tabIndex={-1}
          key={row.id}
          selected={selectedRows.includes(row)}
        >
          <CellComponent padding="checkbox">
            <Checkbox
              onClick={() => handleCheckboxes(row)}
              checked={selectedRows.includes(row)}
            />
          </CellComponent>

          <CellComponent
            component="th"
            scope="row"
            padding="none"
            align="left"
          >
            {row.name}
          </CellComponent>
          <CellComponent
            component="th"
            scope="row"
            padding="default"
            align="right"
          >
            {row.totalPrice}
          </CellComponent>
        </RowComponent>
      ))}
    </>
  );

  useEffect(() => {
    if (campaignTeamData) {
      setData(campaignTeamData);
    } else if (teamParticipantData) {
      setData(teamParticipantData);
    } else if (saleNameData) {
      setData(saleNameData);
    } else if (campaignParticipantData) {
      setData(campaignParticipantData);
    }
  }, [campaignParticipantData, campaignTeamData, saleNameData, teamParticipantData]);

  return (
    <div>
      <PaperComponent>
        {TableToolbar()}
        <TableContainer>
          <Table size="medium">
            {TableColumns()}
            <TableBody>
              {campaignTeamData && CampaignTeamRows()}
              {teamParticipantData && TeamParticipantRows()}
              {saleNameData && SaleNameRows()}
              {campaignParticipantData && CampaignParticipantRows()}
            </TableBody>
          </Table>
        </TableContainer>

        <Pagination
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 50]}
          count={total}
          page={currentPage}
          onRowsPerPageChange={
            (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
              const page = 0;
              const rows = Number(event.target.value);
              setRowsPerPage(Number(event.target.value));
              setCurrentPage(0);
              handleTableQuery(orderBy, page, rows);
            }
          }
          onPageChange={(event: any, newPage: number) => {
            setCurrentPage(newPage);
            handleTableQuery(orderBy, newPage, rowsPerPage);
          }}
        />
      </PaperComponent>
    </div>
  );
};

export default TableComponent;
