/* eslint-disable react/require-default-props */
import {
  CardMain,
  CardImage,
  CardInformation,
  CardDescription,
  CardTitle,
  CardBody,
} from './style';

type WithChildren<T = unknown> = T & {
  children?: React.ReactNode,
};

export interface CardProps {
  description?: string;
  title?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
  backgroundImage?: string;
  padding?: string;
  children?: WithChildren;
  onClickImage?: () => void;
}

const CardCampaign: React.ElementType = ({
  backgroundColor,
  backgroundImage,
  title,
  description,
  width,
  height,
  padding,
  children,
  onClickImage,
}: CardProps) => (

  <CardMain backgroundColor={backgroundColor} width={width}>
    <CardImage clickableImage={onClickImage && true} onClick={onClickImage}>
      <img src={backgroundImage} alt="imagem" />
    </CardImage>
    <CardBody padding={padding} height={height}>
      <CardInformation>
        <CardTitle>{title}</CardTitle>
        <CardDescription>
          {description}
        </CardDescription>
      </CardInformation>
      {children}
    </CardBody>
  </CardMain>
);

export default CardCampaign;
