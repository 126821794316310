import React, { useRef, useState, useEffect } from 'react';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';
import crypto from 'crypto';
import theme from '../../assets/styles/theme';
import useAxios from '../../utils/useAxios';

import {
  StandardInput,
  Button,
  Toast,
} from '../../components';

import {
  LoginPage,
  LoginContainerBorder,
  LoginContainer,
  LoginTitle,
  InputContainer,
  InputTitle,
  GenericContainer,
} from './style';

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [clickedInput, setClickedInput] = useState('');
  const [errorToast, setErrorToast] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successToast, setSuccessToast] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [axiosPost] = useAxios('post');

  const { token } = useParams<{ token: string }>();

  const passwordInputRef = useRef<HTMLDivElement | null>(null);
  const confirmPasswordInputRef = useRef<HTMLDivElement | null>(null);

  const handleOutsideClick = (e: { target: any }) => {
    const passwordReference = passwordInputRef.current;
    const confirmPasswordReference = confirmPasswordInputRef.current;
    if ((passwordReference && !passwordReference.contains(e.target))
      && (confirmPasswordReference && !confirmPasswordReference.contains(e.target))) {
      setClickedInput('');
    }
  };

  const isEmpty = (string: string) => string.length === 0;

  const changePassword = async () => {
    if (!isEmpty(password) && !isEmpty(confirmPassword)) {
      if (password !== confirmPassword) {
        setErrorToast(true);
        setErrorMessage('Código de verificação expirado, gere um novo e tente novamente!');
        // else if (
        // desativado enquanto tiver em desenvolvimento

        // if (!passwordValidator(password)) {
        //   setErrorToast(true);
        //   setErrorMessage(`Esta senha não é forte o bastante,
        //       lembre de adicionar letras maiúsculas e minúsculas.
        //       Além disso ela precisa ter pelo menos 8 (oito) caracteres, pelo menos
        //       1 número e 1 caractere especial.`)
        //   });
        // })
      } else {
        const hashedPassword = crypto.createHash('sha256').update(password).digest('hex');
        const hashedToken = crypto.createHash('sha256').update(token).digest('hex');

        await axiosPost({
          url: 'user/reset-password',
          body: { token: hashedToken, password },
          success: async (res: AxiosResponse) => {
            setSuccessToast(true);
            setSuccessMessage(res.data.message);
            await new Promise((resolve) => setTimeout(resolve, 1000));
            window.location.replace('/login');
          },
          error: (err: any) => {
            setErrorToast(true);
            setErrorMessage(err.response.data.message);
          },
        });
      }
    }
  };

  const ErrorToast = () => (
    <Toast
      type="error"
      message={errorMessage}
      open={errorToast}
      setOpen={setErrorToast}
    />
  );

  const SuccessToast = () => (
    <Toast
      type="success"
      message={successMessage}
      open={successToast}
      setOpen={setSuccessToast}
    />
  );

  const handleEnterKey = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      changePassword();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  });

  return (
    <>
      {ErrorToast()}
      {SuccessToast()}
      <LoginPage onKeyUp={handleEnterKey}>
        <LoginContainerBorder>
          <LoginContainer>
            <LoginTitle>Redefinir senha</LoginTitle>
            <InputContainer>
              <InputTitle style={{
                color: clickedInput === 'password'
                  ? theme.colors.lighterViolet
                  : theme.colors.mainGray,
              }}
              >
                Nova senha
              </InputTitle>
              <GenericContainer ref={passwordInputRef} onClick={() => setClickedInput('password')}>
                <StandardInput
                  placeholder="Digite sua nova senha aqui"
                  type="password"
                  required
                  value={password}
                  setValue={setPassword}
                />
              </GenericContainer>
            </InputContainer>
            <InputContainer>
              <InputTitle style={{
                color: clickedInput === 'passwordConfirm'
                  ? theme.colors.lighterViolet
                  : theme.colors.mainGray,
              }}
              >
                Confirmar senha
              </InputTitle>
              <GenericContainer ref={confirmPasswordInputRef} onClick={() => setClickedInput('confirmPassword')}>
                <StandardInput
                  placeholder="Insira a senha novamente"
                  type="password"
                  required
                  value={confirmPassword}
                  setValue={setConfirmPassword}
                />
              </GenericContainer>
            </InputContainer>
            <Button
              width="70%"
              height="45px"
              backgroundColor={theme.colors.lighterViolet}
              borderRadius="12px"
              boxShadow="0px 2px 6px #0000004D"
              text="Confirmar"
              fontSize="18px"
              textColor={theme.colors.bodyWhite}
              hoverBackground={theme.colors.darkViolet}
              onClick={changePassword}
            />
          </LoginContainer>
        </LoginContainerBorder>
      </LoginPage>
    </>

  );
};

export default ResetPassword;
