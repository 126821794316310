import { Box } from '@mui/system';
import ModalUnstyled from '@mui/core/ModalUnstyled';
import styled from 'styled-components';
import theme from '../../../assets/styles/theme';
import { Close } from '../../../assets/icons';

type ModalProps ={
  width?:string;
  height?:string;
  bgColor?:string;
  bgColorBack?:string;
  borderRadius?:string;
  marginTitle?:string;
  marginDescription?:string;
  marginText?:string;
  padding?:string;
  gapButtons?:string;
  textPosition?:string;
}

export const StyledModal = styled(ModalUnstyled)<ModalProps>`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${(props: ModalProps) => props.padding || '38px 53px 28px 53px'};
`;

export const Backdrop = styled('div')`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  background-color: ${(props: ModalProps) => props.bgColorBack || '#0000004D'};
`;

export const ModalBox = styled(Box)<ModalProps>`
  width: ${(props: ModalProps) => props.width || '600px'};
  height: ${(props: ModalProps) => props.height || '250px'};
  background-color: ${(props: ModalProps) => props.bgColor || theme.colors.bodyWhite};
  border-radius:${(props: ModalProps) => props.borderRadius || theme.card.borderRadius.large};
  padding: ${(props: ModalProps) => props.padding || '38px 53px 28px 53px'};
  outline: none;
  box-sizing: border-box;
`;

export const ModalTitle = styled.h1`
  font:${theme.fonts.primary.h1};
  font-weight: bold;
  color:${theme.colors.mainBlack};
  margin-bottom: ${(props: ModalProps) => props.marginTitle || '22px'};
`;

export const ModalText1 = styled.div`
  font: ${theme.fonts.secondary.h3};
  color:${theme.colors.mainBlack};
  display: flex;
  flex-direction: column;
  justify-content: ${(props: ModalProps) => props.textPosition || 'flex-start'};
  gap: 22px;
  margin-bottom: ${(props: ModalProps) => props.marginDescription || '22px'};
`;

export const ModalText2 = styled.p`
  font:${theme.fonts.secondary.h3};
  color:#D10F32;
  margin-bottom: ${(props: ModalProps) => props.marginText || '0px'};
`;

export const TopContent = styled.div<ModalProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${(props: ModalProps) => props.textPosition || 'space-between'};
`;

export const ModalButtons = styled.div<ModalProps>`
  display: flex;
  justify-content:flex-end;
  gap:${(props: ModalProps) => props.gapButtons || '50px'};
  align-items: center;
`;

export const CloseIcon = styled(Close)`
  cursor: pointer;
  color: ${theme.colors.mainGray};
  stroke-width: 1.5 ;
  top: 30px;
`;

export const ModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
`;
