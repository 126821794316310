import styled from 'styled-components';
import theme from '../../assets/styles/theme';

type HeaderProps = {
  text?: string;
  title?: string;
  width?: number;
  height?: number;
  textButton?: string;
  textNumber?: string;
  description?: string;
  margin?:string;
  display?:string;
}

export const HeaderMain = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
`;

export const HeaderBody = styled.div<HeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderButton = styled.button<HeaderProps>`
  width: 300px;
  height: 25px;
  margin-bottom: 30px;
  font: ${theme.fonts.primary.h3};
  color: ${theme.colors.mainGray};
  border: none;
  background-color: #FFFFFF;
  display: ${(props: HeaderProps) => props.display || 'flex'};
  flex-direction: row;
  cursor: pointer;
  padding-top: 5px;
  margin-left: -8px;
  align-items: center;
  outline: none;
`;

export const HeaderInformation = styled.div<HeaderProps>`
  display: flex;
  flex-direction: column;
`;

export const HeaderTextInformation = styled.div<HeaderProps>`
  align-items: flex-end;
  display: flex;
  flex-direction: row;
`;

export const HeaderText = styled.p<HeaderProps>`
  font: ${theme.fonts.secondary.h3};
  color: ${theme.colors.mainGray};
  margin-right: 10px;
`;

export const HeaderTextNumber = styled.p<HeaderProps>`
  font: ${theme.fonts.secondary.h3};
  color: #616161;
  font-weight: bold;
`;

export const HeaderTitle = styled.h1<HeaderProps>`
  margin: ${(props: HeaderProps) => props.margin || '25px 0 30px 0'};
  font: ${theme.fonts.primary.h1};
  color: ${theme.colors.mainBlack};
  font-weight: bolder;
  font-size: 24px;
`;

export const HeaderDescription = styled.p<HeaderProps>`
  font: ${theme.fonts.primary.h3};
  color: #707070;
  max-width: 900px;
  flex-wrap: wrap;
`;
